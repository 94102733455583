import * as React from 'react';

const WorkTrailIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.333 19.458H6.667c-3.85 0-4.567-1.791-4.75-3.533L1.292 9.25c-.092-.875-.117-2.167.75-3.133.75-.834 1.991-1.234 3.791-1.234h8.333c1.809 0 3.05.409 3.792 1.234.867.966.842 2.258.75 3.141l-.625 6.659c-.183 1.75-.9 3.541-4.75 3.541Zm-7.5-13.333c-1.408 0-2.375.275-2.866.825-.409.45-.542 1.142-.434 2.175l.625 6.675c.142 1.317.5 2.408 3.509 2.408h6.666c3 0 3.367-1.091 3.508-2.416l.625-6.659c.109-1.041-.024-1.733-.433-2.183-.492-.55-1.458-.825-2.867-.825H5.833Z'
      fill='#05060E'
    />
    <path
      d='M13.334 6.125a.63.63 0 0 1-.625-.625v-.667c0-1.483 0-2.042-2.042-2.042H9.334c-2.042 0-2.042.559-2.042 2.042V5.5a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625v-.667c0-1.467 0-3.292 3.292-3.292h1.333c3.292 0 3.292 1.825 3.292 3.292V5.5a.63.63 0 0 1-.625.625Z'
      fill='#05060E'
    />
    <path
      d='M10 14.458c-2.292 0-2.292-1.416-2.292-2.266v-.859c0-1.175.283-1.458 1.458-1.458h1.667c1.175 0 1.458.283 1.458 1.458v.834c0 .866 0 2.291-2.291 2.291Zm-1.042-3.333v1.067c0 .858 0 1.016 1.042 1.016 1.041 0 1.041-.133 1.041-1.025v-1.058H8.958Z'
      fill='#292D32'
    />
    <path
      d='M11.666 12.808a.621.621 0 0 1-.616-.55.628.628 0 0 1 .541-.7 13.146 13.146 0 0 0 6.084-2.4.621.621 0 0 1 .875.142c.2.275.141.667-.142.875a14.47 14.47 0 0 1-6.667 2.633h-.075ZM8.333 12.816h-.075a14.293 14.293 0 0 1-6.433-2.408.627.627 0 0 1-.167-.867.627.627 0 0 1 .867-.166 13.127 13.127 0 0 0 5.866 2.2c.342.041.592.35.55.691a.601.601 0 0 1-.608.55Z'
      fill='#292D32'
    />
  </svg>
);

export default WorkTrailIcon;

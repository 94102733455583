import React from 'react'
import { translation } from 'utils/germanTranslation'

const StepTitle = ({ step }) => {
  return (
    <div className='bg-yellow-400 flex self-center text-center absolute justify-center items-center -top-[12px] w-[80%] rounded-lg py-4'>
      <h1 className='text-xl font-bold'>
        {translation.step} {step}
      </h1>
    </div>
  )
}

export default StepTitle

import React from 'react';

const AdminDashboardIcon = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.375 12.094h-2.25c-2.723 0-4.219-1.496-4.219-4.219v-2.25c0-2.723 1.496-4.219 4.219-4.219h2.25c2.723 0 4.219 1.496 4.219 4.219v2.25c0 2.723-1.497 4.219-4.219 4.219Zm-2.25-9c-1.777 0-2.531.754-2.531 2.531v2.25c0 1.777.753 2.531 2.531 2.531h2.25c1.777 0 2.531-.754 2.531-2.531v-2.25c0-1.777-.753-2.531-2.531-2.531h-2.25ZM7.875 25.594h-2.25c-2.723 0-4.219-1.497-4.219-4.219v-2.25c0-2.723 1.496-4.219 4.219-4.219h2.25c2.723 0 4.219 1.496 4.219 4.219v2.25c0 2.723-1.496 4.219-4.219 4.219Zm-2.25-9c-1.777 0-2.531.753-2.531 2.531v2.25c0 1.777.754 2.531 2.531 2.531h2.25c1.777 0 2.531-.753 2.531-2.531v-2.25c0-1.777-.754-2.531-2.531-2.531h-2.25ZM6.75 12.094A5.349 5.349 0 0 1 1.406 6.75 5.349 5.349 0 0 1 6.75 1.406a5.349 5.349 0 0 1 5.344 5.344 5.349 5.349 0 0 1-5.344 5.344Zm0-9A3.663 3.663 0 0 0 3.094 6.75a3.663 3.663 0 0 0 3.656 3.656 3.663 3.663 0 0 0 3.656-3.656A3.663 3.663 0 0 0 6.75 3.094ZM20.25 25.594a5.349 5.349 0 0 1-5.344-5.344 5.349 5.349 0 0 1 5.344-5.344 5.349 5.349 0 0 1 5.344 5.344 5.349 5.349 0 0 1-5.344 5.344Zm0-9a3.663 3.663 0 0 0-3.656 3.656 3.663 3.663 0 0 0 3.656 3.656 3.663 3.663 0 0 0 3.656-3.656 3.663 3.663 0 0 0-3.656-3.656Z'
      fill='#05060E'
    />
  </svg>
);

const AdminStudentsIcon = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.304 13.072h-.09a.608.608 0 0 0-.202 0c-3.263-.1-5.726-2.666-5.726-5.827a5.848 5.848 0 0 1 5.838-5.839 5.848 5.848 0 0 1 5.84 5.839c-.012 3.161-2.487 5.726-5.626 5.827h-.034Zm-.18-9.978a4.163 4.163 0 0 0-4.15 4.151 4.121 4.121 0 0 0 3.993 4.14c.067-.011.214-.011.36 0a4.144 4.144 0 0 0 3.949-4.14 4.163 4.163 0 0 0-4.152-4.151ZM18.607 13.219c-.034 0-.067 0-.101-.011-.461.044-.934-.282-.979-.743-.045-.461.236-.877.698-.934.135-.011.28-.011.405-.011a3.09 3.09 0 0 0 2.925-3.094 3.092 3.092 0 0 0-3.094-3.094.833.833 0 0 1-.844-.832.85.85 0 0 1 .844-.844 4.792 4.792 0 0 1 4.781 4.781 4.77 4.77 0 0 1-4.601 4.782h-.034ZM10.316 25.369c-2.205 0-4.421-.563-6.097-1.688-1.564-1.035-2.42-2.452-2.42-3.994 0-1.54.856-2.97 2.42-4.016 3.375-2.239 8.842-2.239 12.195 0 1.552 1.035 2.418 2.453 2.418 3.994 0 1.541-.855 2.97-2.418 4.016-1.688 1.125-3.893 1.688-6.098 1.688Zm-5.164-8.28c-1.08.72-1.665 1.642-1.665 2.61 0 .956.597 1.878 1.665 2.587 2.802 1.879 7.527 1.879 10.328 0 1.08-.72 1.665-1.642 1.665-2.61 0-.956-.596-1.878-1.665-2.587-2.801-1.868-7.526-1.868-10.328 0ZM20.632 23.344a.83.83 0 0 1-.821-.675.855.855 0 0 1 .652-1.002c.709-.146 1.361-.427 1.868-.82.641-.485.99-1.092.99-1.733 0-.642-.349-1.249-.979-1.722-.495-.382-1.114-.652-1.845-.82a.85.85 0 0 1-.641-1.013.85.85 0 0 1 1.012-.641c.968.213 1.812.596 2.498 1.125 1.046.787 1.642 1.9 1.642 3.07s-.607 2.285-1.653 3.083c-.698.54-1.576.934-2.543 1.125-.068.023-.124.023-.18.023Z'
      fill='#05060E'
    />
  </svg>
);

const AdminTeachersIcon = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.68 13.072h-.09a.608.608 0 0 0-.203 0c-3.263-.1-5.726-2.666-5.726-5.827a5.848 5.848 0 0 1 5.838-5.839 5.848 5.848 0 0 1 5.84 5.839c-.012 3.161-2.487 5.726-5.626 5.827h-.034Zm-.18-9.978a4.163 4.163 0 0 0-4.152 4.151 4.121 4.121 0 0 0 3.994 4.14c.056-.011.214-.011.36 0a4.144 4.144 0 0 0 3.949-4.14 4.163 4.163 0 0 0-4.152-4.151ZM13.691 25.369c-2.205 0-4.421-.563-6.097-1.688-1.564-1.035-2.42-2.452-2.42-3.994 0-1.54.856-2.97 2.42-4.016 3.375-2.239 8.842-2.239 12.195 0 1.552 1.035 2.418 2.453 2.418 3.994 0 1.541-.855 2.97-2.418 4.016-1.688 1.125-3.893 1.688-6.098 1.688Zm-5.164-8.28c-1.08.72-1.665 1.642-1.665 2.61 0 .956.597 1.878 1.665 2.587 2.802 1.879 7.527 1.879 10.328 0 1.08-.72 1.665-1.642 1.665-2.61 0-.956-.596-1.878-1.665-2.587-2.801-1.868-7.526-1.868-10.328 0Z'
      fill='#05060E'
    />
  </svg>
);

const AdminClassesIcon = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.511 19.125c-.956 0-1.924-.248-2.677-.731L4.06 13.972a4.413 4.413 0 0 1-2.014-3.712c0-1.508.754-2.891 2.014-3.713l6.784-4.41c1.507-.978 3.859-.978 5.355.012l6.739 4.421a4.425 4.425 0 0 1 2.002 3.701c0 1.496-.754 2.88-2.002 3.701l-6.74 4.422c-.753.495-1.72.73-2.688.73Zm0-16.031c-.641 0-1.282.146-1.744.46l-6.772 4.41a2.707 2.707 0 0 0-1.249 2.296c0 .945.45 1.777 1.249 2.295l6.772 4.421c.934.608 2.577.608 3.51 0l6.74-4.421a2.697 2.697 0 0 0 1.237-2.295c0-.945-.45-1.778-1.238-2.295l-6.739-4.421c-.472-.293-1.113-.45-1.766-.45Z'
      fill='#05060E'
    />
    <path
      d='M13.5 25.594c-.495 0-1.001-.068-1.406-.203L8.504 24.2c-1.698-.563-3.037-2.419-3.025-4.208l.01-5.276a.85.85 0 0 1 .845-.844.85.85 0 0 1 .843.844l-.01 5.276c0 1.058.877 2.273 1.878 2.61l3.589 1.193c.45.146 1.282.146 1.732 0l3.589-1.193c1.001-.337 1.879-1.552 1.879-2.598v-5.22a.85.85 0 0 1 .843-.844.85.85 0 0 1 .844.844v5.22c0 1.788-1.327 3.633-3.026 4.207l-3.589 1.193a5.003 5.003 0 0 1-1.406.19ZM24.075 17.719a.85.85 0 0 1-.844-.844v-6.75a.85.85 0 0 1 .844-.844.85.85 0 0 1 .844.844v6.75a.85.85 0 0 1-.844.844Z'
      fill='#05060E'
    />
  </svg>
);

const AdminJobsIcon = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18 25.594H9c-5.197 0-6.165-2.419-6.412-4.77l-.844-9.012c-.124-1.18-.158-2.925 1.012-4.23C3.77 6.457 5.445 5.917 7.875 5.917h11.25c2.441 0 4.117.552 5.119 1.665 1.17 1.305 1.136 3.05 1.012 4.242l-.843 8.989c-.248 2.362-1.215 4.78-6.413 4.78Zm-10.125-18c-1.901 0-3.206.371-3.87 1.113-.551.608-.731 1.542-.585 2.937l.844 9.011c.191 1.777.675 3.251 4.736 3.251h9c4.05 0 4.545-1.473 4.736-3.262l.844-8.989c.146-1.406-.034-2.34-.585-2.948-.664-.742-1.969-1.113-3.87-1.113H7.875Z'
      fill='#05060E'
    />
    <path
      d='M18 7.594a.85.85 0 0 1-.844-.844v-.9c0-2.002 0-2.756-2.756-2.756h-1.8c-2.756 0-2.756.754-2.756 2.756v.9A.85.85 0 0 1 9 7.594a.85.85 0 0 1-.844-.844v-.9c0-1.98 0-4.444 4.444-4.444h1.8c4.444 0 4.444 2.464 4.444 4.444v.9a.85.85 0 0 1-.844.844ZM13.5 18.844c-3.094 0-3.094-1.913-3.094-3.06v-1.159c0-1.586.383-1.969 1.969-1.969h2.25c1.586 0 1.969.383 1.969 1.969v1.125c0 1.17 0 3.094-3.094 3.094Zm-1.406-4.5v1.44c0 1.158 0 1.372 1.406 1.372s1.406-.18 1.406-1.383V14.344H12.094Z'
      fill='#05060E'
    />
    <path
      d='M15.75 16.616a.839.839 0 0 1-.833-.742.847.847 0 0 1 .732-.945 17.749 17.749 0 0 0 8.212-3.24.838.838 0 0 1 1.181.191c.27.371.192.9-.19 1.181a19.535 19.535 0 0 1-9 3.555h-.102Z'
      fill='#05060E'
    />
    <path
      d='M11.25 16.628h-.101a19.296 19.296 0 0 1-8.685-3.252.847.847 0 0 1-.225-1.17.847.847 0 0 1 1.17-.225 17.723 17.723 0 0 0 7.92 2.97.845.845 0 0 1 .742.934.812.812 0 0 1-.821.743Z'
      fill='#292D32'
    />
  </svg>
);

export {
  AdminDashboardIcon,
  AdminStudentsIcon,
  AdminTeachersIcon,
  AdminClassesIcon,
  AdminJobsIcon,
};

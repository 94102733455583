import React, { useState, useRef, useCallback, useMemo } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
} from '@mui/material'
import lehrstellLogo from 'assets/images/logo-transparent.png'
import InputComponent from 'components/Input'
import Button from 'components/Button'
import moment from 'moment'
import { toast } from 'react-toastify'
import { performValidation } from './performUserValidation'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import CustomModal from 'components/CustomModal'
import ReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'utils'
import { translation } from 'utils/germanTranslation'
import WhatIsLehrstell from 'assets/images/what-is-lehrstell.jpeg'
import CustomVideoPlayerModal from 'components/CustomVideoPlayerModal'
import LandingPageIntroductionVideo from 'assets/videos/landing-page-video.mp4'
import UserBirthdaySection from './UserBirthdaySection'
import TERMS_PDF from 'assets/documents/Nutzungsbedingungen_rechtliche_dokumente.pdf'

const USER_ROLE_TRANSLATIONS = {
  STUDENT: {
    BUTTON_TEXT: 'Als Lehrperson registrieren',
    TITLE: 'Schüler*In Account erstellen',
  },
  TEACHER: {
    BUTTON_TEXT: 'Als Schüler*In registrieren',
    TITLE: 'Lehrperson Account erstellen',
  },
}
const Register = () => {
  const navigate = useNavigate()
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const [isConditionsAccepted, setIsConditionsAccepted] = useState(false)
  const [showComingSoonBanner, setShowComingSoonBanner] = useState(false)
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false)
  const [user, setUser] = useState({
    role: translation.student,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })
  const dateOfBirthRef = useRef()

  const [dateOfBirth, setDateOfBirth] = useState({
    date: 1,
    month: 'Januar',
    year: '2000',
  })

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const registerUser = async () => {
    let response
    try {
      response = await fetch(BACKEND_URL + API_URL.register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...user,
          role: user.role === translation.student ? 'Student' : 'Teacher',
        }),
      })
      const data = await response.json()
      return data
    } catch (error) {
      return { error: 'Network Error' }
    }
  }

  const verifyAge = (e) => {
    e.preventDefault()
    if (user?.role === translation.teacher) {
      return handleRegister()
    }

    const dob = moment(
      `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.date}`,
      'YYYY-MMMM-DD'
    )

    const age = moment().diff(dob, 'years')

    if (age < 14) {
      dateOfBirthRef.current.showSubmitModal()
    } else handleRegister()
  }

  const handleRegister = async () => {
    setLoading(true)
    let registration = await registerUser()
    setLoading(false)
    const toastType = registration.error ? 'error' : 'success'
    const toastMessage = registration.error || 'User registered successfully'
    if (toastType === 'error') {
      toast[toastType](toastMessage, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        style: {
          fontSize: '1.1rem',
          fontWeight: 'bold',
          color: '#fff',
          backgroundColor: toastType === 'success' ? '#2e7d32' : '#c62828',
        },
      })
    } else {
      setShowModal(true)
    }
  }

  const [confirmPassword, setConfirmPassword] = useState('')

  const handleChange = (newuser, field) =>
    setUser({ ...user, [field]: newuser })

  const onChange = (value) => {
    if (value) setIsCaptchaVerified(true)
  }

  const handleUserDateOfBirthChange = (value, key) => {
    setDateOfBirth({ ...dateOfBirth, [key]: value })
  }

  const isStrongPassword = useCallback(() => {
    if (!user.password) return true
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    return passwordRegex.test(user.password)
  }, [user.password])

  const isPasswordStrong = useMemo(() => isStrongPassword(), [isStrongPassword])

  const handleCloseRegisterModal = () => {
    navigate('/login')
  }

  return (
    <>
      <Box
        className='p-4 xs:p-8 bg-white rounded-md max-w-[565px] w-full relative'
        style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.07)' }}
      >
        {showComingSoonBanner && (
          <div className='absolute top-[32%] font-sans gap-4 h-[200px] z-[1000] bg-[#E6F0F1] p-4 border border-[#b6ccd7] drop-shadow-2xl shadow-xl left-1/2 transform -translate-x-1/2 w-[90%] flex flex-col items-center justify-center text-[#777777]'>
            <h1 className='uppercase font-semibold text-xl text-center w-[80%]'>
              Registrierung im moment n
              <span
                onClick={() => {
                  setShowComingSoonBanner(false)
                }}
              >
                o
              </span>
              ch nicht möglich
            </h1>
            <div className='text-center'>
              <p>...wir sind bald soweit - für Infos:</p>
              <a href='mailto:info@lehrstell.ch'>info@lehrstell.ch</a>
            </div>
          </div>
        )}
        <div className='flex justify-between items-end sm:items-center w-full'>
          <IconButton onClick={() => setShouldPlayVideo(true)}>
            <img src={WhatIsLehrstell} alt='info video icon' width={100} />
          </IconButton>

          <Button
            title={
              user.role === translation.student
                ? USER_ROLE_TRANSLATIONS.STUDENT.BUTTON_TEXT
                : USER_ROLE_TRANSLATIONS.TEACHER.BUTTON_TEXT
            }
            onClick={() =>
              handleChange(
                user.role === translation.student
                  ? translation.teacher
                  : translation.student,
                'role'
              )
            }
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] w-[200px]'
            titleClasses={`text-center text-sm`}
          />
        </div>

        <div className='py-6 flex items-center justify-center'>
          <img src={lehrstellLogo} alt='logo' className='w-[10rem]' />
        </div>

        <div className='text-softBlack font-extrabold text-2xl xs:text-3xl text-center pt-4 pb-8'>
          {user.role === translation.student
            ? USER_ROLE_TRANSLATIONS.STUDENT.TITLE
            : USER_ROLE_TRANSLATIONS.TEACHER.TITLE}
        </div>
        <form
          className='flex flex-col gap-6 items justify-center w-full xs:w-[80%] m-auto'
          onSubmit={verifyAge}
        >
          <InputComponent
            placeholder={translation.firstName}
            value={user.firstName}
            labelClasses='font-bold text-black'
            handleChange={(newValue) => handleChange(newValue, 'firstName')}
            required
          />
          <InputComponent
            placeholder={translation.lastName}
            value={user.lastName}
            labelClasses='font-bold text-black'
            handleChange={(newValue) => handleChange(newValue, 'lastName')}
            required
          />
          <InputComponent
            placeholder={translation.email}
            type='email'
            labelClasses='font-bold text-black'
            value={user.email}
            handleChange={(newValue) => handleChange(newValue, 'email')}
            required
          />
          <InputComponent
            placeholder={translation.password}
            type='password'
            labelClasses='font-bold text-black'
            value={user.password}
            handleChange={(newValue) => handleChange(newValue, 'password')}
            required
            error={!isPasswordStrong}
            helperText={
              !isPasswordStrong
                ? `Das Passwort muss (mind. 8 Zeichen/ Gross- und Kleinbuchstaben/ mind. 1 Zahl) enthalten`
                : ''
            }
          />
          <InputComponent
            placeholder={translation.confirmPassword}
            type='password'
            labelClasses='font-bold text-black'
            value={confirmPassword}
            handleChange={(value) => setConfirmPassword(value)}
            error={confirmPassword !== '' && user.password !== confirmPassword}
            {...(confirmPassword !== '' &&
              user.password !== confirmPassword && {
                helperText: `${translation.passwordMismatch}`,
              })}
            required
          />
          {user.role === translation.student && (
            <UserBirthdaySection
              ref={dateOfBirthRef}
              dateOfBirth={dateOfBirth}
              handleUserDateOfBirthChange={handleUserDateOfBirthChange}
              registerUser={handleRegister}
            />
          )}
          <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChange} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <div className=''>
                  <button
                    onClick={() => window.open(TERMS_PDF, '_blank')}
                    className='text-blue-500 hover:underline underline-offset-2'
                  >
                    Nutzungsbedingungen akzeptieren
                  </button>
                </div>
              }
              checked={isConditionsAccepted}
              onChange={() => setIsConditionsAccepted(!isConditionsAccepted)}
            />
          </FormGroup>
          <Button
            title={translation.register}
            type='submit'
            disabled={
              !performValidation({ ...user, confirmPassword }) ||
              loading ||
              confirmPassword !== user.password ||
              !isCaptchaVerified ||
              !isConditionsAccepted ||
              !isPasswordStrong
            }
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] w-full'
          />
          <div className='text-center text-sm border border-gray-500 p-2 rounded m-auto'>
            Sie haben bereits ein Konto?{' '}
            <Link component={RouterLink} to='/login' underline='hover'>
              Hier anmelden
            </Link>
          </div>
        </form>
      </Box>
      <CustomModal
        open={showModal}
        handleClose={handleCloseRegisterModal}
        containerClasses='w-[90%] max-w-[565px]'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <div className='text-softBlack font-bold text-2xl'>
            Registrierung erfolgreich!
          </div>
          <div className='text-center text-softBlack'>
            Wir haben eine E-Mail mit einem Bestätigungslink zur Registrierung
            deines Profils an deine E-Mail-Adresse gesendet. Bitte klicke auf
            den Bestätigungslink, um dein Profil zu aktivieren.
          </div>
          <Button
            title='OK'
            onClick={handleCloseRegisterModal}
            type='button'
            containerClasses='bg-[#458EFF] text-white w-full rounded-md h-[40px]'
          />
        </div>
      </CustomModal>
      <CustomVideoPlayerModal
        open={shouldPlayVideo}
        handleClose={() => setShouldPlayVideo(false)}
        videoURL={LandingPageIntroductionVideo}
      />
    </>
  )
}

export default Register

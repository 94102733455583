import * as React from "react"

const SchoolIcon = () => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={0.5} fill="#05060E">
      <path d="M10.01 14.756c-.71 0-1.426-.183-1.984-.541L3.009 10.94a3.27 3.27 0 0 1-1.491-2.75 3.27 3.27 0 0 1 1.491-2.75l5.025-3.267c1.117-.725 2.859-.725 3.967.008l4.992 3.275a3.278 3.278 0 0 1 0 5.484L12 14.215c-.558.366-1.275.541-1.992.541Zm0-11.875c-.476 0-.95.109-1.292.342L3.7 6.49c-.583.383-.925 1-.925 1.7s.333 1.316.925 1.7l5.017 3.275c.691.45 1.908.45 2.6 0l4.991-3.275c.584-.384.917-1 .917-1.7s-.333-1.317-.917-1.7l-4.991-3.275c-.35-.217-.825-.334-1.309-.334Z" />
      <path d="M10 19.548c-.366 0-.741-.05-1.041-.15L6.3 18.515c-1.258-.417-2.25-1.792-2.241-3.117l.008-3.908a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625l-.008 3.908c0 .783.65 1.683 1.391 1.933l2.659.884c.333.108.95.108 1.283 0l2.658-.884c.742-.25 1.392-1.15 1.392-1.925V11.54a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v3.866c0 1.325-.983 2.692-2.242 3.117l-2.658.883c-.3.092-.675.142-1.042.142ZM17.834 13.715a.63.63 0 0 1-.625-.625v-5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v5a.63.63 0 0 1-.625.625Z" />
    </g>
  </svg>
)

export default SchoolIcon

import React from 'react'
import { InfoOutlined } from '@mui/icons-material'

const ZoomInstructionsButton = ({ containerClasses, onClick }) => {
  return (
    <div className={`${containerClasses} cursor-pointer hover:scale-105 transition-all`} onClick={onClick}>
      <div className='flex justify-end mb-1'>
        <InfoOutlined className='bg-[#B6CCD8] text-white rounded-full' />
      </div>
      <div className='bg-[#B6CCD8] text-white px-3 rounded-full'>
        optimale Ansicht
      </div>
    </div>
  )
}

export default ZoomInstructionsButton

import React from 'react'

import DeleteIcon from 'assets/icons/delete'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import CustomInput from 'components/Input'
import { translation } from 'utils/germanTranslation'
import Button from 'components/Button'

const FamilyDetail = ({
  value,
  handleChange,
  setValue,
  onUpdateClick,
  isLoading,
}) => {
  return (
    <SimpleEditWrapper customized={true} showAddIcon={false}>
      <h3>
        Falls nur ein Elternteil, Felder einfach leer lassen. Feld Beruf kann
        auch leer gelassen werden.
      </h3>
      <div className='grid grid-cols-none sm:grid-cols-3 gap-6 items-center'>
        <CustomInput
          label={`${translation.firstName} des ${translation.father}s`}
          type='text'
          value={value.fatherFirstName}
          handleChange={(newValue) => handleChange(newValue, 'fatherFirstName')}
        />
        <CustomInput
          label={`${translation.lastName} des ${translation.father}s`}
          type='text'
          value={value.fatherLastName}
          handleChange={(newValue) => handleChange(newValue, 'fatherLastName')}
        />
        <CustomInput
          label='Beruf des Vaters'
          type='text'
          value={value.fatherOccupation}
          handleChange={(newValue) =>
            handleChange(newValue, 'fatherOccupation')
          }
        />
        <CustomInput
          label={`${translation.firstName} der ${translation.mother}`}
          type='text'
          value={value.motherFirstName}
          handleChange={(newValue) => handleChange(newValue, 'motherFirstName')}
        />
        <CustomInput
          label={`${translation.lastName} der ${translation.mother}`}
          type='text'
          value={value.motherLastName}
          handleChange={(newValue) => handleChange(newValue, 'motherLastName')}
        />
        <CustomInput
          label='Beruf der Mutter'
          type='text'
          value={value.motherOccupation}
          handleChange={(newValue) =>
            handleChange(newValue, 'motherOccupation')
          }
        />
        <div className='col-span-full grid grid-cols-3'>
          <SimpleEditWrapper
            customized
            showAddIcon
            addAnotherField={() => {
              setValue({
                ...value,
                siblings: [
                  ...value.siblings,
                  {
                    siblingName: '',
                    siblingOccupation: '',
                  },
                ],
              })
            }}
            pageTitle={translation.sibling}
            containerClasses='p-0 col-span-full'
          >
            {value.siblings.length ? (
              value.siblings.map((sibling, index) => (
                <div
                  key={index}
                  className=' gap-6 grid grid-cols-none sm:grid-cols-2 md:grid-cols-3 items-center'
                >
                  <CustomInput
                    label={`Name des ${translation.sibling}s ${index + 1}`}
                    type='text'
                    value={sibling.siblingName}
                    handleChange={(newValue) =>
                      setValue({
                        ...value,
                        siblings: [
                          ...value.siblings.slice(0, index),
                          {
                            ...value.siblings[index],
                            siblingName: newValue,
                          },
                          ...value.siblings.slice(index + 1),
                        ],
                      })
                    }
                  />
                  <CustomInput
                    label={`Beruf/Schule Geschwister ${index + 1}`}
                    type='text'
                    value={sibling.siblingOccupation}
                    handleChange={(newValue) =>
                      setValue({
                        ...value,
                        siblings: [
                          ...value.siblings.slice(0, index),
                          {
                            ...value.siblings[index],
                            siblingOccupation: newValue,
                          },
                          ...value.siblings.slice(index + 1),
                        ],
                      })
                    }
                  />
                  <div
                    className='mt-4 mx-auto sm:mx-0'
                    onClick={() => {
                      setValue({
                        ...value,
                        siblings: [
                          ...value.siblings.slice(0, index),
                          ...value.siblings.slice(index + 1),
                        ],
                      })
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ))
            ) : (
              <p className='text-center'>
                Wenn keine Geschwister, dann nichts ausfüllen, sonst unten auf{' '}
                <span className='text-[#458eff] font-medium'>
                  Geschwister hinzufügen
                </span>{' '}
                klicken.
              </p>
            )}
          </SimpleEditWrapper>
        </div>
      </div>
      <div className='flex justify-end'>
        <Button
          title={translation.update}
          onClick={onUpdateClick}
          titleClasses='text-white text-lg font-semibold'
          containerClasses='rounded-md w-full sm:w-[138px] h-[60px] bg-[#458EFF]'
          disabled={isLoading}
        />
      </div>
    </SimpleEditWrapper>
  )
}

export default FamilyDetail

import * as React from "react"

const DocumentIcon = () => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={28} height={28} rx={14} fill="#D5EBFB" />
    <path
      d="M16.533 7.473c-.273-.273-.746-.087-.746.293v2.327c0 .973.826 1.78 1.833 1.78.633.007 1.513.007 2.267.007.38 0 .58-.447.313-.714-.96-.966-2.68-2.706-3.667-3.693Z"
      fill="#209ADA"
    />
    <path
      d="M19.666 12.793H17.74a2.87 2.87 0 0 1-2.867-2.866V8c0-.367-.3-.667-.666-.667H11.38c-2.054 0-3.714 1.334-3.714 3.714v5.906c0 2.38 1.66 3.714 3.714 3.714h5.24c2.053 0 3.713-1.334 3.713-3.714V13.46c0-.367-.3-.667-.666-.667Zm-6 5.04H11a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h2.666c.274 0 .5.227.5.5 0 .274-.226.5-.5.5ZM15 15.168h-4a.504.504 0 0 1-.5-.5c0-.274.226-.5.5-.5h4c.273 0 .5.226.5.5 0 .273-.227.5-.5.5Z"
      fill="#209ADA"
    />
  </svg>
)

export default DocumentIcon

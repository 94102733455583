import React from 'react';

const SmallEditIcon = ({ containerClasses }) => {
  return (
    <div className={`cursor-pointer ${containerClasses}`}>
      <svg
        width={30}
        height={30}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx={15}
          cy={15}
          r={14}
          fill='#fff'
          stroke='#E8E8E8'
          strokeWidth={2}
        />
        <g opacity={0.8} fill='#05060E'>
          <path d='M16.125 20.323h-3.25c-2.941 0-4.198-1.257-4.198-4.198v-3.25c0-2.941 1.257-4.198 4.198-4.198h1.084a.41.41 0 0 1 .406.407.41.41 0 0 1-.406.406h-1.084c-2.497 0-3.385.888-3.385 3.385v3.25c0 2.497.888 3.386 3.385 3.386h3.25c2.497 0 3.386-.889 3.386-3.386v-1.083a.41.41 0 0 1 .406-.406.41.41 0 0 1 .406.406v1.083c0 2.941-1.257 4.198-4.198 4.198Z' />
          <path d='M12.604 17.582c-.33 0-.634-.119-.856-.335-.265-.266-.379-.65-.32-1.057l.233-1.63c.044-.314.25-.72.472-.943L16.4 9.35c1.078-1.078 2.172-1.078 3.25 0 .59.59.856 1.192.802 1.793-.049.487-.309.964-.802 1.452l-4.268 4.268a1.878 1.878 0 0 1-.943.471l-1.63.233c-.07.016-.141.016-.206.016Zm4.371-7.659-4.268 4.268a1.103 1.103 0 0 0-.244.482l-.233 1.631c-.021.157.011.287.092.368.082.081.212.114.369.092l1.63-.232c.14-.022.385-.141.482-.244l4.268-4.269c.353-.352.537-.666.564-.958.032-.352-.152-.726-.564-1.143-.866-.867-1.462-.623-2.096.005Z' />
          <path d='M18.752 13.324a.35.35 0 0 1-.108-.016 4.3 4.3 0 0 1-2.958-2.957.411.411 0 0 1 .282-.504.403.403 0 0 1 .498.282 3.48 3.48 0 0 0 2.394 2.394c.217.06.342.287.282.504a.399.399 0 0 1-.39.297Z' />
        </g>
      </svg>
    </div>
  );
};

export default SmallEditIcon;

import React, { useState } from 'react'
import Navbar from 'components/Navbar/Navbar'
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import CustomInput from 'components/Input'
import Button from 'components/Button'
import { ROUTES } from 'utils/routes'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'
import ReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'utils'
import { useEffect } from 'react'
import moment from 'moment'
import { translation } from 'utils/germanTranslation'
import { Checkbox, FormControlLabel, FormGroup, Link } from '@mui/material'

const SCREEN = 'screen'
const RegisterScreen = 'register'
const EmailScreen = 'email'
const WatchProfileScreen = 'watch-profile'

const initialValueState = {
  [RegisterScreen]: {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
  },
  [EmailScreen]: {
    email: '',
  },
  [WatchProfileScreen]: {
    email: '',
    code: '',
  },
}

const initialErrorState = Object.keys(initialValueState['register']).reduce(
  (acc, key) => {
    acc[key] = false
    return acc
  },
  {}
)

const WatchProfile = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const [params, setParams] = useSearchParams()

  const [value, setValue] = useState(initialValueState)
  const [errors, setErrors] = useState(initialErrorState)

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const [isConditionsAccepted, setIsConditionsAccepted] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('accessedUserProfiles')) {
      const accessedUserProfiles = JSON.parse(
        localStorage.getItem('accessedUserProfiles')
      )
      const userProfile = accessedUserProfiles.find(
        (item) => item.sharedProfileUserId === userId
      )
      if (userProfile) {
        const diffDays = moment().diff(
          moment(userProfile.codeUpdatedAt),
          'days'
        )
        if (diffDays > 365) {
          ShowToast({
            message:
              'Your access code has expired. Please request a new access code.',
          })
          return
        }
        navigate(
          ROUTES({
            userId: userProfile.token,
          }).student.dashboard,
          {
            state: {
              fromShareProfile: true,
            },
          }
        )
      }
    }
  }, [userId])

  const handleValueChange = (newValue, screen, field) => {
    setErrors(initialErrorState)
    setValue({
      ...value,
      [screen]: {
        ...value[screen],
        [field]: newValue,
      },
    })
  }

  const getNewAccessCode = async (e) => {
    e.preventDefault()
    const { email } = value[EmailScreen]

    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.shareProfile.getNewAccessCode,
        { value: { email, userId } },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      ShowToast({ type: 'success', message: data.message })
      setParams({ [SCREEN]: WatchProfileScreen })
      handleValueChange(email, WatchProfileScreen, 'email')
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleWatchProfile = async (e) => {
    e.preventDefault()
    const { email } = value[WatchProfileScreen]

    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.shareProfile.watchProfile,
        { value: { email, userId, code: value[WatchProfileScreen].code } },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      const newUserProfile = {
        sharedProfileUserId: userId,
        code: value[WatchProfileScreen].code,
        codeUpdatedAt: new Date(),
        token: data.token,
      }
      if (localStorage.getItem('accessedUserProfiles')) {
        const accessedUserProfiles = JSON.parse(
          localStorage.getItem('accessedUserProfiles')
        )
        let temp = accessedUserProfiles.map((item) => item.userId !== userId)
        localStorage.setItem(
          'accessedUserProfiles',
          JSON.stringify([...temp, newUserProfile])
        )
      } else {
        localStorage.setItem(
          'accessedUserProfiles',
          JSON.stringify([newUserProfile])
        )
      }
      ShowToast({ type: 'success', message: data.message })

      navigate(
        ROUTES({
          userId: data.token,
        }).student.dashboard,
        {
          state: {
            fromShareProfile: true,
          },
        }
      )
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const registerHR = async (e) => {
    e.preventDefault()
    const { email, firstName, lastName, company } = value[RegisterScreen]
    let tempErrors = initialErrorState
    Object.keys(value[RegisterScreen]).forEach((key) => {
      if (!value.register[key]) {
        tempErrors = { ...tempErrors, [key]: true }
      }
    })

    if (Object.keys(tempErrors).find((key) => Boolean(tempErrors[key]))) {
      setErrors(tempErrors)
      return
    }

    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.shareProfile.registerHR,
        {
          value: {
            email,
            firstName,
            lastName,
            company,
            codeUpdatedAt: new Date(),
            userId,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      ShowToast({ type: 'success', message: data.message })
      setParams({ [SCREEN]: WatchProfileScreen })
      handleValueChange(value.register.email, WatchProfileScreen, 'email')
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const onChange = (value) => {
    if (value) setIsCaptchaVerified(true)
  }

  return (
    <div className='min-h-screen h-full bg-softWhite'>
      <Navbar />
      <div className='m-auto flex items-center justify-center min-h-screen my-6 sm:my-0'>
        <div className='p-4 bg-white w-[90%] max-w-[600px] rounded-xl shadow my-4'>
          {params.get(SCREEN) === EmailScreen ? (
            <form onSubmit={getNewAccessCode}>
              <div className='flex justify-between items-center '>
                <CustomInput
                  label='Email'
                  inputBackgroundColor='#eff1f4'
                  value={value[EmailScreen].email}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, EmailScreen, 'email')
                  }
                />
              </div>
              <div className='flex gap-4 justify-between'>
                <Button
                  title={translation.back}
                  onClick={(e) => {
                    e.preventDefault()
                    setParams({ [SCREEN]: RegisterScreen })
                  }}
                  titleClasses='text-white text-sm font-semibold'
                  containerClasses='rounded-md w-full sm:w-[196px] h-[46px] bg-[#458EFF] mt-4'
                  type='button'
                />
                <Button
                  title='Zugangscode anfordern'
                  titleClasses='text-white text-sm font-semibold'
                  containerClasses='rounded-md w-full sm:w-[130px] h-[46px] bg-[#458EFF] mt-4'
                  type='submit'
                />
              </div>
            </form>
          ) : params.get(SCREEN) === WatchProfileScreen ? (
            <form onSubmit={handleWatchProfile}>
              <h1 className='text-softBlack'>
                Wir haben Ihnen gerade eine E-Mail mit einem Zugangscode
                gesendet. Bitte überprüfen Sie Ihren Posteingang (Spam/Junk).
              </h1>

              <div className='flex flex-col gap-2 my-4'>
                <div className='flex justify-between items-center '>
                  <CustomInput
                    label='Email'
                    inputBackgroundColor='#eff1f4'
                    value={value[WatchProfileScreen].email}
                    handleChange={(newValue) =>
                      handleValueChange(newValue, WatchProfileScreen, 'email')
                    }
                  />
                </div>
                <div className='flex justify-between items-center '>
                  <CustomInput
                    label='Zugangscode'
                    inputBackgroundColor='#eff1f4'
                    value={value[WatchProfileScreen].code}
                    handleChange={(newValue) =>
                      handleValueChange(newValue, WatchProfileScreen, 'code')
                    }
                  />
                </div>
              </div>

              <div className='flex gap-4 justify-between'>
                <Button
                  title={translation.back}
                  onClick={(e) => {
                    e.preventDefault()
                    setParams({ [SCREEN]: RegisterScreen })
                  }}
                  titleClasses='text-white text-sm font-semibold'
                  containerClasses='rounded-md w-full sm:w-[196px] h-[46px] bg-[#458EFF] mt-4'
                  type='button'
                />
                <Button
                  title='Profil ansehen'
                  titleClasses='text-white text-sm font-semibold'
                  containerClasses='rounded-md w-full sm:w-[130px] h-[46px] bg-[#458EFF] mt-4'
                  type='submit'
                />
              </div>
            </form>
          ) : (
            <>
              <form className='text-softBlack border-b border-black pb-4'>
                <h1 className='font-semibold'>Registrierung</h1>
                <div className='flex flex-col gap-2 my-4'>
                  <div className='flex justify-between items-center '>
                    <CustomInput
                      label='Email*'
                      inputBackgroundColor='#eff1f4'
                      value={value[RegisterScreen].email}
                      type='email'
                      handleChange={(newValue) =>
                        handleValueChange(newValue, RegisterScreen, 'email')
                      }
                      required
                      error={errors.email}
                      helperText={errors.email && 'Pflichtfeld'}
                    />
                  </div>
                  <div className='flex justify-between items-center '>
                    <CustomInput
                      label={translation.firstName + '*'}
                      inputBackgroundColor='#eff1f4'
                      value={value[RegisterScreen].firstName}
                      handleChange={(newValue) =>
                        handleValueChange(newValue, RegisterScreen, 'firstName')
                      }
                      error={errors.firstName}
                      helperText={errors.firstName && 'Pflichtfeld'}
                      required
                    />
                  </div>
                  <div className='flex justify-between items-center '>
                    <CustomInput
                      label={translation.lastName + '*'}
                      inputBackgroundColor='#eff1f4'
                      value={value[RegisterScreen].lastName}
                      handleChange={(newValue) =>
                        handleValueChange(newValue, RegisterScreen, 'lastName')
                      }
                      error={errors.lastName}
                      helperText={errors.lastName && 'Pflichtfeld'}
                      required
                    />
                  </div>
                  <div className='flex justify-between items-center '>
                    <CustomInput
                      label={translation.companyName + '*'}
                      inputBackgroundColor='#eff1f4'
                      value={value[RegisterScreen].company}
                      handleChange={(newValue) =>
                        handleValueChange(newValue, RegisterScreen, 'company')
                      }
                      error={errors.company}
                      helperText={errors.company && 'Pflichtfeld'}
                      required
                    />
                  </div>
                </div>
                <div className='flex flex-col-reverse sm:flex-row-reverse justify-between gap-8'>
                  <Button
                    title='Zugangscode anfordern'
                    onClick={registerHR}
                    disabled={!(isConditionsAccepted && isCaptchaVerified)}
                    titleClasses='text-white text-sm font-semibold'
                    containerClasses='rounded-md w-full sm:w-[130px] h-[46px] bg-[#458EFF] mt-4 '
                  />
                  <div>
                    <ReCAPTCHA
                      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                      onChange={onChange}
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <div className=''>
                            Akzeptiere die{' '}
                            <Link
                              component={RouterLink}
                              to='/terms-of-use'
                              className=''
                            >
                              Nutzungsbedingungen
                            </Link>
                          </div>
                        }
                        checked={isConditionsAccepted}
                        onChange={() =>
                          setIsConditionsAccepted((prevValue) => !prevValue)
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </form>
              <div className='flex items-center justify-center flex-col py-5'>
                <h1 className='text-lg font-medium'>Bereits registriert?</h1>
                <div className='flex gap-4 justify-center sm:justify-end'>
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      setParams({ [SCREEN]: EmailScreen })
                    }}
                    title='Fordern Sie einen neuen Zugangscode an'
                    titleClasses='text-white text-sm font-bold'
                    containerClasses='rounded-md w-full p-2 bg-[#4EEC4C] mt-4'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default WatchProfile

import * as React from 'react'

const LocationIcon = () => (
  <svg
    width='20'
    height='20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
  >
    <path
      d='M10 11.808a3.22 3.22 0 1 1 0-6.442 3.22 3.22 0 1 1 0 6.442Zm0-5.191a1.974 1.974 0 1 0 0 3.95 1.974 1.974 0 1 0 0-3.95Z'
      fill='#05060E'
    ></path>
    <path
      d='M10 18.966a4.976 4.976 0 0 1-3.441-1.391C4.1 15.208 1.384 11.433 2.409 6.942c.925-4.075 4.483-5.9 7.591-5.9h.009c3.108 0 6.666 1.824 7.591 5.908 1.017 4.491-1.7 8.258-4.158 10.625A4.976 4.976 0 0 1 10 18.967Zm0-16.675c-2.425 0-5.541 1.292-6.366 4.925-.9 3.926 1.566 7.309 3.8 9.45a3.688 3.688 0 0 0 5.141 0c2.225-2.141 4.692-5.524 3.809-9.45C15.55 3.583 12.425 2.292 10 2.292Z'
      fill='#05060E'
    ></path>
  </svg>
)

export default LocationIcon

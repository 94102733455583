import axios from 'axios'
import moment from 'moment'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useGetStudentPersonalData = (id) => {
  const [value, setValue] = useState()
  const [loading, setLoading] = useState(true)

  const getUserData = async () => {
    const { data } = await axios.get(BACKEND_URL + API_URL.student.personal, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        id,
      },
    })
    const temp = data.data
    temp.siblings = JSON.parse(temp.siblings)
    const strengths = JSON.parse(temp.strengths)
    const weaknesses = JSON.parse(temp.weaknesses)
    temp.strengths = strengths.length ? strengths : ['']
    temp.weaknesses = weaknesses.length ? weaknesses : ['']
    temp.references = JSON.parse(temp.references)
    temp.dateOfBirth = moment(temp.dateOfBirth).utc()
    setValue(temp)
    setLoading(false)
  }

  useEffect(() => {
    getUserData()
  }, [id])

  return { personalData: value, loading }
}

export default useGetStudentPersonalData

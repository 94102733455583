import * as React from 'react';

const JobInformationIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={149} height={130} fill='none'>
    <path fill='#F9EDB0' d='M.057 65a65 65 0 1 0 130 0 65 65 0 0 0-130 0Z' />
    <path fill='#F0DB6C' d='M50.227 8.173H91.62v38.631H50.227V8.173Z' />
    <path fill='#fff' d='M66.788 8.173h8.278v19.315h-8.278V8.173Z' />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M66.788 8.173h8.278v19.315h-8.278V8.173Z'
    />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M50.227 8.173H91.62v38.631H50.227V8.173Z'
    />
    <path
      fill='#F0DB6C'
      d='M58.35 117.188c0 .336.264.669.779.979.514.311 1.269.593 2.22.831.95.237 2.078.426 3.32.554 1.243.129 2.574.195 3.918.195 1.344 0 2.676-.066 3.918-.195 1.242-.128 2.37-.317 3.321-.554.95-.238 1.705-.52 2.22-.831.514-.31.779-.643.779-.979 0-.336-.265-.669-.78-.98-.514-.31-1.268-.592-2.219-.83-.95-.238-2.08-.426-3.321-.555a38.594 38.594 0 0 0-3.918-.195c-1.344 0-2.675.067-3.918.195-1.242.129-2.37.317-3.32.555-.951.238-1.706.52-2.22.83-.515.311-.78.644-.78.98ZM21.668 80.245h24.74v20.475h-24.74V80.245Z'
    />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M21.668 80.245h24.74v20.475h-24.74V80.245Z'
    />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M30.207 80.245h7.678v9.384h-7.678v-9.384Z'
    />
    <path fill='#F0DB6C' d='M58.35 99.037h20.9v17.298h-20.9V99.037Z' />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M58.35 99.037h20.9v17.298h-20.9V99.037Z'
    />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M65.573 99.037h6.487v7.928h-6.487v-7.928Z'
    />
    <path fill='#F0DB6C' d='M11.429 39.294h27.594v25.753H11.429V39.294Z' />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M22.46 39.294h5.52v12.877h-5.52V39.294Z'
    />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M11.429 39.294h27.594v25.753H11.429V39.294Z'
    />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M54.399 68.413a35.5 35.5 0 1 0 60.561-37.06A35.5 35.5 0 0 0 54.4 68.412Z'
    />
    <path
      fill='#F0DB6C'
      d='M73.294 75.906A28.4 28.4 0 1 0 96.037 23.86a28.4 28.4 0 0 0-22.743 52.047Z'
    />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M104.741 29.802a28.397 28.397 0 0 1-20.08 48.48 28.399 28.399 0 1 1 20.08-48.48Z'
    />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='m121.492 78.323-7.877-7.877a35.823 35.823 0 0 1-6.47 6.918l7.653 7.654 6.694-6.695Z'
    />
    <path
      fill='#F0DB6C'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='m145.924 109.14-.325.325a6.9 6.9 0 0 1-9.724 0l-21.13-21.141a2.3 2.3 0 0 1 0-3.242l6.802-6.8a2.3 2.3 0 0 1 3.241 0l21.136 21.134a6.86 6.86 0 0 1 1.494 7.495 6.848 6.848 0 0 1-1.494 2.229ZM56.333 51.921a28.411 28.411 0 0 1 40.763-27.578V48.68a3.237 3.237 0 0 1-3.225 3.242H56.333Z'
    />
    <path
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M76.282 39.977v6.826M79.693 41.683v5.119M83.106 39.977v6.826M86.517 41.683v5.119M89.929 39.977v6.826'
    />
    <path
      fill='#fff'
      stroke='#020064'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={0.24}
      d='M78.024 22.263v6.002H66.258a28.328 28.328 0 0 1 11.766-6.002Z'
    />
  </svg>
);

export default JobInformationIcon;

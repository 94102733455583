import * as React from 'react'

const FullscreenIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid meet'
    viewBox='593 174 737 736'
    {...props}
  >
    <g
      transform='translate(0.000000,1080.000000) scale(0.100000,-0.100000)'
      fill='#000000'
      stroke='none'
    >
      <path d='M5930 5380 l0 -3680 3685 0 3685 0 0 3680 0 3680 -3685 0 -3685 0 0 -3680z m7040 0 l0 -3350 -3350 0 -3350 0 0 3350 0 3350 3350 0 3350 0 0 -3350z' />
      <path d='M10570 7835 l0 -165 587 0 588 0 -835 -836 -835 -835 120 -114 120 -114 833 832 832 832 0 -583 0 -582 165 0 165 0 0 865 0 865 -870 0 -870 0 0 -165z' />
      <path d='M8087 4163 l-827 -828 0 583 0 582 -170 0 -170 0 0 -870 0 -870 870 0 870 0 0 170 0 170 -582 0 -583 0 830 830 830 830 -115 115 c-63 63 -117 115 -120 115 -3 0 -377 -372 -833 -827z' />
    </g>
  </svg>
)
export default FullscreenIcon

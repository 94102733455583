import * as React from 'react';

const AdminEmailStatsIcon = () => (
  <svg width={92} height={92} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect opacity={0.1} width={92} height={92} rx={24} fill='#458EFF' />
    <path
      d='M45.996 62.516c-1.168 0-2.269-.584-3.056-1.618l-2.044-2.719h-.562c-6.09 0-10.135-1.73-10.135-10.135V40.99c0-6.023 3.079-9.596 8.674-10.068.427-.044.944-.067 1.46-.067h11.304c6.539 0 10.134 3.595 10.134 10.135v7.056c0 .539-.022 1.033-.09 1.527-.471 5.528-4.044 8.607-10.067 8.607h-.561l-2.045 2.72c-.742 1.033-1.843 1.617-3.012 1.617Zm-5.64-28.314c-.382 0-.764.023-1.123.068-3.933.337-5.663 2.404-5.663 6.718v7.056c0 5.798 1.685 6.764 6.764 6.764h.696c.9 0 1.91.495 2.472 1.214l2.135 2.854c.135.18.27.27.337.27s.202-.09.337-.27l2.112-2.832a3.076 3.076 0 0 1 2.472-1.236h.697c4.314 0 6.382-1.73 6.719-5.595.045-.427.067-.787.067-1.191v-7.056c0-4.674-2.09-6.764-6.764-6.764H40.356Z'
      fill='#458EFF'
    />
    <path
      d='M52.738 70.156H39.255c-12.202 0-17.415-5.213-17.415-17.415V39.258c0-12.202 5.213-17.415 17.415-17.415h13.483c12.202 0 17.415 5.213 17.415 17.415v13.483c0 12.202-5.213 17.415-17.415 17.415ZM39.255 25.214c-10.36 0-14.045 3.685-14.045 14.044v13.483c0 10.36 3.686 14.044 14.045 14.044h13.483c10.36 0 14.044-3.685 14.044-14.044V39.258c0-10.359-3.685-14.044-14.044-14.044H39.255Z'
      fill='#458EFF'
    />
  </svg>
);

export default AdminEmailStatsIcon;

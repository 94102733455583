import React from 'react';
import { Typography } from '@mui/material';

const NoAccess = () => {
  return (
    <Typography color='red' textAlign='center' className='p-4'>
      You don't have access to edit this page
    </Typography>
  );
};

export default NoAccess;

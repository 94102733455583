import React from 'react'

import Navbar from 'components/Navbar/Navbar'
import PDFIcon from 'assets/icons/pdf'

const TermsOfUse = () => {
  const documents = [
    {
      title: 'Nutzungsbedingungen',
      pdf: require(`../../assets/documents/Nutzungsbedingungen_rechtliche_dokumente.pdf`),
    },
    {
      title: 'AGB für Kunden',
      pdf: require(`../../assets/documents/AGB_für_Kunden_rechtliche_dokumente.pdf`),
    },
    {
      title: 'Cookie Richtlinie',
      pdf: require(`../../assets/documents/Cookie_Richtlinie_rechtliche_dokumente.pdf`),
    },
    {
      title: 'Datenschutzerklärung',
      pdf: require(`../../assets/documents/Datenschutzerklärung_rechtliche_dokumente.pdf`),
    },
    {
      title: 'Einwilligungserklärung_lehrstell.ch',
      pdf: require(`../../assets/documents/Footer/Other/Einwilligungserklärung_lehrstell.ch.pdf`),
    },
  ]

  const openPdfInNewTab = (pdf) => {
    // Use the imported PDF as the URL
    window.open(pdf, '_blank')
  }

  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen p-6 w-full flex gap-4 items-center justify-center text-softBlack'>
        <div className='w-full font-bold text-2xl text-softBlack underline'>
          Rechtliche Dokumente
        </div>
        <div className='bg-white rounded-lg p-4 flex flex-col gap-4 w-full pb-12'>
          {documents.map(({ title, pdf }) => (
            <button
              className='flex items-center gap-4'
              onClick={() => openPdfInNewTab(pdf)}
            >
              <PDFIcon width={40} height={40} />
              {title}
            </button>
          ))}
          <h1 className='ml-14 mt-4 italic text-gray-600'>
            Kontakt: info@lehrstell.ch
          </h1>
        </div>
      </div>
    </>
  )
}

export default TermsOfUse

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from 'store/configureStore';
import App from './App';
import MaterialUICustomization from './components/MaterialUICustomization';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MaterialUICustomization>
        <App />
      </MaterialUICustomization>
    </PersistGate>
  </Provider>
);

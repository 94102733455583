import React from 'react'

const InfoLinkSearchIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={25}
      viewBox='0 0 202 202'
    >
      <path d='M75.5 3.1c-14.8 1.4-28.8 7-41.2 16.4C12.4 35.9-.1 65 3 92.3c1.1 9.2 4.4 20.6 8.2 28 6.3 12.6 19.3 26.3 31.4 33.5 25.4 15 57.7 14.9 83.4-.4l5.5-3.3 24.5 24.5c17.2 17.1 25.5 24.7 27.7 25.3 6.8 2 15.3-3.1 16.6-9.9 1.5-7.1.6-8.3-24.9-34-13.4-13.5-24.4-24.9-24.4-25.3 0-.4 1.4-2.9 3.1-5.6 7.9-12.9 11.3-27.3 10.6-44.4-.6-14.5-2.2-21.1-8.1-33.2C141.8 17.4 109.5-.2 75.5 3.1zM101.6 19c17.6 4.5 33.7 17.6 41.9 34.2 5.9 12.1 7.9 21.2 7.2 34.3-1 17.8-7 31-20.1 44C109.9 152 81 156.6 54 143.8c-11.9-5.7-23.9-17.7-29.8-29.8-18.9-38.3 1.5-84.4 42-95 10.1-2.6 25-2.6 35.4 0z' />
      <path d='M58.5 37.3C42.8 45.7 31 64.8 31 81.8c0 4.3.4 5.4 2.6 7.1 5.9 4.6 10.7 1 11.7-8.9 1.4-12.8 9.6-24.9 20.9-30.6 7.3-3.6 8.8-5.2 8.8-8.9 0-7.1-6.9-8.4-16.5-3.2z' />
    </svg>
  )
}

export default InfoLinkSearchIcon

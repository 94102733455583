import { Paragraph, Document, Packer, TextRun } from 'docx'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { Apprenticeship, TrialApprenticeship } from './Step1'
import {
  ApprenticeshipQuestions,
  TrialApprenticeshipQuestions,
} from './Step2/Questions'
import { getLetterTitle } from '../MotivationLetter/RowTemplate'
import {
  GENDERS,
  getGreetingBasedOnGender,
  getRecipientGender,
  UNKNOWN_GENDER,
} from './utils'

const GenerateWordMotivationLetter = ({ user, value }) => {
  const title = (value, bold = false, breakLength = 1) => {
    if (value)
      return new TextRun({
        text: `${value}`,
        bold,
        break: breakLength,
        font: 'Calibri',
        size: 24,
      })
  }

  const LineBreak = () =>
    new TextRun({
      text: ``,
      bold: true,
      break: 2,
    })

  const Questions = () => {
    const temp =
      value.type === TrialApprenticeship
        ? TrialApprenticeshipQuestions.map((question) =>
            title(
              value.questions[question.label]
                ? value.questions[question.label]
                : '',
              false,
              2
            )
          )
        : ApprenticeshipQuestions.map((question) =>
            title(
              value.questions[question.label]
                ? value.questions[question.label]
                : '',
              false,
              2
            )
          )

    return temp
  }

  const TrialTimingsTemplate = () => {
    if (value.type === TrialApprenticeship) {
      const heading = [
        new TextRun({
          text: `Ideale Termine zum Schnuppern sind für mich`,
          break: 2,
          font: 'Calibri',
          size: 24,
        }),
      ]
      const timings = Object.keys(value.appointments).map((key, index) =>
        title(
          `${moment(value.appointments[index + 1].from).format(
            'DD.MM.YYYY'
          )} - ${moment(value.appointments[index + 1].to).format('DD.MM.YYYY')}`
        )
      )
      return [...heading, ...timings]
    }
    return []
  }
  const greetingBasedOnGender = getGreetingBasedOnGender(
    value?.recipient?.gender,
    value?.recipient,
    GENDERS,
    UNKNOWN_GENDER
  )

  const recipientGender = getRecipientGender(value)

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                title(
                  [user.firstName, user.lastName].filter(Boolean).join(' '),
                  false,
                  0
                ),
                title(user.address),
                title([user.postalCode, user.place].filter(Boolean).join(' ')),
                title(user.email),
                title(user.phone),

                LineBreak(),

                title(value.company.name || ''),
                title(
                  [
                    recipientGender,
                    value?.recipient?.firstName,
                    value?.recipient?.lastName,
                  ]
                    .filter(Boolean)
                    .join(' ')
                ),
                title(value.company.address ? value.company.address : ''),
                title(
                  `${
                    value.company.postcode ? value.company.postcode + ' ' : ''
                  }${value.company.city ? value.company.city : ''}`
                ),

                LineBreak(),

                title(
                  [user.place, moment(new Date()).format('DD. MMMM YYYY')]
                    .filter(Boolean)
                    .join(', ')
                ),

                LineBreak(),

                title(
                  [
                    'Bewerbung um eine',
                    value?.type === TrialApprenticeship
                      ? 'Schnupperlehre'
                      : 'Lehrstelle',
                    'als',
                    value?.job?.title,
                    value?.job?.type,
                  ]
                    .filter(Boolean)
                    .join(' '),
                  true
                ),

                LineBreak(),

                title(greetingBasedOnGender),

                ...Questions(),

                ...TrialTimingsTemplate(),

                LineBreak(),

                value.type === Apprenticeship
                  ? title(
                      `Ich freue mich, wenn Sie mich zu einem Vorstellungsgespräch einladen, damit ich Sie von meiner Persönlichkeit überzeugen kann und warte gespannt auf Ihre Antwort.`
                    )
                  : title('Ich freue mich von Ihnen zu hören.'),

                LineBreak(),

                title('Freundliche Grüsse'),

                LineBreak(),

                title(`${user.firstName} ${user.lastName}`),

                LineBreak(),
                LineBreak(),
                LineBreak(),

                title(value.attachments.length ? 'Beilagen' : ''),
                title(value.attachments.join(' / ')),
              ],
            }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, getLetterTitle(value, user))
    })
  }

  return (
    <li className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'>
      <p onClick={generate}>Motivationsschreiben als WORD herunterladen</p>
    </li>
  )
}

export default GenerateWordMotivationLetter

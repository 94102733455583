import React from 'react'

const VideoUnavailableIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='80.93 36 69.48 44.07'
      width={55}
      className='mt-3'
    >
      <defs>
        <clipPath id='a'>
          <path d='M80.926 36.047h53.015V77H80.926Zm0 0' />
        </clipPath>
        <clipPath id='b'>
          <path d='M127 58h8.473v16.105H127Zm0 0' />
        </clipPath>
        <clipPath id='c'>
          <path d='M119.887 54.504h22.398v22.402h-22.398Zm0 0' />
        </clipPath>
      </defs>
      <g clipPath='url(#a)'>
        <path
          fill='#a6a6a6'
          d='M129.809 36.004h-45.09a3.726 3.726 0 0 0-3.727 3.726V73.22a3.733 3.733 0 0 0 3.727 3.734h45.09a3.733 3.733 0 0 0 3.726-3.734V39.73a3.726 3.726 0 0 0-3.726-3.726m-24.989 5.535a1.25 1.25 0 0 1 1.25-1.254h2.38c.69 0 1.257.559 1.257 1.254v2.375c0 .691-.566 1.258-1.258 1.258h-2.379c-.691 0-1.25-.567-1.25-1.258ZM91.293 71.402c0 .696-.566 1.262-1.262 1.262h-2.375c-.691 0-1.25-.566-1.25-1.262v-2.375c0-.691.559-1.25 1.25-1.25h2.375c.696 0 1.262.559 1.262 1.25Zm0-27.488c0 .691-.566 1.258-1.262 1.258h-2.375c-.691 0-1.25-.567-1.25-1.258V41.54a1.25 1.25 0 0 1 1.25-1.254h2.375c.696 0 1.262.559 1.262 1.254Zm9.207 27.488c0 .696-.566 1.262-1.258 1.262h-2.379c-.691 0-1.25-.566-1.25-1.262v-2.375c0-.691.559-1.25 1.25-1.25h2.38c.69 0 1.257.559 1.257 1.25Zm0-27.488c0 .691-.566 1.258-1.258 1.258h-2.379c-.691 0-1.25-.567-1.25-1.258V41.54a1.25 1.25 0 0 1 1.25-1.254h2.38c.69 0 1.257.559 1.257 1.254Zm.656 18.09V50.945c0-1.668 1.758-2.754 3.254-2.008l11.055 5.532c1.656.828 1.656 3.187 0 4.015l-11.059 5.528c-1.492.746-3.25-.34-3.25-2.008m8.551 9.398c0 .696-.566 1.262-1.258 1.262h-2.379c-.691 0-1.25-.566-1.25-1.262v-2.375c0-.691.559-1.25 1.25-1.25h2.38c.69 0 1.257.559 1.257 1.25Zm9.207 0c0 .696-.566 1.262-1.258 1.262h-2.379c-.691 0-1.25-.566-1.25-1.262v-2.375c0-.691.559-1.25 1.25-1.25h2.38c.69 0 1.257.559 1.257 1.25Zm0-27.488c0 .691-.566 1.258-1.258 1.258h-2.379c-.691 0-1.25-.567-1.25-1.258V41.54a1.25 1.25 0 0 1 1.25-1.254h2.38c.69 0 1.257.559 1.257 1.254Zm9.211 27.488c0 .696-.57 1.262-1.262 1.262h-2.375a1.257 1.257 0 0 1-1.254-1.262v-2.375c0-.691.559-1.25 1.254-1.25h2.375c.692 0 1.262.559 1.262 1.25Zm0-27.488c0 .691-.57 1.258-1.262 1.258h-2.375a1.256 1.256 0 0 1-1.254-1.258V41.54a1.25 1.25 0 0 1 1.254-1.254h2.375a1.26 1.26 0 0 1 1.262 1.254Zm0 0'
        />
      </g>
      <g clipPath='url(#b)'>
        <image
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF8AAABaCAIAAABR4x0RAAAABmJLR0QA/wD/AP+gvaeTAAAAyUlEQVR4nO3QsRHAIBDAsJD9d34mwC0U0gQ+r5n5OPhvBzzNneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijvFneJOcae4U9wp7hR3ijtlA0HWA7Et3cOpAAAAAElFTkSuQmCC'
          width={95}
          height={90}
          transform='matrix(.25151 0 0 .2489 126.51 57.677)'
        />
      </g>
      <g clipPath='url(#c)'>
        <path
          fill='#545454'
          d='M142.379 65.75c-.008 6.191-5.004 11.25-11.254 11.246a11.227 11.227 0 0 1-11.234-11.25c0-6.242 5.05-11.238 11.246-11.238 6.207-.004 11.242 5.035 11.242 11.242Zm-9.3 2.465c0-1.387-.005-2.77 0-4.156.003-.524-.184-.97-.571-1.313-.61-.547-1.32-.66-2.082-.39a1.824 1.824 0 0 0-1.207 1.742c.015 2.761.008 5.52.004 8.28 0 1.56 1.336 1.821 1.972 1.821.961-.004 1.907-.777 1.887-1.89-.023-1.364-.004-2.73-.004-4.094Zm.128-8.856a2.06 2.06 0 0 0-2.074-2.07 2.066 2.066 0 0 0-2.074 2.078c0 1.145.933 2.074 2.078 2.074 1.152 0 2.07-.925 2.07-2.082Zm0 0'
        />
      </g>
    </svg>
  )
}

export default VideoUnavailableIcon

export const translation = {
  Download_Application_Documents: `Meine Bewerbungsunterlagen (PDF) 
  zusammenfügen & herunterladen`,
  Share_Profile: 'Profil teilen',
  academicHistory: 'Schulbildung',
  accept: 'Akzeptieren',
  notAccept: 'ablehnen',
  add: 'Hinzufügen',
  confirmPassword: 'Bestätige das Passwort',
  contactNumber: 'Telefon',
  email: 'E-Mail',
  firstName: 'Vorname',
  hobbies: 'Hobbys',
  languages: 'Sprachen',
  lastName: 'Nachname',
  motivationLetter: 'Motivationsschreiben',
  motivationLetterLibrary: 'Meine Motivationsschreiben',
  parents: 'Eltern',
  password: 'Passwort',
  passwordMismatch: 'Passwörter stimmen nicht überein',
  personalInformation: 'Infos über mich',
  siblings: 'Geschwister',
  signIn: 'Anmelden',
  signUp: 'Registrieren',
  strengths: 'Stärken',
  student: 'Schüler',
  teacher: 'Lehrer',
  trialApprenticeship: 'Schnupperlehren',
  weaknesses: 'Schwächen',
  references: 'Referenzen',
  dateOfBirth: 'Geburtsdatum',
  postalCode: 'Postleitzahl',
  enter: 'Eingeben',
  address: 'Adresse',
  place: 'Ort',
  nationality: 'Nationalität',
  father: 'Vater',
  mother: 'Mutter',
  sibling: 'Geschwister',
  designation: 'Funktion',
  update: 'Aktualisieren',
  cancel: 'zurück',
  attachments: 'Anhänge',
  share: 'Aktie',
  back: 'Zurück',
  submit: 'Speichern',
  startingYear: 'Startdatum',
  endingYear: 'Enddatum',
  schoolName: 'Name der Schule',
  job: 'Beruf',
  companyName: 'Name der Firma',
  testerDiary: 'Mein Lehrstell-Journal',
  addDiary: 'Tagebuch hinzufügen',
  exportDiary: 'Journal herunterladen',
  sortBy: 'Sortiere nach',
  contactPerson: 'Kontaktperson',
  contactEmail: 'Kontakt E-Mail',
  date: 'Datum',
  notes: 'Kontaktnummer und Notizen',
  type: 'Typ',
  actions: 'Aktionen',
  sure: 'Sicher',
  from: 'Von',
  to: 'Bis',
  diary: 'Tagebuch',
  title: 'Titel',
  step: 'Schritt',
  class: 'Klasse',
  classes: 'Klassen',
  className: 'Klassenname',
  dashboard: 'Armaturenbrett',
  login: 'Anmeldung',
  close: 'Schließen',
  success: 'Erfolg',
  error: 'Error',
  files: 'Dateien',
  register: 'Registrieren',
  uploadComplete: 'Upload erfolgreich',
  yes: 'Ja',
  NO_LANGUAGES_AVAILABLE: 'Es wurde noch keine Sprache hinzugefügt',
  NO_MOTIVATION_LETTERS_AVAILABLE:
    'Es sind keine Motivationsschreiben vorhanden',
  DELETE_MOTIVATION_LETTERS: 'Möchtest du dieses Motivationsschreiben löschen?',
  DELETE_DIARY: 'Möchtest du diesen Eintrag wirklich löschen?',
  USER_NOT_FOUND: 'Bitte Seite aktualisieren und erneut versuchen',
  INVALID_EMAIL_ADDRESS: 'Gültige E-Mail-Adresse eingeben',
  SECOND_ADMIN_ADDED_SUCCESSFULLY:
    'Zweite Admin-Lehrperson wurde erfolgreich hinzugefügt',
  REQUIRED_FIELD: 'Pflichtfeld (bitte ausfüllen)',
}

import * as React from 'react';

const AddIcon = () => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.492 1.667H6.508c-3.033 0-4.841 1.808-4.841 4.841v6.975c0 3.042 1.808 4.85 4.841 4.85h6.975c3.034 0 4.842-1.808 4.842-4.841V6.508c.008-3.033-1.8-4.841-4.833-4.841ZM15 10.625h-4.375V15a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625v-4.375H5A.63.63 0 0 1 4.375 10 .63.63 0 0 1 5 9.375h4.375V5A.63.63 0 0 1 10 4.375a.63.63 0 0 1 .625.625v4.375H15a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z'
      fill='#458EFF'
    />
  </svg>
);

export default AddIcon;

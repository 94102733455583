export const ApprenticeshipQuestions = [
  {
    label:
      'Wie bist du auf das Stelleninserat aufmerksam geworden und für welche Stelle möchtest du dich bewerben? (Schreibe in ganzen Sätzen).',
    placeholder:
      'Ich habe im Internet gesehen, dass Sie ab August 2022 eine Ausbildung zum Logistiker anbieten. Hiermit bewerbe ich mich für diesen Ausbildungsplatz.  Ich würde mich sehr gerne in Ihrem Unternehmen engagieren und die Ausbildung zum Logistiker EBA absolvieren.',
  },
  {
    label:
      'Was hast du bisher in diesem Beruf gemacht? (Schreibe in ganzen Sätzen).',
    placeholder:
      'Den Beruf des Logistikers habe ich bei einem Schnuppertraining bei der Firma Logistik AG kennengelernt.  Dort wurde mir klar, dass ich mich für eine Ausbildung zum Logistiker bewerben würde, weil mir die Schnuppertage in diesem Beruf sehr viel Spaß gemacht haben.',
  },
  {
    label:
      'Warum passt der Beruf zu dir? Warum möchtest du bei dieser Firma eine Lehrstelle? (Schreibe in ganzen Sätzen).',
    placeholder:
      'Es macht mir großen Spaß, die Ware mit dem Gabelstapler zu kontrollieren und zu transportieren.  Auch der Kontakt mit den Kunden gefällt mir.  Ich arbeite gerne praxisnah, helfe gerne mit und erledige Aufgaben schnell und zielgerichtet.  Als Ausbildungsbetrieb würde ich mir die Firma Logistik AG wünschen, da es sich um ein Familienunternehmen handelt.',
  },
  {
    label:
      'Welche Klasse besuchst du, welches sind deine Lieblingsfächer und Hobbys? (Schreibe in ganzen Sätzen).',
    placeholder: `Zurzeit besuche ich die zweite Sekundarstufe C der Musterschule Luzern.  Meine Lieblingsfächer sind Kunst und Sport.  In meiner Freizeit spiele ich seit zwei Jahren Fußball für den FC Muster.  Für mich sind die Trainingseinheiten und Spiele eine gute Abwechslung zum mentalen Stress des Schulalltags.`,
  },
]

export const TrialApprenticeshipQuestions = [
  {
    label:
      'Was ist der Grund für diese Bewerbung? Hast du evtl. schon ein Telefonanruf an dieses Unternehmen gemacht? Schreibe dies hier hin.',
    placeholder:
      'Für die telefonische Auskunft bedanke ich mich. Gerne bewerbe ich mich hiermit für eine Schnupperlehre als Logistiker EFZ.',
  },
  {
    label:
      'Wo gehst du zur Schule (Name der Schule/Schuljahr/Schulstufe)? Was sind deine Lieblingsfächer und was sind deine Hobbys?',
    placeholder:
      'Zurzeit besuche ich die 2. Sekundarschule Stufe C in Luzern.  Meine Lieblingsfächer sind Kunst und Sport.  In meiner Freizeit spiele ich Fußball für den FC Muster.',
  },
  {
    label: 'Warum möchtest du in diesem Beruf eine Schnupperlehre machen?',
    placeholder:
      'Ich habe die Stellenausschreibung im Internet gelesen und interessiere mich nun sehr für diesen Job.  In der Schnupperlehre möchte ich nun den Arbeitsalltag eines Logistikers näher kennenlernen.',
  },
]

import * as React from 'react';

const CVGeneratorIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 793}
    height={props.height || 793}
    viewBox='0 0 594.96 595.5'
    {...props}
  >
    <defs>
      <clipPath id='a'>
        <path d='M156 118.855h283v356.403H156Zm0 0' />
      </clipPath>
    </defs>
    <path
      fill='#100f0d'
      d='M314.988 173.137h70.309v73.433h-3.945v-13.617c0-.324-.114-7.86-10.59-11.34a124.677 124.677 0 0 1-12.395-4.84v-.132a20.035 20.035 0 0 0 11.492-18.11v-1.07c0-11.094-9-20.078-20.109-20.078-11.11 0-20.11 8.984-20.11 20.078v1.07a20.065 20.065 0 0 0 11.489 18.11v.132a124.237 124.237 0 0 1-12.39 4.84c-10.458 3.48-10.571 11.016-10.571 11.34v13.617h-3.18Zm0 85.136h70.309c6.473 0 11.723-5.238 11.723-11.703v-73.433c0-6.465-5.25-11.703-11.723-11.703h-70.309c-6.476 0-11.722 5.238-11.722 11.703v73.433c0 6.465 5.246 11.703 11.722 11.703'
    />
    <g clipPath='url(#a)'>
      <path
        fill='#100f0d'
        d='M423.91 456.813a3.557 3.557 0 0 1-3.582 3.558H175c-1.973.152-3.7-1.3-3.852-3.25-.02-.117-.02-.21-.02-.308V193.14h44.61c8.617-1.649 14.844-9.16 14.86-17.903v-41.496h189.73a3.556 3.556 0 0 1 3.582 3.555Zm-208.172-312.61v30.73a3.416 3.416 0 0 1-3.332 3.348h-30.8Zm204.762-25.3H223.168a7.424 7.424 0 0 0-5.266 2.14l-59.488 59.398a7.332 7.332 0 0 0-2.148 5.258v271.114c.039 10.136 8.257 18.359 18.425 18.398H420.04c10.23.133 18.617-8.05 18.734-18.262V137.605c.133-10.214-8.066-18.59-18.273-18.703'
      />
    </g>
    <path
      fill='#100f0d'
      d='M273.887 209.355h-78.508v9.41h78.508v-9.41M273.887 230.68h-78.508v9.406h78.508v-9.406M273.887 251.809h-78.508v9.41h78.508v-9.41M397.918 294.36H195.684v11.874h202.234V294.36M397.918 324.059H195.684v11.875h202.234v-11.875M397.918 353.758H195.684v11.875h202.234v-11.875M195.684 395.332H302.75v-11.894H195.684v11.894M350.344 420.516V408.64h-17.852v11.875h17.852M380.066 420.516h17.852V408.64h-17.852v11.875M374.129 420.516V408.64H356.28v11.875h17.848M332.492 444.27h65.426v-11.875h-65.426v11.875'
    />
  </svg>
);

export default CVGeneratorIcon;

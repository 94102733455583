import React from 'react';

const StudentCard = ({ icon, title }) => {
  return (
    <div className='w-[332px] h-[300px] bg-white  rounded-lg shadow-lg text-softBlack cursor-pointer hover:scale-[1.04] transition-all ease-in-out'>
      <div className='h-full w-full flex justify-center items-center flex-col gap-4 '>
        {icon}
        <p className='text-2xl text-center '>{title}</p>
      </div>
    </div>
  );
};

export default StudentCard;

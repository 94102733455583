import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetSkill = (id, skillName) => {
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const { data: userData } = await axios.get(
        BACKEND_URL + API_URL.student.skill + skillName,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id,
          },
        }
      );
      const skill = JSON.parse(userData.data);
      setValue(skill);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id, skillName]);

  return { loading, data: value, refetch: getData };
};

export default useGetSkill;

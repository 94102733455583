import {
  CERTIFICATE,
  INTERLOCKING_TEST,
  WORK_TRIAL,
} from 'utils/documentTypes';

export const documentOptions = [
  { title: INTERLOCKING_TEST },
  { title: CERTIFICATE },
  { title: WORK_TRIAL },
];

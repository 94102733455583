import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const classes = {
  top: 'lg:max-w-[28rem] xl:max-w-[36rem] flex items-center justify-center w-full',
}

const SplitImageLink = ({
  id,
  imageUrl,
  firstLink,
  secondLink,
  splitDirection = 'Horizontal',
  bannerType = 'top',
  containerClasses = '',
}) => {
  const updateBannerClick = async () => {
    await axios.patch(BACKEND_URL + API_URL.banner.updateClicks(id))
  }

  const handleClick = async (e) => {
    // e.preventDefault()
    updateBannerClick()
    // if (splitDirection === 'null' && (firstLink || secondLink)) {
    //   window.open(`https://${firstLink || secondLink}`, '_blank') // Left or top link in a new tab
    // }
    // const rect = e.target.getBoundingClientRect()
    // const x = e.clientX - rect.left // x position within the element.
    // const y = e.clientY - rect.top // y position within the element.
    // const widthHalf = rect.width / 2
    // const heightHalf = rect.height / 2

    // var linkElement = document.createElement('a')
    // linkElement.id = 'link'
    // window.document.body.appendChild(linkElement)

    // var link = document.getElementById('link')

    // if (splitDirection === 'Horizontal') {
    //   if (x < widthHalf) {
    //     if (firstLink) link.setAttribute('href', `https://${firstLink}`)
    //   } else {
    //     if (secondLink) link.setAttribute('href', `https://${secondLink}`)
    //   }
    // } else if (splitDirection === 'Vertical') {
    //   if (y < heightHalf) {
    //     if (firstLink) link.setAttribute('href', `https://${firstLink}`)
    //   } else {
    //     if (secondLink) link.setAttribute('href', `https://${secondLink}`)
    //   }
    // }
    // link.setAttribute('target', `_blank`)

    // link.click()
  }

  return (
    <div className={containerClasses || classes[bannerType]}>
      <div
        className='max-w-[350px] relative z-50'
        onClick={() => handleClick()}
      >
        {splitDirection === 'null' ? (
          <div className='w-full h-full bg-transparent absolute'>
            <a
              href={`https://${firstLink || secondLink}`}
              className='w-full h-full'
              rel='noreferrer'
              target='_blank'
            >
              <div className='w-full h-full'></div>
            </a>
          </div>
        ) : (
          <div
            className={`w-full h-full bg-transparent absolute flex ${
              splitDirection === 'Horizontal' ? 'flex-row' : 'flex-col'
            }`}
          >
            <a
              href={`https://${firstLink}`}
              className={`bg-transparent ${
                splitDirection === 'Horizontal' ? 'w-[50%]' : 'w-full'
              } h-full`}
              rel='noreferrer'
              target='_blank'
            >
              <div className='w-[100%] h-full'></div>
            </a>
            <a
              href={`https://${secondLink}`}
              className={`bg-transparent ${
                splitDirection === 'Horizontal' ? 'w-[50%]' : 'w-full'
              } h-full`}
              rel='noreferrer'
              target='_blank'
            >
              <div className='w-[100%] h-full'></div>
            </a>
          </div>
        )}
        <img
          src={imageUrl}
          alt='banner_image'
          // onClick={handleClick}
          className='w-full h-full object-cover cursor-pointer'
        />
      </div>
    </div>
  )
}

export default SplitImageLink

import { Box } from '@mui/material'
import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { ROUTES } from 'utils/routes'
import empty from 'assets/images/empty.png'
import Button from './Button'
import { jobCategories } from 'utils'

const JobListingCard = ({ label = '', data = [], userId }) => {
  const [params, setParams] = useSearchParams()

  return (
    <Box className='bg-white p-4 rounded-2xl h-[345px] shadow-lg overflow-hidden relative'>
      <div
        onClick={() => {
          setParams({
            category: null,
            type: label,
          })
        }}
      >
        <p className='text-xl font-semibold mb-4 cursor-pointer'>{label}</p>
      </div>
      <div
        className={`flex flex-col gap-2 ${
          !data?.jobs?.length && 'items-center justify-center pt-12'
        }`}
      >
        {data?.jobs?.length ? (
          data?.jobs?.map(
            (item, index) =>
              index < 7 && (
                <Link
                  key={index}
                  to={
                    ROUTES({
                      userId,
                      jobCategory: label,
                      jobTitle: item.title,
                    }).student.job.jobByTitle
                  }
                >
                  <p className='list-item list-inside list-disc truncate hover:underline hover:scale-[1.01] underline-offset-1'>
                    {item.title}
                  </p>
                </Link>
              )
          )
        ) : (
          <>
            <img src={empty} alt='empty' className='w-20' />
            <h1>leer</h1>
          </>
        )}
      </div>
      <Link
        to={
          ROUTES({ userId, jobType: label, jobCategory: jobCategories[0] })
            .student.job.categoryOrTypeJobs
        }
      >
        <Button
          title={`Alle ${data?.count || 0} Berufe anzeigen`}
          disabled={!data?.jobs?.length}
          titleClasses='text-white text-sm font-semibold text-left group-hover:underline'
          containerClasses='rounded-md w-[89%] h-[40px] bg-blue-200 px-2 absolute bottom-2 group'
        />
      </Link>
    </Box>
  )
}

export default JobListingCard

import Button from 'components/Button';
import React from 'react';

const DeleteDocumentScreen = ({
  setConfirmDeleteDocument,
  value,
  setValue,
}) => {
  return (
    <>
      <h1 className='text-center font-medium'>
        Do you really want to delete this file?
      </h1>
      <div className='flex gap-6 justify-center items-center mt-4 '>
        <Button
          title='Cancel'
          titleClasses='text-[#458EFF] text-lg font-semibold'
          containerClasses='rounded-md w-full sm:w-[130px] h-[46px] bg-[#E9F1FC]'
          onClick={(e) => {
            e.preventDefault();
            setConfirmDeleteDocument(false);
          }}
        />
        <Button
          title='Confirm'
          onClick={() => {
            setValue({ ...value, uploadedDocument: null });
            setConfirmDeleteDocument(false);
          }}
          titleClasses='text-white text-sm font-semibold'
          containerClasses='rounded-md w-full sm:w-[130px] h-[46px] bg-[#458EFF]'
        />
      </div>
    </>
  );
};

export default DeleteDocumentScreen;

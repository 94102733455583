import React, { useState } from 'react'
import Navbar from 'components/Navbar/Navbar'
import PDFIcon from 'assets/icons/pdf'
import VideoAvailableIcon from 'assets/icons/videoAvailable'
import CustomVideoPlayerModal from 'components/CustomVideoPlayerModal'
import WordIcon from 'assets/icons/word'
import PowerPointIcon from 'assets/icons/powerpoint'

const Anleitungen = () => {
  const [selectedVideo, setSelectedVideo] = useState('')

  const documents = [
    {
      title: 'Anleitung zum Erstellen des SchülerInnen-Profils',
      pdf: require(`../../assets/documents/Footer/Student/student-info.pdf`),
    },
  ]

  const teacherDocuments = [
    {
      title: 'Anleitung zum Erstellen der Klasse_hinzufügen von SuS',
      pdf: require(`../../assets/documents/Footer/Teacher/Anleitung zum Erstellen der Klasse_hinzufügen von SuS.pdf`),
    },
    {
      title: 'Anleitung zum Erstellen des SchülerInnen-Profils',
      pdf: require(`../../assets/documents/Footer/Teacher/Anleitung zum Erstellen des SchülerInnen-Profils.pdf`),
    },
    {
      title: 'Einwilligungserklärung_lehrstell.ch',
      pdf: require(`../../assets/documents/Footer/Teacher/Einwilligungserklärung_lehrstell.ch.pdf`),
    },
  ]

  const videos = [
    {
      title: '0. Schülerinnen-Video_Perfekte Bildschirmansicht einstellen',
      link: require(`../../assets/videos/Footer/Student/0. Schülerinnen-Video_Perfekte Bildschirmansicht einstellen.mp4`),
    },
    {
      title: '1. Schülerinnen-Video_Profil erstellen_Registrierung',
      link: require(`../../assets/videos/Footer/Student/1_Schülerinnen-Video_Profil erstellen_Registrierung.mp4`),
    },
    {
      title: '2. Schülerinnen-Video_Infos mich ergänzen',
      link: require(`../../assets/videos/Footer/Student/2_Schülerinnen-Video_Infos mich ergänzen.mp4`),
    },
    {
      title: '3. Schülerinnen-Video_Profil ergänzen',
      link: require(`../../assets/videos/Footer/Student/3_Schülerinnen-Video_Profil ergänzen.mp4`),
    },
    {
      title: '4. Schülerinnen-Video_Mein Lehrstell-Journal',
      link: require(`../../assets/videos/Footer/Student/4_Schülerinnen-Video_Mein Lehrstell-Journal.mp4`),
    },
    {
      title: '5. Schülerinnen-Video_Motivationsschreiben erstellen',
      link: require(`../../assets/videos/Footer/Student/Schülerinnen-Video_Motivationsschreiben erstellen.mp4`),
    },
    {
      title:
        '6. Schülerinnen-Video_ Dokumente ins Profil hochladen (z.B. Zeugnisse)',
      link: require(`../../assets/videos/Footer/Student/6_Schülerinnen-Video_ Dokumente ins Profil hochladen (z.B. Zeugnisse).mp4`),
    },
    {
      title: '7. Schülerinnen-Video_Profil teilen',
      link: require(`../../assets/videos/Footer/Student/7_Schülerinnen-Video_Profil teilen.mp4`),
    },
    {
      title: '8. Schülerinnen-Video_sich über Berufe informieren',
      link: require(`../../assets/videos/Footer/Student/8_Schülerinnen-Video_sich über Berufe informieren.mp4`),
    },
  ]

  const teacherVideos = [
    {
      title: 'Anleitung für Lehrpersonen Erstellen der Klasse',
      link: require(`../../assets/videos/Footer/Teacher/Anleitung für Lehrpersonen_Erstellen der Klasse.mp4`),
    },
  ]

  const openPdfInNewTab = (pdf) => {
    // Use the imported PDF as the URL
    window.open(pdf, '_blank')
  }

  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen p-3 sm:p-6 w-full flex gap-4 items-center justify-center text-softBlack'>
        <>
          <div className='w-full font-bold text-2xl text-softBlack underline'>
            ANLEITUNGEN FÜR SCHÜLER*INNEN
          </div>
          <div className='grid grid-cols-none sm:grid-cols-2 w-full'>
            <div className='w-full'>
              <div className='font-bold text-xl underline mb-2'>
                Anleitungen
              </div>
              <div className='bg-white h-full flex flex-col gap-4 py-3 px-2'>
                {documents.map(({ title, pdf }) => (
                  <button
                    className='flex items-center gap-4'
                    onClick={() => openPdfInNewTab(pdf)}
                  >
                    <PDFIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    <p className='text-left'>{title}</p>
                  </button>
                ))}
              </div>
            </div>
            <div className='w-full'>
              <div className='font-bold text-xl underline mb-2'>
                Anleitungsvideos
              </div>
              <div className='bg-white h-full flex flex-col gap-4 py-3 px-2'>
                {videos.map(({ title, link }) => (
                  <button
                    className='flex items-center gap-2 sm:gap-4'
                    onClick={() => setSelectedVideo(link)}
                  >
                    <VideoAvailableIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    <p className='text-left'>{title}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
        <>
          <div className='w-full font-bold text-2xl text-softBlack underline mt-8'>
            ANLEITUNGEN FÜR LEHRPERSONEN
          </div>
          <div className='grid grid-cols-none sm:grid-cols-2 w-full'>
            <div className='w-full'>
              <div className='font-bold text-xl underline mb-2'>
                Anleitungen
              </div>
              <div className='bg-white h-full flex flex-col gap-4 py-3 px-2'>
                {teacherDocuments.map(({ title, pdf }) => (
                  <button
                    className='flex items-center gap-4'
                    onClick={() => openPdfInNewTab(pdf)}
                  >
                    <PDFIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    <p className='text-left'>{title}</p>
                  </button>
                ))}
              </div>
            </div>
            <div className='w-full'>
              <div className='font-bold text-xl underline mb-2'>
                Anleitungsvideos
              </div>
              <div className='bg-white h-full flex flex-col gap-4 py-3 px-2'>
                {teacherVideos.map(({ title, link }) => (
                  <button
                    className='flex items-center gap-2 sm:gap-4'
                    onClick={() => setSelectedVideo(link)}
                  >
                    <VideoAvailableIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    <p className='text-left'>{title}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
        <div className='my-8 w-full'>
          <div className='w-full font-bold text-2xl text-softBlack underline'>
            UNTERRICHTSDOKUMENTE
          </div>
          <div className='grid grid-cols-none sm:grid-cols-2 w-full'>
            <div className='w-full mt-2'>
              <div className='bg-white h-full flex flex-col gap-4 py-3 px-2'>
                {[
                  {
                    title: '0_Berufswahl KOMPAKT_Dossier lehrstell.ch',
                    pdf: require(`../../assets/documents/Footer/Other/0_Berufswahl KOMPAKT_Dossier lehrstell.ch.pdf`),
                  },
                  {
                    title: '1_Einwilligungserklärung_lehrstell.ch',
                    pdf: require(`../../assets/documents/Footer/Other/Einwilligungserklärung_lehrstell.ch.pdf`),
                  },
                  {
                    title:
                      '2_Visualisierungsmöglichkeit eines Berufswahlbarometers',
                    pdf: require(`../../assets/documents/Footer/Other/Visualisierungsmöglichkeit eines Berufswahlbarometers.pdf`),
                  },
                  {
                    title:
                      '2_Visualisierungsmöglichkeit eines Berufswahlbarometers',
                    pdf: require(`../../assets/documents/Footer/Other/Visualisierungsmöglichkeit eines Berufswahlbarometers.docx`),
                    icon: (
                      <WordIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    ),
                  },
                  {
                    title: '4_Übersicht der Weg zur Lehrstelle',
                    pdf: require(`../../assets/documents/Footer/Other/1_Einstieg_Übersicht Berufswahl_der Weg zur Lehrstelle.pdf`),
                  },
                  {
                    title: '4_Übersicht der Weg zur Lehrstelle',
                    pdf: require(`../../assets/documents/Footer/Other/1_Übersicht_der Weg zur Lehrstelle.pptx`),
                    icon: (
                      <PowerPointIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    ),
                  },
                  {
                    title: '5_Powerpoint-Präsentation einen Lehrberuf vorstellen',
                    pdf: require(`../../assets/documents/Footer/Other/3_Auftrag_Powerpoint-Präsentation_einen Lehrberuf vorstellen.pdf`),
                  },
                  {
                    title: '5_Powerpoint-Präsentation einen Lehrberuf vorstellen',
                    pdf: require(`../../assets/documents/Footer/Other/3_Vorlage_Powerpoint-Präsentation_einen Lehrberuf vorstellen.pptx`),
                    icon: (
                      <PowerPointIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    ),
                  },
                  {
                    title: '6_Schnupperlehren vorbereiten',
                    pdf: require(`../../assets/documents/Footer/Other/4_Auftrag_Schnupperlehren vorbereiten.pdf`),
                  },
                  {
                    title: '7_Anfragetelefonat Schnupperlehre Deutsch',
                    pdf: require(`../../assets/documents/Footer/Other/5_Anfragetelefonat_Schnupperlehre_Deutsch.pdf`),
                  },
                  {
                    title: '8_Sich für eine Lehrstelle bewerben',
                    pdf: require(`../../assets/documents/Footer/Other/6_Sich für eine Lehrstelle bewerben.pdf`),
                  },
                  {
                    title: '9_Vorbereitung für ein Vorstellungsgespräch',
                    pdf: require(`../../assets/documents/Footer/Other/7_Vorbereitung für ein Vorstellungsgespräch.pdf`),
                  },
                  {
                    title: '10_Lehrstell.ch Infoflyer Lehrpersonen',
                    pdf: require(`../../assets/documents/Footer/Other/Lehrstell.ch_Infoflyer Lehrpersonen.pdf`),
                  },
                ].map(({ title, pdf, icon }) => (
                  <button
                    className='flex items-center gap-4'
                    onClick={() => openPdfInNewTab(pdf)}
                  >
                    {icon ? (
                      icon
                    ) : (
                      <PDFIcon className='min-w-[30px] w-[30px] sm:w-[40px]' />
                    )}
                    <p className='text-left'>{title}</p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomVideoPlayerModal
        videoURL={selectedVideo}
        open={selectedVideo}
        handleClose={() => setSelectedVideo('')}
      />
    </>
  )
}

export default Anleitungen

import Custom404Error from 'components/Custom404Error';
import React from 'react';
import { useSelector } from 'react-redux';
import { user } from 'store/reducers/user';

const VerifyAdmin = ({ children }) => {
  const loggedInUser = useSelector(user)

  const userRole = loggedInUser?.role?.toLowerCase();

  return <>{userRole === 'admin' ? children : <Custom404Error />}</>;
};

export default VerifyAdmin;

import NoAccess from 'components/NoAccess';
import VerifyEditAccess from 'components/VerifyEditAccess';
import React from 'react';
import VerifiedEditAcademicHistory from './VerifiedEditAcademicHistory';

const EditAcademicHistory = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate) =>
        canEdit ? (
          <VerifiedEditAcademicHistory id={id} navigate={navigate} />
        ) : (
          <NoAccess />
        )
      }
    </VerifyEditAccess>
  );
};

export default EditAcademicHistory;

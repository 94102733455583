import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import CalenderIcon from 'assets/icons/calender'
import { setDefaultOptions } from 'date-fns'
import { de } from 'date-fns/locale'

setDefaultOptions({ locale: de })

const CustomDatePicker = ({
  label,
  containerClasses,
  labelClasses,
  value,
  handleChange,
  error,
  inputBackgroundColor = 'transparent',
  helperText,
  placeholder = 'Tag/Monat/Jahr',
  ...props
}) => {
  return (
    <div className={containerClasses}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputLabel shrink htmlFor='date-input' className={labelClasses}>
          {label}
        </InputLabel>

        <DesktopDatePicker
          value={value}
          components={{
            OpenPickerIcon: CalenderIcon,
          }}
          minDate='1970-01-01'
          onChange={(value, keyboardInputValue, ...props) =>
            handleChange(value, keyboardInputValue, ...props)
          }
          className='w-full'
          dayOfWeekFormatter={(day) => `${day}`}
          inputProps={{ placeholder }}
          renderInput={(params) => {
            return (
              <TextField
                sx={{
                  [`& .MuiInputBase-root`]: {
                    backgroundColor: inputBackgroundColor,
                    height: 45,
                  },
                }}
                helperText={helperText}
                {...params}
                error={error}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 0,
                  },
                }}
              />
            )
          }}
          {...props}
        />
      </LocalizationProvider>
    </div>
  )
}

export default CustomDatePicker

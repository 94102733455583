import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import moment from 'moment'

import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import CustomSelect from 'components/CustomSelect'
import { translation } from 'utils/germanTranslation'

const SUBMIT_MODAL = 'user checks if he is 14 years old'
const UNDERSTAND_MODAL = 'user understands that he is 14 years old'

const UserBirthdaySection = forwardRef(
  ({ dateOfBirth, handleUserDateOfBirthChange, registerUser }, ref) => {
    const startingYear = 2000
    const currentYear = moment().year()
    const yearsArray = Array.from(
      { length: currentYear - startingYear + 1 },
      (_, index) => startingYear + index
    )

    const getDaysInMonthOfAYear = useCallback(
      () =>
        Array.from(
          {
            length: moment(
              `${dateOfBirth.year}-${moment()
                .month(dateOfBirth.month)
                .format('M')}`,
              'YYYY-M'
            ).daysInMonth(),
          },
          (_, index) => index + 1
        ),
      [dateOfBirth]
    )

    const totalDaysInMonth = useMemo(
      () => getDaysInMonthOfAYear(),
      [getDaysInMonthOfAYear]
    )

    const [showModal, setShowModal] = useState({
      show: false,
      type: UNDERSTAND_MODAL,
    })

    const showSubmitModal = () =>
      setShowModal({ show: true, type: SUBMIT_MODAL })

    const showUnderstandModal = () =>
      setShowModal({ show: true, type: UNDERSTAND_MODAL })

    useImperativeHandle(ref, () => ({
      showSubmitModal,
      showUnderstandModal,
    }))

    const closeModal = () => setShowModal({ show: false, type: SUBMIT_MODAL })

    return (
      <>
        <div>
          <h1 className='text-sm mb-2'>Geburtsdatum</h1>
          <div className='grid grid-cols-3 gap-2'>
            <CustomSelect
              options={totalDaysInMonth}
              value={dateOfBirth.date}
              handleChange={(value) =>
                handleUserDateOfBirthChange(value, 'date')
              }
            />
            <CustomSelect
              options={moment.months()}
              value={dateOfBirth.month}
              handleChange={(value) =>
                handleUserDateOfBirthChange(value, 'month')
              }
            />
            <CustomSelect
              options={yearsArray}
              value={dateOfBirth.year}
              handleChange={(value) =>
                handleUserDateOfBirthChange(value, 'year')
              }
            />
          </div>
          <CustomModal
            open={showModal.show}
            handleClose={() =>
              setShowModal({ show: false, type: SUBMIT_MODAL })
            }
          >
            {showModal.type === SUBMIT_MODAL ? (
              <>
                <h1 className='text-center'>
                  Hast du das{' '}
                  <strong>
                    Einverständnis deiner Eltern oder Erziehungsberechtigten
                  </strong>{' '}
                  um ein Bewerbungs-Profil auf lehrstell.ch zu erstellen und dich
                  damit zu bewerben?
                </h1>
                <div className='flex justify-center gap-4 mt-4'>
                  <Button
                    title={translation.yes}
                    onClick={() => {
                      closeModal()
                      registerUser()
                    }}
                    containerClasses='px-8 py-2 rounded-lg text-white'
                    backgroundColor='#458EFF'
                  />
                  <Button
                    title='nein'
                    onClick={showUnderstandModal}
                    containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
                    backgroundColor='#E9F1FC'
                  />
                </div>
              </>
            ) : (
              <>
                <h1 className='text-center'>
                  ...ohne Einverständnis deiner Eltern/Erziehungsberechtigten
                  kannst du mit unter 14 Jahren leider kein Bewerbungs-Profil
                  auf lehrstell.ch erstellen. Wenn du das Einverständnis deiner
                  Eltern/Erziehungsberechtigten hast, kannst du ein
                  Bewerbungs-Profil eröffnen.
                </h1>
                <div className='flex justify-center gap-4 mt-4'>
                  <Button
                    title='verstanden'
                    onClick={closeModal}
                    containerClasses='px-8 py-2 rounded-lg text-white'
                    backgroundColor='#458EFF'
                  />
                </div>
              </>
            )}
          </CustomModal>
        </div>
      </>
    )
  }
)

export default UserBirthdaySection

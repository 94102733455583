import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetClassesCount = () => {
  const [value, setValue] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data: userData } = await axios.get(
        BACKEND_URL + API_URL.count.classes,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setValue(userData?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return { loading, data: value, refetch: getData, error };
};

export default useGetClassesCount;

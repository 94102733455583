import { useEffect, useState } from 'react'
import { Box, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'

import useGetClasses from 'hooks/useGetClasses'
import TrashIcon from 'assets/icons/trash'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'
import CustomTable from 'components/Table'
import SortableTableCell from 'components/SortableTableCell'
import NothingToShow from 'components/Student/NothingToShow'
import moment from 'moment'

const NAME = 'name'
const CREATION_TIME = 'createdAt'
const ZIP = 'schoolZipCode'

const initialSortOrder = {
  [NAME]: { order: null, active: false },
  [CREATION_TIME]: { order: null, active: false },
  [ZIP]: { order: null, active: false },
}

const AdminClasses = ({ userId }) => {
  const [classes, setClasses] = useState([])
  const [modalConfig, setModalConfig] = useState({
    showRenameModal: false,
    showDeleteModal: false,
    classId: '',
    className: '',
    newClassName: '',
  })
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState(initialSortOrder)

  const { data, refetch } = useGetClasses(userId, null, 'ADMIN')

  const deleteClass = async (id) => {
    await axios.post(BACKEND_URL + API_URL.classes.deleteById + id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    refetch()
  }

  const handleCloseModal = () => {
    setModalConfig({
      showRenameModal: false,
      showDeleteModal: false,
      classId: '',
      className: '',
      newClassName: '',
    })
  }

  useEffect(() => setClasses(data), [data])

  const handleSort = (key, value) => {
    if (value.length) {
      const newSortOrder = {
        ...initialSortOrder,
        [key]: {
          order: sortOrder[key].order === 'asc' ? 'desc' : 'asc',
          active: true,
        },
      }
      setSortOrder(newSortOrder)
      setSortBy(key)

      let sortedData = [...value.filter((job) => Boolean(job[key]))]
      sortedData.sort((a, b) => {
        var nameA = a[key]?.toLowerCase(),
          nameB = b[key]?.toLowerCase()
        if (nameA < nameB)
          if (newSortOrder[key].order === 'asc') {
            return -1
          } else {
            return 1
          }
        if (nameA > nameB) {
          if (newSortOrder[key].order === 'asc') {
            return 1
          } else {
            return -1
          }
        }
        return 0
      })

      setClasses([...sortedData, ...value.filter((job) => !Boolean(job[key]))])
    }
  }

  const handleSortHover = (key) => {
    const newSortOrder = {
      ...sortOrder,
      [key]: {
        order: sortOrder[key].order,
        active: !sortOrder[key].active,
      },
    }
    setSortOrder(newSortOrder)
  }

  return (
    <>
      <div className='w-full h-full bg-softWhite'>
        <Box className='max-w-[1760px] min-h-screen px-4 pt-10 sm:px-8 w-full gap-4'>
          <div className='flex items-center justify-between pb-9'>
            <h3 className='text-2xl font-bold'>
              {translation.classes}{' '}
              <span className='opacity-40'>({classes?.length})</span>
            </h3>
          </div>

          {classes?.length > 0 ? (
            <>
              <CustomTable
                data={classes}
                headerTemplate={
                  <TableHead className='bg-[#F0F2F9]'>
                    <TableRow>
                      <SortableTableCell
                        title={translation.className}
                        onHover={() => handleSortHover(NAME)}
                        onHoverLeave={() => handleSortHover(NAME)}
                        performSorting={() => {
                          handleSort(NAME, classes)
                        }}
                        isActive={sortOrder[NAME].active}
                        order={sortOrder[NAME].order}
                      />
                      <SortableTableCell
                        title={'Erstellungsdatum'}
                        onHover={() => handleSortHover(CREATION_TIME)}
                        onHoverLeave={() => handleSortHover(CREATION_TIME)}
                        performSorting={() => {
                          handleSort(CREATION_TIME, classes)
                        }}
                        isActive={sortOrder[CREATION_TIME].active}
                        order={sortOrder[CREATION_TIME].order}
                      />
                      <SortableTableCell
                        title={'plz'}
                        onHover={() => handleSortHover(ZIP)}
                        onHoverLeave={() => handleSortHover(ZIP)}
                        performSorting={() => {
                          handleSort(ZIP, classes)
                        }}
                        isActive={sortOrder[ZIP].active}
                        order={sortOrder[ZIP].order}
                      />
                      <TableCell style={{ width: '10rem' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                }
                rowTemplate={(row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{row.schoolZipCode}</TableCell>
                    <TableCell className='py-4'>
                      <div className='flex gap-2'>
                        <button
                          onClick={() => {
                            setModalConfig({
                              showRenameModal: false,
                              showDeleteModal: true,
                              classId: row?.id,
                              className: row?.name,
                              newClassName: row?.name,
                            })
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              />
            </>
          ) : (
            <NothingToShow />
          )}
        </Box>

        <CustomModal
          open={modalConfig.showDeleteModal}
          handleClose={handleCloseModal}
          containerClasses='w-[90%] sm:w-auto'
        >
          <div>
            <p className='text-lg sm:text-xl font-semibold text-center'>
              Willst du diese Klasse wirklich löschen?{' '}
              <span className='text-blue-600 font-bold'>
                ({modalConfig?.className})
              </span>
              ?
            </p>
            <div className='flex justify-center gap-4 mt-4'>
              <Button
                title={translation.sure}
                onClick={() => {
                  deleteClass(modalConfig.classId)
                  handleCloseModal()
                }}
                containerClasses='px-8 py-2 rounded-lg text-white'
                backgroundColor='#458EFF'
              />
              <Button
                title={translation.cancel}
                onClick={handleCloseModal}
                containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
                backgroundColor='#E9F1FC'
              />
            </div>
          </div>
        </CustomModal>
      </div>
    </>
  )
}

export default AdminClasses

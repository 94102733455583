export const strengths = [
  { title: 'freundlich' },
  { title: 'hilfsbereit' },
  { title: 'humorvoll' },
  { title: 'sportlich' },
  { title: 'ehrgeizig' },
  { title: 'einfühlsam' },
  { title: 'Teamplayer' },
  { title: 'zuverlässig' },
  { title: 'fleissig' },
  { title: 'empathisch' },
  { title: 'selbständig' },
  { title: 'selbstkritisch' },
  { title: 'Organisationstalent' },
  { title: 'Risikobereitschaft' },
]
export const weaknesses = [
  { title: 'zu ehrgeizig' },
  { title: 'ruhig' },
  { title: 'scheu' },
  { title: 'unkreativ' },
  { title: 'manchmal chaotisch' },
  { title: 'verträumt' },
  { title: 'nicht sehr sportlich' },
  { title: 'sehr direkt' },
  { title: 'nicht humorvoll' },
  { title: 'unselbständig' },
  { title: 'schüchtern' },
  { title: 'leicht ablenkbar' },
  { title: 'wenig Selbstvertrauen' },
]

import React from 'react';

const UpdateRank = ({
  index,
  value = [],
  setValue,
  reverse = false,
  disableDown = null,
  onUpClick = () => {
    const temp = [...value];
    const tempItem = temp[index];
    let iterator = !reverse ? index - 1 : index + 1;
    temp[index] = temp[iterator];
    temp[iterator] = tempItem;

    setValue(temp);
  },
  onDownClick = () => {
    const temp = [...value];
    const tempItem = temp[index];
    let iterator = !reverse ? index + 1 : index - 1;
    temp[index] = temp[iterator];
    temp[iterator] = tempItem;

    setValue(temp);
  },
}) => {
  return (
    <div className='flex flex-col items-center justify-center select-none'>
      <span
        onClick={onUpClick}
        className={`cursor-pointer ${
          reverse && index === value.length - 1
            ? 'opacity-20 pointer-events-none'
            : !reverse && index === 0
            ? 'opacity-20 pointer-events-none'
            : ''
        }`}
      >
        🔼
      </span>
      <span
        onClick={onDownClick}
        className={`cursor-pointer ${
          disableDown !== null
            ? disableDown
              ? 'opacity-20 pointer-events-none'
              : ''
            : reverse && index === 0
            ? 'opacity-20 pointer-events-none'
            : !reverse && index === value.length - 1
            ? 'opacity-20 pointer-events-none'
            : ''
        }`}
      >
        🔽
      </span>
    </div>
  );
};

export default UpdateRank;

import _ from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const useGetBack = (value) => {
  const initialValueRef = useRef([])
  const navigate = useNavigate()

  const onBackButtonEvent = useCallback(
    (event) => {
      event.preventDefault()
      // console.log('value', value)
      // console.log('initialValue', initialValueRef.current)
      // console.log('isEqual', _.isEqual(initialValueRef.current, value))

      if (!_.isEqual(initialValueRef.current, value)) {
        if (window.confirm('Do you want to go back ?')) {
          navigate(-1)
        } else {
          window.history.pushState(null, null, window.location.pathname)
        }
      } else {
        navigate(-1)
      }
    },
    [value]
  )

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [value])

  useEffect(() => {
    if (initialValueRef.current.length === 0) {
      initialValueRef.current = value
    }
  }, [value])

  return onBackButtonEvent
}

export default useGetBack

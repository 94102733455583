import React, { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import SettingsIcon from '@mui/icons-material/Settings'
import { Delete, Logout } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import userImage from 'assets/images/blank_user.png'
import { BACKEND_URL } from 'utils/apiConstants'
import { Link } from 'react-router-dom'
import { ROUTES } from 'utils/routes'

const NavbarProfile = ({
  containerClasses,
  user,
  handleLogout,
  handleDeleteAccount,
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleClose = () => setOpen(false)

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  return (
    <div className={`items-center gap-1 sm:gap-3 flex ${containerClasses}`}>
      <div className='w-12 shrink-0'>
        <img
          className='w-[43px] h-[43px] rounded-full object-cover'
          src={user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage}
          alt='user'
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className='flex flex-col '>
        <p className='font-bold text-sm md:text-base lg:text-lg text-black w-[7rem] xs:w-auto truncate'>
          {user?.firstName} {user?.lastName}
        </p>
        <p className='font-medium text-sm text-[#555555] w-[7rem] xs:w-auto truncate'>
          {user?.email}
        </p>
      </div>
      <Button
        ref={anchorRef}
        id='composition-button'
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        size='small'
        color='inherit'
        style={{ minWidth: 0, color: 'black' }}
        className='p-0 md:p-1'
      >
        <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        className='text-black'
        anchorEl={anchorRef.current}
        placement='bottom-start'
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                  className='text-black'
                >
                  {(String(user.role).toLowerCase() === 'teacher' ||
                    String(user.role).toLowerCase() === 'student') && (
                    <Link to={ROUTES({ userId: user?.id }).teacher.settings}>
                      <MenuItem>
                        <SettingsIcon className='mr-2' />
                        <p className='text-sm sm:text-base'>
                          Konto-Einstellungen
                        </p>
                      </MenuItem>
                    </Link>
                  )}
                  <MenuItem onClick={handleDeleteAccount}>
                    <Delete className='mr-2' />
                    <p className='text-sm sm:text-base'>Konto löschen</p>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Logout className='mr-2' />
                    <p className='text-sm sm:text-base'>Ausloggen</p>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default NavbarProfile

import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import axios from 'axios'

import Button from 'components/Button'
import CustomInput from 'components/Input'
import UploadFiles from 'components/Upload'
import { ROUTES } from 'utils/routes'
import { ShowToast } from 'utils/toastMessages'
import { translation } from 'utils/germanTranslation'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import {
  CERTIFICATE,
  INTERLOCKING_TEST,
  WORK_TRIAL,
  CV,
  MOTIVATION_LETTER,
} from 'utils/documentTypes'

const DOCUMENTS_TO_SHARE = [
  CV,
  MOTIVATION_LETTER,
  CERTIFICATE,
  INTERLOCKING_TEST,
  WORK_TRIAL,
]

const ShareProfileScreen = ({
  value,
  setValue,
  onSubmit,
  setSelectLetter,
  user,
  setConfirmDeleteDocument,
}) => {
  const navigate = useNavigate()
  const toastId = useRef(null)

  const handleChange = (type) => {
    value.documentsAttached.includes(type)
      ? setValue({
          ...value,
          documentsAttached: value.documentsAttached.filter(
            (item) => item !== type
          ),
        })
      : setValue({
          ...value,
          documentsAttached: [...value.documentsAttached, type],
        })
  }

  const handleDocumentUpload = (file, index) => {
    const formData = new FormData()
    formData.append('document', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${user?.token}`,
        id: user?.id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 0,
          })
          toastId.current = null
        }
      },
    }
    axios
      .post(BACKEND_URL + API_URL.documents.add, formData, config)
      .then(({ data }) => {
        setValue({
          ...value,
          uploadedDocument: data?.data,
        })
      })
      .catch((error) => {
        ShowToast({ error })
      })
  }

  const handleDocumentTypeClick = (type) => {
    if (type === CV) {
      setValue({
        ...value,
        isCVAttached: !value.isCVAttached,
      })
    } else if (type === MOTIVATION_LETTER) {
      if (value.isMotivationLetterAttached) {
        setValue({
          ...value,
          isMotivationLetterAttached: '',
        })
      } else setSelectLetter((prev) => !prev)
    } else if (
      type === CERTIFICATE ||
      type === INTERLOCKING_TEST ||
      type === WORK_TRIAL
    )
      handleChange(type)
  }

  const isDocumentTypeSelected = (type) => {
    if (type === CV) {
      return Boolean(value.isCVAttached)
    } else if (type === MOTIVATION_LETTER) {
      return Boolean(value.isMotivationLetterAttached)
    } else if (
      type === CERTIFICATE ||
      type === INTERLOCKING_TEST ||
      type === WORK_TRIAL
    ) {
      return value.documentsAttached.includes(type)
    }
  }

  return (
    <div className='p-4'>
      <h1 className='text-base xs:text-lg font-bold mb-3'>
        Gib die E-Mail Adresse ein, die Zugriff auf dein Profil haben soll:
      </h1>
      <form onSubmit={onSubmit} className='flex gap-3 '>
        <CustomInput
          value={value.toShareEmail}
          type='email'
          handleChange={(newValue) =>
            setValue({
              ...value,
              toShareEmail: newValue,
            })
          }
        />
        <Button
          title={'Teilen'}
          type='submit'
          disabled={value.loading || !value.toShareEmail.trim().length}
          containerClasses='bg-[#458EFF] text-white px-4 py-2 rounded-lg h-full'
        />
      </form>
      <div className='w-full'>
        <Button
          title='Hier kannst du den Standart-E-Mail-Text anpassen'
          titleClasses='text-sm'
          onClick={() =>
            setValue({
              ...value,
              showChangeEmailTextModal: true,
            })
          }
          containerClasses='bg-blue-200 px-4 py-2 rounded-lg mt-3 w-full sm:w-auto'
        />
      </div>
      <div className='mt-3'>
        <p className='font-medium'>{translation.attachments}</p>
        <FormGroup>
          {DOCUMENTS_TO_SHARE.map((item) => (
            <div
              className='flex flex-col sm:flex-row gap-2 items-start sm:items-center'
              key={item}
            >
              <FormControlLabel
                label={
                  item === INTERLOCKING_TEST
                    ? `${INTERLOCKING_TEST} (z.B. Stellwerk)`
                    : item
                }
                sx={{
                  '.MuiFormControlLabel-label:first-letter': {
                    textTransform: 'capitalize',
                  },
                }}
                control={
                  <Checkbox
                    checked={isDocumentTypeSelected(item)}
                    onChange={() => handleDocumentTypeClick(item)}
                  />
                }
              />
              {item === MOTIVATION_LETTER && (
                <>
                  <Button
                    title='Motivationsschreiben auswählen'
                    titleClasses='text-sm'
                    containerClasses='bg-blue-200 px-2 py-2 rounded-lg w-full sm:w-auto'
                    onClick={() => {
                      setSelectLetter((prev) => !prev)
                    }}
                  />
                  <Button
                    title='Neues Motivationsschreiben erstellen'
                    titleClasses='text-sm'
                    containerClasses='bg-blue-200 px-2 py-2 rounded-lg w-full sm:w-auto'
                    onClick={() => {
                      navigate(
                        ROUTES({ userId: user.id }).student.edit
                          .motivationLetter
                      )
                    }}
                  />
                </>
              )}
            </div>
          ))}
          <UploadFiles
            file={value.uploadedDocument}
            handleFileUpload={handleDocumentUpload}
            onDeleteFile={() => setConfirmDeleteDocument(true)}
            label='Weitere Dokumente vom Computer'
            types={['pdf']}
          />
        </FormGroup>
      </div>
    </div>
  )
}

export default ShareProfileScreen

import { Checkbox, FormControlLabel, IconButton } from '@mui/material'
import AddIcon from 'assets/icons/add'
import DeleteIcon from 'assets/icons/delete'
import CustomInput from 'components/Input'
import React from 'react'
import { TrialApprenticeship, Apprenticeship } from './Step1'

export const attachmentType = {
  [TrialApprenticeship]: ['Lebenslauf', 'Zeugnisse'],
  [Apprenticeship]: [
    'Lebenslauf',
    'Stellwerktest',
    'Schnupperberichte',
    'Zeugnisse',
  ],
}

const Attachments = ({
  value,
  handleChange,
  otherAttachments,
  setOtherAttachments,
}) => {
  return (
    <div>
      <h1 className='font-bold'>Beilagen anklicken:</h1>
      <h3>
        (Nach neustem BIZ-Standard werden die Beilagen im Motivationsschreiben
        nicht mehr aufgeführt)
      </h3>
      <div className='flex items-center gap-2 flex-wrap'>
        {attachmentType[value.type].map((type) => (
          <FormControlLabel
            key={type}
            label={type}
            control={
              <Checkbox
                checked={value.attachments.includes(type)}
                onChange={() =>
                  handleChange(
                    value.attachments.includes(type)
                      ? value.attachments.filter(
                          (attachment) => attachment !== type
                        )
                      : [...value.attachments, type],
                    'attachments'
                  )
                }
              />
            }
          />
        ))}
        {otherAttachments.map((type, index) => (
          <div className='flex items-center w-52 gap-2' key={index}>
            <CustomInput
              value={type}
              handleChange={(newValue) => {
                setOtherAttachments(
                  otherAttachments.map((attachment, i) =>
                    i === index ? newValue : attachment
                  )
                )
              }}
            />
            <IconButton>
              <DeleteIcon
                onClick={() =>
                  setOtherAttachments(
                    otherAttachments.filter((_, i) => i !== index)
                  )
                }
              />
            </IconButton>
          </div>
        ))}

        <div
          className='flex items-center justify-center gap-2'
          onClick={() => setOtherAttachments([...otherAttachments, ''])}
        >
          <h3>Weitere</h3>
          <IconButton>
          <AddIcon />
          </IconButton>
            
        </div>
      </div>
    </div>
  )
}

export default Attachments

import {
  Button as MUIButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material'
import ExportDropdown from 'assets/icons/exportDropdown'
import axios from 'axios'
import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import moment from 'moment'
import React, { useState } from 'react'
import { isBlob } from 'react-pdf/dist/umd/shared/utils'
import { useSearchParams } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { CV } from 'utils/documentTypes'
import { CERTIFICATE } from 'utils/documentTypes'
import { WORK_TRIAL } from 'utils/documentTypes'
import { INTERLOCKING_TEST } from 'utils/documentTypes'
import { translation } from 'utils/germanTranslation'
import { ShowToast } from 'utils/toastMessages'

const DOWNLOAD_DOCUMENTS = 'download-documents'

const DownloadDocuments = ({ user }) => {
  const [params, setParams] = useSearchParams()
  const [selectedDocuments, setSelectedDocuments] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleSelectDocuments = (type) => {
    const temp = selectedDocuments.includes(type)
      ? selectedDocuments.filter((doc) => doc !== type)
      : [...selectedDocuments, type]
    setSelectedDocuments(temp)
  }

  const downloadMergedDocuments = async (separately = false) => {
    // try {
    let pdfData = null
    ShowToast({ type: 'info', message: 'Downloading...' })
    if (separately) {
      for (let index = 0; index < selectedDocuments.length; index++) {
        const documentType = selectedDocuments[index]

        try {
          const { data } = await axios.post(
            BACKEND_URL + API_URL.documents.downloadMergedDocument + user?.id,
            {
              selectedDocuments: [documentType],
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
              responseType: 'blob',
            }
          )
          pdfData = data

          let link = document.createElement('a')
          if (link.download !== undefined) {
            let url = URL.createObjectURL(pdfData)
            link.setAttribute('href', url)
            link.setAttribute(
              'download',
              `${documentType
                .split(/ /g)
                .map(
                  (word) =>
                    `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`
                )
                .join(' ')}_${user?.firstName}_${user?.lastName}`
            )
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        } catch (error) {
          const isErrorOfBlobType = isBlob(error?.response?.data)
          if (isErrorOfBlobType) {
            const blob = error?.response?.data
            const reader = new FileReader()
            reader.onload = function () {
              const text = reader.result
              const jsonObject = JSON.parse(text)
              ShowToast({ message: jsonObject.error })
            }
            reader.readAsText(blob)
          } else ShowToast({ message: error })
        }
      }
    } else {
      try {
        const { data } = await axios.post(
          BACKEND_URL + API_URL.documents.downloadMergedDocument + user?.id,
          {
            selectedDocuments,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            responseType: 'blob',
          }
        )
        let link = document.createElement('a')
        if (link.download !== undefined) {
          let url = URL.createObjectURL(data)
          link.setAttribute('href', url)
          link.setAttribute(
            'download',
            `Bewerbungsunterlagen_${user?.firstName}_${user?.lastName}`
          )
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      } catch (error) {
        const isErrorOfBlobType = isBlob(error?.response?.data)
        if (isErrorOfBlobType) {
          const blob = error?.response?.data
          const reader = new FileReader()
          reader.onload = function () {
            const text = reader.result
            const jsonObject = JSON.parse(text)
            ShowToast({ message: jsonObject.error })
          }
          reader.readAsText(blob)
        } else ShowToast({ message: error })
      }
    }
  }

  return (
    <div>
      <MUIButton
        onClick={() => {
          setParams({ [DOWNLOAD_DOCUMENTS]: 'true' })
        }}
        startIcon={<ExportDropdown />}
        className='text-[10px] w-56 normal-case text-left'
      >
        {translation.Download_Application_Documents}
      </MUIButton>

      <CustomModal
        open={params.get(DOWNLOAD_DOCUMENTS) === 'true'}
        maxWidth='400px'
        handleClose={() => {
          setParams({ [DOWNLOAD_DOCUMENTS]: 'false' })
        }}
      >
        <div className='flex flex-col gap-4'>
          <h1 className='font-semibold'>
            Dokumente anklicken und herunterladen
          </h1>
        </div>
        <FormGroup onSubmit={handleSubmit}>
          {[CV, CERTIFICATE, INTERLOCKING_TEST, WORK_TRIAL].map(
            (doc, index) => (
              <FormControlLabel
                key={index}
                label={
                  doc === INTERLOCKING_TEST
                    ? `${INTERLOCKING_TEST} (z.B. Stellwerk)`
                    : doc
                }
                control={<Checkbox />}
                sx={{
                  '.MuiFormControlLabel-label:first-letter': {
                    textTransform: 'capitalize',
                  },
                }}
                value={selectedDocuments.includes(doc)}
                onChange={() => handleSelectDocuments(doc)}
              />
            )
          )}
        </FormGroup>
        <div className='flex items-center justify-center gap-4'>
          <Button
            title='Dokumente seperat herunterladen'
            onClick={() => downloadMergedDocuments(true)}
            titleClasses='text-white text-sm font-semibold'
            containerClasses='rounded-md w-full h-[46px] bg-[#458EFF] mt-4'
            disabled={selectedDocuments.length === 0}
          />
          <Button
            title='Dokumente als ein Dokument herunterladen'
            onClick={() => downloadMergedDocuments(false)}
            titleClasses='text-white text-sm font-semibold'
            containerClasses='rounded-md w-full h-[46px] bg-[#458EFF] mt-4'
            disabled={selectedDocuments.length === 0}
          />
        </div>
      </CustomModal>
    </div>
  )
}

export default DownloadDocuments

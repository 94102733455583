import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetAdminStudentOrTeacher = (
  role,
  name = '',
  limit,
  offset,
  findCount,
  searchByName
) => {
  const [value, setValue] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const getUserData = useCallback(async () => {
    const url = role.toLowerCase() + 'Detail';
    try {
      const { data: userData } = await axios.post(
        BACKEND_URL + API_URL.admin[url],
        { name, limit, offset, findCount, searchByName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setValue(userData.data);
      setCount(userData.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  }, [role, name, limit, offset, findCount]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return { loading, data: value, count, refetch: getUserData, error };
};

export default useGetAdminStudentOrTeacher;

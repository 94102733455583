import { CircularProgress } from '@mui/material'
import useFetchBannerById from 'hooks/useFetchBannerById'
import React from 'react'
import { useParams } from 'react-router-dom'
import AddBanner from './AddBanner'

const UpdateBanner = () => {
  const { bannerId: id } = useParams()
  const { banner, loading } = useFetchBannerById(id)

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <AddBanner banner={banner} toUpdate={true} />
      )}
    </React.Fragment>
  )
}

export default UpdateBanner

import * as React from 'react';

const ExportDropdown = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 19.458c-4.941 0-8.958-4.016-8.958-8.958S5.059 1.54 10 1.54c4.942 0 8.959 4.017 8.959 8.959 0 4.941-4.017 8.958-8.959 8.958Zm0-16.666c-4.25 0-7.708 3.458-7.708 7.708S5.75 18.208 10 18.208 17.71 14.75 17.71 10.5 14.25 2.792 10 2.792Z'
      fill='#05060E'
    />
    <path
      d='M10 13.008a.618.618 0 0 1-.442-.183L6.617 9.883a.629.629 0 0 1 0-.883.629.629 0 0 1 .883 0l2.5 2.5L12.5 9a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883l-2.941 2.942a.618.618 0 0 1-.442.183Z'
      fill='#05060E'
    />
  </svg>
);

export default ExportDropdown;

import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import userSlice from 'store/reducers/user';

const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: '12345678',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  userSlice: userSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

// store.dispatch(fetchLanguages());
// store.dispatch(fetchAcademicHistory());

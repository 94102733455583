import { createSelector, createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
const userInitialState = {
  id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  avatar: '',
  token: '',
  address: '',
  place: '',
  postalCode: '',
  phone: '',
  isLoggedIn: false,
}

export const fetchUser = createAsyncThunk(
  'products/fetchLanguages',
  async (params, thunkAPI) => {
    const userId = params?.id || thunkAPI.getState().userSlice.id
    try {
      const { data } = await axios.get(
        BACKEND_URL + API_URL.user.findById + userId
      )
      const user = data?.user
      return {
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: user?.role,
        email: user?.email,
        avatar: user?.avatar,
        postalCode: user?.postalCode,
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    handleUserChange: (state, action) => {
      const {
        id,
        firstName,
        lastName,
        role,
        email,
        avatar,
        token,
        address,
        place,
        postalCode,
        phone,
      } = action.payload
      state.id = id
      state.firstName = firstName
      state.lastName = lastName
      state.role = role
      state.email = email
      state.avatar = avatar
      state.token = token
      state.phone = phone
      state.address = address
      state.place = place
      state.postalCode = postalCode
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.id = ''
      state.firstName = ''
      state.lastName = ''
      state.role = ''
      state.email = ''
      state.avatar = ''
      state.token = ''
      state.phone = ''
      state.address = ''
      state.place = ''
      state.postalCode = ''
      state.isLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.id = payload.id
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.role = payload.role
      state.email = payload.email
      state.avatar = payload.avatar
      state.postalCode = payload.postalCode
    })
  },
})

export const { handleUserChange, logout } = userSlice.actions

export const user = createSelector(
  ({ userSlice }) => {
    return {
      id: userSlice.id,
      firstName: userSlice.firstName,
      lastName: userSlice.lastName,
      role: userSlice.role,
      email: userSlice.email,
      avatar: userSlice.avatar,
      token: userSlice.token,
      phone: userSlice.phone,
      address: userSlice.address,
      place: userSlice.place,
      postalCode: userSlice.postalCode,
      isLoggedIn: userSlice.isLoggedIn,
    }
  },
  (state) => state
)
export default userSlice

import React from 'react';
import Box from '@mui/material/Box';
import JobListingCard from 'components/JobListingCard';

const AllCategoriesJobs = ({
  jobs,
  handleChange,
  userId,
  jobType,
  jobCategory,
}) => {
  return (
    <Box className='grid grid-cols-none sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-6 w-full'>
      {Object.keys(jobs).map((key, index) => (
        <JobListingCard
          key={index}
          index={index}
          handleChange={(...props) => handleChange(...props)}
          label={key}
          data={jobs[key]}
          userId={userId}
          jobType={jobType}
          jobCategory={jobCategory}
        />
      ))}
    </Box>
  );
};

export default AllCategoriesJobs;

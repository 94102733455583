import React from 'react';

const AdminStudentsStatsIcon = () => {
  return (
    <svg width={92} height={92} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect opacity={0.1} width={92} height={92} rx={24} fill='#458EFF' />
      <path
        d='M39.614 45.146h-.18a1.218 1.218 0 0 0-.404 0c-6.517-.203-11.438-5.326-11.438-11.64 0-6.427 5.236-11.663 11.662-11.663 6.427 0 11.663 5.236 11.663 11.663-.023 6.314-4.966 11.438-11.236 11.64h-.067Zm-.36-19.932c-4.561 0-8.291 3.73-8.291 8.292a8.232 8.232 0 0 0 7.977 8.269c.135-.022.427-.022.719 0 4.404-.202 7.865-3.82 7.887-8.27 0-4.561-3.73-8.291-8.292-8.291ZM56.198 45.438c-.068 0-.135 0-.202-.023-.922.09-1.866-.561-1.955-1.483-.09-.92.472-1.752 1.393-1.865.27-.022.562-.022.809-.022a6.171 6.171 0 0 0 5.842-6.18 6.176 6.176 0 0 0-6.18-6.18c-.92.023-1.685-.74-1.685-1.662 0-.922.764-1.686 1.686-1.686 5.258 0 9.55 4.292 9.55 9.55a9.53 9.53 0 0 1-9.19 9.551h-.068ZM39.637 69.707c-4.405 0-8.832-1.124-12.18-3.37-3.123-2.068-4.831-4.9-4.831-7.978 0-3.079 1.708-5.932 4.831-8.022 6.742-4.472 17.663-4.472 24.36 0 3.1 2.067 4.83 4.898 4.83 7.977 0 3.079-1.707 5.932-4.83 8.022-3.371 2.247-7.776 3.371-12.18 3.371ZM29.322 53.168c-2.157 1.438-3.325 3.28-3.325 5.214 0 1.91 1.19 3.752 3.325 5.168 5.596 3.752 15.034 3.752 20.629 0 2.157-1.438 3.326-3.281 3.326-5.213 0-1.91-1.191-3.753-3.326-5.169-5.595-3.73-15.033-3.73-20.629 0ZM60.242 65.662c-.786 0-1.483-.54-1.64-1.348a1.709 1.709 0 0 1 1.303-2c1.416-.292 2.72-.854 3.73-1.64 1.281-.967 1.978-2.18 1.978-3.461 0-1.281-.697-2.494-1.955-3.438-.989-.764-2.225-1.304-3.685-1.64a1.7 1.7 0 0 1-1.281-2.023 1.7 1.7 0 0 1 2.022-1.28c1.933.426 3.618 1.19 4.989 2.246 2.09 1.573 3.28 3.798 3.28 6.135 0 2.337-1.213 4.562-3.303 6.157-1.393 1.079-3.146 1.865-5.078 2.247a1.05 1.05 0 0 1-.36.045Z'
        fill='#458EFF'
      />
    </svg>
  );
};

export default AdminStudentsStatsIcon;

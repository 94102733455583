import React from 'react'
import {
  DataTableCell,
  TableHeader,
  TableBody,
  TableCell,
  Table,
} from '@david.kucsai/react-pdf-table'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

import lehrstellLogo from 'assets/images/logo-transparent.png'
import { getFormattedDiaryDate } from 'utils'
import { translation } from 'utils/germanTranslation'

const ExportDiary = ({ data, loggedInUser }) => {
  return (
    <Document title='Mein Lehrstell-Journal'>
      <Page
        wrap
        orientation='landscape'
        style={{
          padding: 20,
        }}
      >
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            borderBottom: '1px solid black',
            paddingBottom: 10,
            alignItems: 'center',
          }}
        >
          <Image
            src={lehrstellLogo}
            style={{
              width: 50,
              height: 40,
            }}
          />
          <Text style={{ flex: 1 }}>Mein Lehrstell-Journal</Text>
          <Text style={{ flex: 1, textAlign: 'right' }}>
            {loggedInUser.firstName} {loggedInUser.lastName}
          </Text>
        </View>
        <Table data={data}>
          <TableHeader>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Beruf
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Name der Firma
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Kontaktperson
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Kontakt E-Mail
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Typ
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              Datum
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#F0F2F9',
                padding: 5,
                fontSize: 14,
              }}
            >
              {translation.notes}
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell style={{ padding: 5 }} getContent={(r) => r.job} />
            <DataTableCell
              style={{ padding: 5 }}
              getContent={(r) => r.companyName}
            />
            <DataTableCell
              style={{ padding: 5 }}
              getContent={(r) => r.contactPerson}
            />
            <DataTableCell
              style={{
                padding: 5,
                hyphenate: true,
              }}
              getContent={(r) => {
                let atTheRate = r.contactEmail.indexOf('@')
                let email = r.contactEmail
                let emailStart = email.slice(0, atTheRate)
                let emailEnd = email.slice(atTheRate)
                return (
                  <Text>
                    {emailStart}
                    {'\u00AD'}
                    {emailEnd}
                  </Text>
                )
              }}
            />
            <DataTableCell style={{ padding: 5 }} getContent={(r) => r.type} />
            <DataTableCell
              style={{ padding: 5 }}
              getContent={(r) => <Text>{getFormattedDiaryDate(r)}</Text>}
            />
            <DataTableCell style={{ padding: 5 }} getContent={(r) => r.notes} />
          </TableBody>
        </Table>
      </Page>
    </Document>
  )
}

export default ExportDiary

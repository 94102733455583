import { CircularProgress } from '@mui/material'
import axios from 'axios'
import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import Navbar from 'components/Navbar/Navbar'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { user } from 'store/reducers/user'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { translation } from 'utils/germanTranslation'

const modalDetailsInitialState = {
  title: '',
  description: '',
  show: false,
}

const GERMAN_TEXT = {
  incorrect: {
    user: {
      title: 'Falscher Benutzer erkannt',
      description:
        'Der Einladungslink, auf den Sie zugreifen möchten, gehört nicht Ihnen.',
    },
    token: {
      title: 'Falscher Token',
      description:
        'Der Einladungslink, auf den Sie zugreifen möchten, ist falsch.',
    },
  },
}

const Invite = () => {
  const loggedInUser = useSelector(user)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [modalDetails, setModalDetails] = useState(modalDetailsInitialState)
  const { token } = useParams()

  const addMemberToClass = async () => {
    let classDetails = null
    try {
      classDetails = jwtDecode(token)
    } catch (error) {
      setLoading(false)
      setModalDetails({
        title: GERMAN_TEXT.incorrect.token.title,
        description: GERMAN_TEXT.incorrect.token.description,
        show: true,
      })
      return
    }
    if (classDetails.userId !== loggedInUser?.email) {
      if (!classDetails?.isPublic) {
        setLoading(false)
        setModalDetails({
          title: GERMAN_TEXT.incorrect.user.title,
          description: GERMAN_TEXT.incorrect.user.description,
          show: true,
        })
        return
      }
    }
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.classes.addMemberToClass,
        {
          classId: classDetails.classId,
          memberId: classDetails?.isPublic
            ? loggedInUser?.email
            : classDetails.userId,
        }
      )
      if (data?.success) {
        setLoading(false)
        setModalDetails({
          title: translation.success,
          description: data.message,
          show: true,
        })
      }
    } catch (error) {
      setLoading(false)
      setModalDetails({
        title: translation.error,
        description: error.response.data.message,
        show: true,
      })
    }
  }

  useEffect(() => {
    if (token) {
      addMemberToClass()
    }
  }, [token])

  return (
    <div>
      <Navbar />
      {loading ? (
        <div className='flex justify-center items-center h-screen'>
          <CircularProgress />
        </div>
      ) : (
        <CustomModal open={modalDetails.show}>
          <div className='flex flex-col items-center justify-center'>
            <h1 className='text-2xl font-bold'>{modalDetails.title}</h1>
            <p
              className='text-lg'
              dangerouslySetInnerHTML={{ __html: modalDetails.description }}
            ></p>
          </div>
          <div className='flex justify-center'>
            <Button
              title={translation.close}
              containerClasses='bg-blue-500 text-white px-4 py-2 rounded-md mt-2'
              onClick={() => {
                navigate('/')
              }}
            />
          </div>
        </CustomModal>
      )}
    </div>
  )
}

export default Invite

import React, { useState, useEffect } from 'react'

import DeleteIcon from 'assets/icons/delete'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import useGetHobbies from 'hooks/useGetHobbies'
import { updateHobbies } from './updateHobbies'
import CustomInput from 'components/Input'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'

const VerifiedEditHobbies = ({ id, navigate }) => {
  const { loading: hobbiesLoading, data } = useGetHobbies(id)

  const initialValue = {
    title: '',
    description: '',
  }

  const [value, setValue] = useState([initialValue])
  const [errors, setErrors] = useState([initialValue])
  const [loading, setLoading] = useState(false)
  const onBackButtonEvent = useGetBack(value)

  const handleDelete = (index) => {
    setValue((prev) => prev.filter((_, i) => i !== index))
  }

  const handleChange = (index, newValue, field) => {
    const temp = [...value].map((object, i) =>
      i === index ? { ...object, [field]: newValue } : object
    )
    setValue(temp)
  }

  useEffect(() => {
    if (data.length) setValue(data)
  }, [data])

  return (
    <EditSkills
      pageTitle={translation.hobbies}
      loading={hobbiesLoading}
      disabled={loading || hobbiesLoading}
      onUpdateClick={() =>
        updateHobbies(
          value,
          setLoading,
          id,
          setErrors,
          initialValue,
          navigate,
          onBackButtonEvent
        )
      }
    >
      <SimpleEditWrapper
        value={value}
        pageTitle={translation.hobbies}
        customized
        addAnotherField={() => setValue([...value, { ...initialValue }])}
      >
        {value.map((item, index) => (
          <div
            key={index}
            className='flex flex-col sm:flex-row gap-4 items-center'
          >
            <CustomInput
              label='Hobby'
              value={item.title}
              handleChange={(newValue) =>
                handleChange(index, newValue, 'title')
              }
              error={errors[index]?.title ? true : false}
              helperText={errors[index]?.title}
              placeholder='Fussball'
            />
            <CustomInput
              label={
                <div className='flex justify-between w-full'>
                  <h3>Beschreibung</h3>
                  <span>Zeichen übrig: {60 - item.description.length}</span>
                </div>
              }
              placeholder='Ich spiele zweimal pro Woche Fussball beim SC Kriens'
              value={item.description}
              handleChange={(newValue) =>
                newValue.length <= 60 &&
                handleChange(index, newValue, 'description')
              }
              error={errors[index]?.description ? true : false}
              helperText={errors[index]?.description}
            />
            <div onClick={() => handleDelete(index)}>
              <DeleteIcon />
            </div>
          </div>
        ))}
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default VerifiedEditHobbies

import React from 'react';

const AdminStatsCard = ({ icon, title, count }) => {
  return (
    <div
      className='bg-white p-7 rounded-2xl flex gap-8'
      style={{ boxShadow: '0px 9px 44px rgba(17, 37, 66, 0.08)' }}
    >
      <div>{icon}</div>
      <div className='flex flex-col gap-3 justify-center'>
        <div className='text-xl sm:text-2xl md:text-3xl font-bold'>{count}</div>
        <div className='text-xl sm:text-2xl md:text-2xl'>{title}</div>
      </div>
    </div>
  );
};

export default AdminStatsCard;

import React from 'react'

import Navbar from 'components/Navbar/Navbar'

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className='bg-[#F0F1F5] flex-col min-h-screen w-full flex p-6 gap-4 items-center justify-center text-softBlack'>
        <div className='w-full max-w-2xl font-bold text-2xl text-softBlack underline'>
          Über uns
        </div>
        <div className='bg-white rounded-lg max-w-2xl p-4'>
          <p>
            Seit über zwei Jahren haben wir in unserer Freizeit diese Plattform
            erstellt, damit die Berufswahl (insbesondere Schnupper- &
            Lehrstellensuche) für Lehrpersonen und vor allem für Schüler*innen
            simpler und zeitgemässer wird.
          </p>
          <br />
          Das Hauptziel ist, dass alle Schüler*innen den Berufswahlprozess und
          insbesondere den Bewerbungsprozess eigenständiger, mit der Lehrperson
          als Funktion des Coaches, bestreiten können.
          <p>&nbsp;</p>
          <span className='font-bold'>Wer sind wir?</span>
          <br />
          Wir sind ein kreatives Team, bestehend aus zwei Lehrpersonen
          (Klassenlehrperson Niveau C & Klassenlehrperson Niveau B/
          IF-Lehrperson) und einem tollen Informatiker.
          <p>&nbsp;</p>
          Verbesserungsvorschläge & Anregungen bitte unbedingt an{' '}
          <a
            href='mailto:info@lehrstell.ch'
            className='text-blue-500 underline font-medium'
          >
            info@lehrstell.ch
          </a>{' '}
          schicken!
        </div>
      </div>
    </>
  )
}

export default AboutUs

import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetMotivationLetters = (id, letterId) => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const reqHeaders = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    id,
  };

  useEffect(() => {
    if (letterId !== 'none') getMotivationLetters();
  }, []);

  const getMotivationLetters = async () => {
    try {
      const url = letterId
        ? `${API_URL.motivationLetters.findById}${letterId}`
        : API_URL.motivationLetters.all;
      const { data: userData } = await axios.get(BACKEND_URL + url, {
        headers: reqHeaders,
      });
      const diary = JSON.parse(userData.data);
      setValue(diary);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return {
    loading,
    data: value,
    refetch: getMotivationLetters,
    error,
  };
};

export default useGetMotivationLetters;

import React, { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';

const filter = createFilterOptions();

const AutoComplete = ({
  options,
  setOptions,
  placeholder,
  value,
  label = '',
  labelClasses = '',
  containerClasses = 'w-full',
  handleChange,
  controlled = true,
  required = false,
  error,
  helperText,
  inputBackgroundColor = 'transparent',
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const uncontrolledProps = {
    filterOptions: (options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      const isExisting = options.some(
        (option) =>
          inputValue.toLowerCase().trim() === option.title.toLowerCase().trim()
      );
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          title: `Hinzufügen "${inputValue}"`,
        });
      }
      return filtered;
    },
    selectOnFocus: true,
    clearOnBlur: true,
    handleHomeEndKeys: true,
    freeSolo: true,
    forcePopupIcon: true,
    getOptionLabel: (option) => {
      if (typeof option === 'string') {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return option.title;
    },
    isOptionEqualToValue: (option, inputValue) =>
      option.inputValue === value || option.title === inputValue,
    renderOption: (props, option) => <li {...props}>{option.title}</li>,
  };

  const controlledProps = {
    inputValue: inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
    },
  };

  return (
    <div className={containerClasses}>
      <InputLabel shrink htmlFor='bootstrap-input' className={labelClasses}>
        {label}
      </InputLabel>
      <Autocomplete
        disablePortal
        value={value}
        onChange={(e, newValue, reason) => {
          let title = newValue?.inputValue || newValue?.title;
          handleChange(controlled ? newValue : title);
          if (reason === 'selectOption' && !controlled) {
            const checkIfExists = [...options].some((el) => el.title === title);
            if (!checkIfExists) {
              setOptions([...options, { title }]);
            }
          }
        }}
        {...(controlled ? controlledProps : uncontrolledProps)}
        options={options}
        sx={{
          maxWidth: 539,
          width: '100%',
          [`& .MuiInputBase-root`]: {
            height: 44,
            backgroundColor: inputBackgroundColor,
          },
          [`& .MuiAutocomplete-input`]: {
            height: 10,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            required={required}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
          />
        )}
        {...props}
      />
    </div>
  );
};
export default AutoComplete;

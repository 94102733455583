import DeleteIcon from 'assets/icons/delete'
import UploadJobImage from 'assets/icons/uploadJobImage'
import React from 'react'
import { FileUploader } from 'react-drag-drop-files'

const UploadBanner = ({ image, setImage }) => {
  return (
    <React.Fragment>
      {image ? (
        <div className='relative'>
          <img
            src={image}
            alt='job'
            className='w-[167px] h-[135px] object-contain rounded-2xl'
            onContextMenu={(e) => e.preventDefault()}
          />
          <div
            onClick={() => {
              setImage(null)
            }}
            className='-left-[8px] -top-[10px] border-2 border-[#E8E8E8] rounded-full absolute bg-white'
          >
            <DeleteIcon />
          </div>
        </div>
      ) : (
        <FileUploader
          handleChange={(file) => setImage(file)}
          types={['jpeg', 'png', 'jpg']}
        >
          <div className='cursor-pointer w-[167px] h-[135px] bg-[#F0F8FF] border border-[#209ADA] border-dashed rounded-lg text-[#458EFF] text-sm font-medium flex items-center justify-center flex-col gap-1'>
            <UploadJobImage />
            <p>Bild hochladen</p>
          </div>
        </FileUploader>
      )}
    </React.Fragment>
  )
}

export default UploadBanner

import * as React from 'react';

const TrophyIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.125 16.625A.63.63 0 0 1 9.5 16v-1.75a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625V16a.63.63 0 0 1-.625.625Z'
      fill='#05060E'
    />
    <path
      d='M14.917 19.459H5.333V18a2.29 2.29 0 0 1 2.292-2.291h5A2.29 2.29 0 0 1 14.917 18v1.459Zm-8.334-1.25h7.084V18c0-.575-.467-1.041-1.042-1.041h-5c-.575 0-1.042.466-1.042 1.041v.209Z'
      fill='#05060E'
    />
    <path
      d='M15.125 19.459h-10a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h10a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625ZM15.358 10.867a.635.635 0 0 1-.475-.217.616.616 0 0 1-.1-.658c.283-.65.425-1.342.425-2.067v-2.5c0-.292-.05-.575-.15-.875a1.366 1.366 0 0 1-.025-.092A1.875 1.875 0 0 1 15 4.092a.63.63 0 0 1 .625-.625h.5c1.491 0 2.708 1.25 2.708 2.791A4.914 4.914 0 0 1 17.4 9.7c-.017.017-.067.058-.075.067-.492.408-1.05.867-1.8 1.075a.577.577 0 0 1-.167.025ZM16.4 4.742c.041.225.058.458.058.683v2.5c0 .342-.025.667-.075 1l.142-.117a3.644 3.644 0 0 0 1.058-2.55c0-.75-.508-1.383-1.183-1.516ZM4.65 10.833a.517.517 0 0 1-.192-.033A4.637 4.637 0 0 1 2.6 9.65C1.64 8.592 1.167 7.433 1.167 6.208A2.682 2.682 0 0 1 3.875 3.5h.542a.623.623 0 0 1 .575.875c-.134.3-.2.642-.2 1v2.5c0 .717.141 1.417.433 2.083a.63.63 0 0 1-.108.667.64.64 0 0 1-.467.208ZM3.583 4.775a1.444 1.444 0 0 0-1.167 1.433c0 .909.367 1.784 1.092 2.584a.72.72 0 0 0 .117.108 6.073 6.073 0 0 1-.083-1.025v-2.5c0-.2.016-.4.041-.6Z'
      fill='#05060E'
    />
    <path
      d='M10 14.458c-3.559 0-6.458-2.9-6.458-6.458V5.5A3.962 3.962 0 0 1 7.5 1.542h5A3.962 3.962 0 0 1 16.458 5.5V8c0 3.558-2.9 6.458-6.458 6.458ZM7.5 2.792A2.714 2.714 0 0 0 4.792 5.5V8A5.21 5.21 0 0 0 10 13.208 5.21 5.21 0 0 0 15.208 8V5.5A2.714 2.714 0 0 0 12.5 2.792h-5Z'
      fill='#05060E'
    />
  </svg>
);

export default TrophyIcon;

import CustomInput from 'components/Input'
import React from 'react'
import StepTitle from '../StepTitle'
import {
  ApprenticeshipQuestions,
  TrialApprenticeshipQuestions,
} from './Questions'
import { TrialApprenticeship } from '../Step1'

export const MotivationLetterTypeOptions = [
  'Trial Apprenticeship',
  'Apprenticeship',
]

const Step2 = ({ value, handleChange, step }) => {
  const questions =
    value.type === TrialApprenticeship
      ? TrialApprenticeshipQuestions
      : ApprenticeshipQuestions.slice(0, 3)
  return (
    <>
      <StepTitle step={`${step} (${value.type})`} />
      <h1 className='font-bold mt-5'>Beantworte die Fragen:</h1>
      <p className='italic'>
        (Die Vorlage hilft dir, wenn du nicht genau weisst, was du schreiben
        sollst)
      </p>
      <div className='flex gap-4 flex-col mt-5 '>
        {questions.map((question) => (
          <CustomInput
            key={question.label}
            label={question.label}
            type='text'
            labelClasses='font-medium text-softBlack text-lg whitespace-normal'
            value={value.questions[question.label]}
            handleChange={(newValue) =>
              handleChange(newValue, 'questions', question.label)
            }
            placeholder={question.placeholder}
            multiline
            disableInputPadding
            minRows={4}
          />
        ))}
      </div>
    </>
  )
}

export default Step2

import React from 'react'
import CustomModal from './CustomModal'
import OptimalZoonModal from 'assets/images/zoom-instructions.png'
import CloseIcon from '@mui/icons-material/Close'

const ZoomInstructionsModal = ({
  showInstructionsModal,
  setShowInstructionsModal,
}) => {
  return (
    <CustomModal
      open={showInstructionsModal}
      handleClose={() => setShowInstructionsModal(false)}
      padding='0'
      containerClasses='rounded w-full'
    >
      <CloseIcon
        onClick={() => setShowInstructionsModal(false)}
        className='absolute -top-[12px] xs:-top-[20px] right-0 sm:-right-[20px] rounded-[50%] cursor-pointer bg-red-600 text-white w-7 xs:w-11 h-7 xs:h-11 z-10 hover:scale-110 transition-all shadow-2xl'
      />
      <div>
        <img src={OptimalZoonModal} alt='' />
      </div>
    </CustomModal>
  )
}

export default ZoomInstructionsModal

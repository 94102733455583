import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetAdminJobs = () => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data: jobs } = await axios.get(
        BACKEND_URL + API_URL.job.adminDashboardJobs,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const allJobs = jobs.data;
      setValue(allJobs);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return { loading, data: value, refetch: getData, error };
};

export default useGetAdminJobs;

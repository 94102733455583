import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';
import {
  CERTIFICATE,
  INTERLOCKING_TEST,
  RESUME,
  WORK_TRIAL,
} from 'utils/documentTypes';

const useGetUserDocuments = (id, documentType) => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    try {
      const { data: userData } = await axios.get(
        BACKEND_URL + API_URL.documents.all,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id,
          },
        }
      );
      const documents = JSON.parse(userData.data);

      if (
        documents.length > 0 &&
        [CERTIFICATE, INTERLOCKING_TEST, WORK_TRIAL].includes(
          String(documentType).toLowerCase()
        )
      ) {
        let temp = [];
        documents.forEach((document) => {
          if (document.type.toLowerCase() === documentType.toLowerCase()) {
            temp.push(document);
          }
        });
        setValue(temp);
      } else {
        setValue(documents);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return { loading, data: value, refetch: getData, error };
};

export default useGetUserDocuments;

import Button from 'components/Button'
import CustomInput from 'components/Input'
import React from 'react'
import { EditSchool } from '.'
import { translation } from 'utils/germanTranslation'

const ChangeSchoolDetailModal = ({
  value,
  setValue,
  setSearchParams,
  onSubmit,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      className='p-4'
    >
      <div className='grid grid-cols-none sm:grid-cols-3 gap-3'>
        <CustomInput
          label={translation.className}
          value={value?.className}
          handleChange={(newValue) =>
            setValue({ ...value, className: newValue })
          }
        />
        <CustomInput
          label={translation.schoolName}
          value={value?.schoolName}
          handleChange={(newValue) =>
            setValue({ ...value, schoolName: newValue })
          }
        />
        <CustomInput
          label={translation.postalCode}
          value={value?.schoolZipCode}
          handleChange={(newValue) =>
            setValue({ ...value, schoolZipCode: newValue })
          }
        />
        <CustomInput
          label='Ort der Schule'
          value={value?.schoolPlace}
          handleChange={(newValue) =>
            setValue({ ...value, schoolPlace: newValue })
          }
        />
      </div>
      <div className='flex justify-center gap-4 mt-4'>
        <Button
          title='zurück'
          onClick={() => setSearchParams({ [EditSchool]: 'false' })}
          containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
          backgroundColor='#E9F1FC'
          type='button'
        />
        <Button
          title='speichern'
          type='submit'
          // onClick={() => handleAddSecondAdmin()}
          containerClasses='px-8 py-2 rounded-lg text-white'
          backgroundColor='#458EFF'
        />
      </div>
    </form>
  )
}

export default ChangeSchoolDetailModal

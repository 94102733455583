import * as React from 'react';

const MotivationLetterStudentOptionsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={130} height={130} fill='none'>
    <g clipPath='url(#a)'>
      <path
        fill='#F9EDB0'
        d='M65 130c35.899 0 65-29.101 65-65 0-35.898-29.101-65-65-65C29.102 0 0 29.102 0 65c0 35.899 29.102 65 65 65Z'
      />
      <path
        fill='#F0DB6C'
        d='M65 112c11.598 0 21-1.343 21-3s-9.402-3-21-3-21 1.343-21 3 9.402 3 21 3Z'
      />
      <path
        fill='#fff'
        stroke='#020064'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.24}
        d='m56.454 14.852-15.85 59.786a1.717 1.717 0 0 0 1.22 2.1l44.01 11.668a1.717 1.717 0 0 0 2.1-1.22l15.85-59.786a1.717 1.717 0 0 0-1.22-2.1l-44.01-11.668a1.717 1.717 0 0 0-2.1 1.22Z'
      />
      <path
        fill='#fff'
        stroke='#020064'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.24}
        d='M64.966 19.146 21.373 32.284a1.717 1.717 0 0 0-1.15 2.14l17.849 59.22a1.717 1.717 0 0 0 2.14 1.149l43.593-13.138a1.717 1.717 0 0 0 1.148-2.14l-17.847-59.22a1.717 1.717 0 0 0-2.14-1.15Z'
      />
      <path
        fill='#fff'
        stroke='#020064'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.24}
        d='m39.613 32.313-3.824 61.733a1.717 1.717 0 0 0 1.608 1.82l45.443 2.815a1.717 1.717 0 0 0 1.82-1.608l3.824-61.733a1.717 1.717 0 0 0-1.608-1.82l-45.443-2.815a1.717 1.717 0 0 0-1.82 1.608Z'
      />
      <path
        stroke='#020064'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={0.24}
        d='m47.583 60.128 1.794 2.042 5.793-5.815M57.12 61.45l.857.997a1.588 1.588 0 0 0 2.239.167l.997-.856a1.59 1.59 0 0 1 2.238.167l.858.998a1.588 1.588 0 0 0 2.239.167l.997-.857a1.587 1.587 0 0 1 2.239.167l1.03 1.124a1.59 1.59 0 0 0 2.238.176l.997-.857a1.59 1.59 0 0 1 2.239.168l1.093 1.223M48.797 46.912l1.793 2.04 5.794-5.814M58.334 48.23l.858.997a1.584 1.584 0 0 0 2.237.173l.997-.857a1.589 1.589 0 0 1 2.24.168l.857.997a1.588 1.588 0 0 0 2.239.168l.997-.857a1.585 1.585 0 0 1 2.238.168l1.03 1.124a1.582 1.582 0 0 0 1.7.474c.197-.065.38-.169.538-.305l.998-.858a1.584 1.584 0 0 1 2.238.169l1.095 1.222M46.29 74.213l1.793 2.042 5.794-5.815M55.9 74.814l.858.996a1.585 1.585 0 0 0 2.238.169l.997-.858a1.586 1.586 0 0 1 2.24.169l.856.995a1.586 1.586 0 0 0 2.239.17l.997-.859a1.586 1.586 0 0 1 2.239.17'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h130v130H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default MotivationLetterStudentOptionsIcon;

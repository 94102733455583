import React from 'react'

const EditIcon = ({ ...props }) => (
  <svg
    width='35'
    height='40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 40 40'
    className='hover:scale-105 transition-all ease-in-out'
    {...props}
  >
    <path
      d='M23 30.75h-6c-5.43 0-7.75-2.32-7.75-7.75v-6c0-5.43 2.32-7.75 7.75-7.75h2c.41 0 .75.34.75.75s-.34.75-.75.75h-2c-4.61 0-6.25 1.64-6.25 6.25v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-2c0-.41.34-.75.75-.75s.75.34.75.75v2c0 5.43-2.32 7.75-7.75 7.75Z'
      fill='#05060E'
    ></path>
    <path
      d='M16.5 25.69c-.61 0-1.17-.22-1.58-.62-.49-.49-.7-1.2-.59-1.95l.43-3.01c.08-.58.46-1.33.87-1.74l7.88-7.88c1.99-1.99 4.01-1.99 6 0 1.09 1.09 1.58 2.2 1.48 3.31-.09.9-.57 1.78-1.48 2.68l-7.88 7.88c-.41.41-1.16.79-1.74.87l-3.01.43c-.13.03-.26.03-.38.03Zm8.07-14.14-7.88 7.88c-.19.19-.41.63-.45.89l-.43 3.01c-.04.29.02.53.17.68.15.15.39.21.68.17l3.01-.43c.26-.04.71-.26.89-.45l7.88-7.88c.65-.65.99-1.23 1.04-1.77.06-.65-.28-1.34-1.04-2.11-1.6-1.6-2.7-1.15-3.87.01Z'
      fill='#05060E'
    ></path>
    <path
      d='M27.85 17.83c-.07 0-.14-.01-.2-.03a7.937 7.937 0 0 1-5.46-5.46.76.76 0 0 1 .52-.93c.4-.11.81.12.92.52.6 2.13 2.29 3.82 4.42 4.42.4.11.63.53.52.93-.09.34-.39.55-.72.55Z'
      fill='#05060E'
    ></path>
    <rect
      x='0.5'
      y='0.5'
      width='39'
      height='39'
      rx='11.5'
      stroke='#99AECF'
    ></rect>
  </svg>
)

export default EditIcon

import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { CERTIFICATE, INTERLOCKING_TEST, WORK_TRIAL } from 'utils/documentTypes'

export const updateDocuments = async (
  value,
  setLoading,
  id,
  navigate,
  documentType
) => {
  setLoading(true)
  try {
    const toMerge = [CERTIFICATE, INTERLOCKING_TEST, WORK_TRIAL].includes(
      String(documentType).toLowerCase()
    )
    const { data } = await axios.post(
      BACKEND_URL + API_URL.documents.submitDocument,
      {
        value: value.map((doc) => {
          return { ...doc, type: String(doc.type).toLowerCase() }
        }),
        toMerge,
        documentType,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
    navigate(-1)
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
  }
}

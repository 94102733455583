import { IconButton } from '@mui/material'
import SmallEditIcon from 'assets/icons/smallEdit'
import Tag from 'components/Tag'
import React from 'react'
import { Link } from 'react-router-dom'

const EditableTag = ({
  canEdit,
  icon,
  title,
  onClick,
  link,
  titleClasses,
  containerClasses = 'py-3 px-4 justify-center',
}) => {
  return (
    <div className='relative'>
      <Tag
        icon={icon}
        showBorder
        containerClasses={containerClasses}
        title={title}
        titleClasses={titleClasses}
        onClick={onClick}
      />
      {canEdit && (
        <Link to={link}>
          <IconButton className='absolute -top-[20px] -right-[20px]'>
            <SmallEditIcon  />
          </IconButton>
        </Link>
      )}
    </div>
  )
}

export default EditableTag

import React from 'react'

const Tag = ({
  icon,
  title,
  onClick,
  width = 'auto',
  height = 'auto',
  showBorder,
  containerClasses = '',
  titleClasses = '',
  style = {},
}) => {
  return (
    <div
      style={{
        width,
        height,
        ...style,
      }}
      onClick={onClick}
      className={`${
        showBorder ? 'border border-[#99AECF]' : ''
      } rounded-2xl flex items-center gap-3 select-none cursor-pointer ${containerClasses}`}
    >
      <div>{icon}</div>
      {title && <p className={titleClasses}>{title}</p>}
    </div>
  )
}

export default Tag

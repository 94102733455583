import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

export const deleteDiaryById = async (
  id,
  letterId,
  handleModalClose,
  refetch
) => {
  const reqHeaders = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    id,
  };
  try {
    const { data } = await axios.delete(
      BACKEND_URL + API_URL.motivationLetters.deleteById + letterId,
      { headers: reqHeaders }
    );
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
    handleModalClose();
    refetch();
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  }
};

import NoAccess from 'components/NoAccess'
import VerifyEditAccess from 'components/VerifyEditAccess'
import React from 'react'
import VerifiedEditLanguage from './VerifiedEditLanguage'

const EditLanguages = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate) =>
        canEdit ? (
          <VerifiedEditLanguage id={id} navigate={navigate} />
        ) : (
          <NoAccess />
        )
      }
    </VerifyEditAccess>
  )
}

export default EditLanguages

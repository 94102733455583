import React, { useState, useEffect } from 'react'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import Input from 'components/Input'
import DeleteIcon from 'assets/icons/delete'
import { updateAcademicHistory } from './updateAcedmicHistory'
import { ACADEMIC_HISTORY } from 'utils'
import useGetSkill from 'hooks/useGetSkill'
import CustomSelect from 'components/CustomSelect'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'

const VerifiedEditAcademicHistory = ({ id, navigate }) => {
  const { loading: historyLoading, data } = useGetSkill(id, ACADEMIC_HISTORY)

  const initialValue = {
    startingYear: '',
    endingYear: '',
    schoolName: '',
    place: '',
  }

  const [value, setValue] = useState([])
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([{ ...initialValue }])
  const onBackButtonEvent = useGetBack(value)

  const handleChange = (newValue, field, index) => {
    setValue(
      value.map((object, i) =>
        index === i ? { ...object, [field]: newValue } : object
      )
    )
  }

  const handleDelete = (index) =>
    setValue((prev) => prev.filter((_, i) => i !== index))

  useEffect(() => {
    setValue(data)
  }, [data])

  const getYears = () => {
    const startingYear = 2001
    const currentYear = new Date().getFullYear()
    let years = []
    for (let index = 1; index <= currentYear - startingYear; index++) {
      years.push(startingYear + index)
    }
    return years.reverse()
  }

  const years = getYears()

  return (
    <EditSkills
      pageTitle={translation.academicHistory}
      loading={historyLoading}
      disabled={loading}
      onUpdateClick={() =>
        updateAcademicHistory(
          value,
          setLoading,
          id,
          setErrors,
          initialValue,
          navigate,
          onBackButtonEvent
        )
      }
    >
      <>
        <SimpleEditWrapper
          value={value}
          pageTitle={translation.academicHistory}
          customized={true}
          addAnotherField={() => {
            setValue([...value, { ...initialValue }])
          }}
        >
          {value?.map((item, index) => (
            <div key={index} className='flex flex-col md:flex-row gap-2'>
              <div className='flex flex-col sm:flex-row gap-2 w-full'>
                <CustomSelect
                  label={translation.startingYear}
                  labelClasses={index === 0 ? 'block' : 'md:hidden block'}
                  options={years}
                  containerClasses='w-full'
                  value={String(item.startingYear)}
                  handleChange={(...props) =>
                    handleChange(...props, 'startingYear', index)
                  }
                />
                <CustomSelect
                  label={translation.endingYear}
                  labelClasses={index === 0 ? 'block' : 'md:hidden block'}
                  options={['heute', ...years]}
                  containerClasses='w-full'
                  value={String(item.endingYear)}
                  handleChange={(...props) =>
                    handleChange(...props, 'endingYear', index)
                  }
                />
              </div>
              <div className='flex flex-col sm:flex-row items-center gap-2 w-full'>
                <Input
                  label={translation.schoolName}
                  labelClasses={index === 0 ? 'block' : 'md:hidden block'}
                  value={item.schoolName}
                  handleChange={(...props) =>
                    handleChange(...props, 'schoolName', index)
                  }
                  placeholder='Sekundarschule Kirchbühl'
                  error={errors[index]?.schoolName ? true : false}
                  helperText={errors[index]?.schoolName}
                />
                <Input
                  label={translation.place}
                  labelClasses={index === 0 ? 'block' : 'md:hidden block'}
                  value={item.place}
                  handleChange={(...props) =>
                    handleChange(...props, 'place', index)
                  }
                  error={errors[index]?.place ? true : false}
                  helperText={errors[index]?.place}
                />
              </div>
              <div
                onClick={() => handleDelete(index)}
                className={`${index === 0 && 'mt-4'} ${
                  errors[index]?.startingYear ||
                  errors[index]?.endingYear ||
                  errors[index]?.place
                    ? `${
                        index === 0 ? 'mt-auto sm:mt-8' : 'mt-auto sm:mt-[10px]'
                      }`
                    : 'flex items-center justify-center'
                } flex sm:block items-center justify-center`}
              >
                <DeleteIcon />
              </div>
            </div>
          ))}
        </SimpleEditWrapper>
      </>
    </EditSkills>
  )
}

export default VerifiedEditAcademicHistory

import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { translation } from 'utils/germanTranslation'

export const updateHobbies = async (
  value,
  setLoading,
  id,
  setErrors,
  initialValue,
  navigate,
  onBackButtonEvent
) => {
  let validation = true
  let errors = Array.from({ length: value.length }).map(() => {
    return { ...initialValue }
  })
  value.forEach((item, index) => {
    if (!item.title) {
      errors[index].title = translation.REQUIRED_FIELD
      validation = false
    }
    if (!item.description) {
      errors[index].description = translation.REQUIRED_FIELD
      validation = false
    }
  })
  if (!validation) {
    setErrors(errors)
    return
  }
  setLoading(true)
  try {
    const { data } = await axios.post(
      BACKEND_URL + API_URL.hobbies.add,
      { value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
    window.removeEventListener('popstate', onBackButtonEvent)
    navigate(-2)
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
  }
}

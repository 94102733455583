import AddJob from 'components/Student/Jobs/Add'
import useGetJobById from 'hooks/useGetJobById'
import React from 'react'
import { useParams } from 'react-router-dom'
import { translation } from 'utils/germanTranslation'

const UpdateJob = () => {
  const { jobId } = useParams()
  const { loading, data, error } = useGetJobById(jobId)
  return (
    <div>
      {!loading && !error ? (
        <AddJob jobDetail={data} title={`Job ${translation.update}`} />
      ) : (
        error
      )}
    </div>
  )
}

export default UpdateJob

import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const REQUIRED_LANGUAGE_FIELDS = 'Bitte Feld ausfüllen oder löschen'

export const updateLanguages = async (
  value,
  setLoading,
  id,
  setErrors,
  navigate,
  onBackButtonEvent
) => {
  setLoading(true)
  setErrors([])
  let validation = true
  let errors = Array.from({ length: value.length }).map(() => ({
    language: '',
    proficiency: '',
  }))
  value.forEach((item, index) => {
    if (!item.language) {
      errors[index].language = REQUIRED_LANGUAGE_FIELDS
      validation = false
    }
    if (!item.proficiency) {
      errors[index].proficiency = REQUIRED_LANGUAGE_FIELDS
      validation = false
    }
  })

  if (!validation) {
    setLoading(false)
    setErrors(errors)
    return
  }
  try {
    const { data } = await axios.post(
      BACKEND_URL + API_URL.languages.add,
      { value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    window.removeEventListener('popstate', onBackButtonEvent)
    setLoading(false)
    navigate(-2)
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
  }
}

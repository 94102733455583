import { StyleSheet, View } from '@react-pdf/renderer';

const LineBreak = () => {
  return <View style={styles.lineBreak}></View>;
};

const styles = StyleSheet.create({
  lineBreak: {
    width: '76%',
    border: '0.5pt solid #bfbfbf',
    marginTop: 10,
    marginBottom: 15,
  },
});
export default LineBreak;

import * as React from 'react';

const TeacherClasses = () => (
  <svg width={27} height={27} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.512 19.125c-.957 0-1.924-.248-2.678-.731l-6.772-4.422a4.413 4.413 0 0 1-2.014-3.712c0-1.508.754-2.891 2.014-3.713l6.783-4.41c1.508-.978 3.86-.978 5.355.012l6.74 4.421a4.425 4.425 0 0 1 2.002 3.701c0 1.496-.754 2.88-2.003 3.701L16.2 18.394c-.753.495-1.72.73-2.688.73Zm0-16.031c-.642 0-1.283.146-1.744.46l-6.773 4.41a2.707 2.707 0 0 0-1.248 2.296c0 .945.45 1.777 1.248 2.295l6.773 4.421c.934.608 2.576.608 3.51 0l6.739-4.421a2.697 2.697 0 0 0 1.237-2.295c0-.945-.45-1.778-1.237-2.295l-6.74-4.421c-.472-.293-1.113-.45-1.765-.45Z'
      fill='#05060E'
    />
    <path
      d='M13.5 25.594c-.495 0-1.001-.068-1.406-.203L8.504 24.2c-1.698-.563-3.037-2.419-3.025-4.208l.01-5.276a.85.85 0 0 1 .845-.844.85.85 0 0 1 .843.844l-.01 5.276c0 1.058.877 2.273 1.878 2.61l3.589 1.193c.45.146 1.282.146 1.732 0l3.589-1.193c1.001-.337 1.879-1.552 1.879-2.598v-5.22a.85.85 0 0 1 .843-.844.85.85 0 0 1 .844.844v5.22c0 1.788-1.327 3.633-3.026 4.207l-3.589 1.192a5.003 5.003 0 0 1-1.406.192ZM24.075 17.719a.85.85 0 0 1-.844-.844v-6.75a.85.85 0 0 1 .844-.844.85.85 0 0 1 .844.844v6.75a.85.85 0 0 1-.844.844Z'
      fill='#05060E'
    />
  </svg>
);

export default TeacherClasses;

import React from 'react';

const PlayIcon = ({ disabled }) => {
  return (
    <svg
      className={`${disabled && 'opacity-50'}`}
      width={26}
      height={26}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.968 2.167C6.988 2.167 2.134 7.02 2.134 13s4.854 10.833 10.834 10.833C18.948 23.833 23.8 18.98 23.8 13S18.958 2.166 12.968 2.166Zm3.25 13.249-3.142 1.809a2.475 2.475 0 0 1-2.492 0 2.475 2.475 0 0 1-1.246-2.167V11.43c0-.9.466-1.712 1.246-2.167a2.465 2.465 0 0 1 2.503 0l3.141 1.81a2.475 2.475 0 0 1 1.246 2.166 2.48 2.48 0 0 1-1.256 2.178Z'
        fill={disabled ? '#000000' : '#FF0100'}
      />
    </svg>
  );
};

export default PlayIcon;

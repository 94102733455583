import * as React from 'react';

const InterlockingTestIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.167 18.033H5.834a4.798 4.798 0 0 1-4.792-4.792V5.259c0-1.083.5-1.458.8-1.583.3-.125.917-.217 1.683.55l2.159 2.158a.201.201 0 0 0 .291 0l2.992-2.991c.55-.55 1.517-.55 2.058 0l2.992 2.991a.201.201 0 0 0 .292 0l2.158-2.158c.767-.767 1.383-.667 1.683-.55.3.125.8.492.8 1.583v7.992c.009 2.858-1.916 4.783-4.783 4.783ZM2.342 4.866a1.18 1.18 0 0 0-.05.392v7.992a3.55 3.55 0 0 0 3.542 3.541h8.333c2.15 0 3.542-1.391 3.542-3.541V5.258c0-.2-.025-.317-.05-.383-.067.033-.167.1-.309.242l-2.158 2.158c-.55.55-1.517.55-2.058 0l-2.992-2.992a.201.201 0 0 0-.292 0L6.867 7.266c-.55.55-1.517.55-2.058 0L2.65 5.108a1.421 1.421 0 0 0-.308-.241Z'
      fill='#05060E'
    />
  </svg>
);

export default InterlockingTestIcon;

import { toast } from 'react-toastify';

export const ShowToast = ({ type = 'error', message }) => {
  const showToastByType = () => {
    const error = type === 'error' ? message : '';
    const toastMessage =
      type === 'error'
        ? error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          error
        : message;
    toast[type](toastMessage, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  };
  return <button onClick={showToastByType()}></button>;
};

import * as React from "react"

const CakeIcon = () => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.334 18.959H1.667a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h16.667a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z"
      fill="#05060E"
    />
    <path
      d="M17.409 18.959a.63.63 0 0 1-.625-.625v-7.5c0-1.034-.967-1.875-2.15-1.875H5.367c-1.184 0-2.15.841-2.15 1.875v7.5a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625v-7.5c0-1.726 1.525-3.125 3.4-3.125h9.258c1.875 0 3.4 1.4 3.4 3.125v7.5a.617.617 0 0 1-.616.625Z"
      fill="#05060E"
    />
    <path
      d="M15.367 8.958a.63.63 0 0 1-.625-.625V5.975c0-.65-.625-1.183-1.383-1.183H6.65c-.766 0-1.383.533-1.383 1.183v2.358a.63.63 0 0 1-.625.625.637.637 0 0 1-.633-.625V5.975c0-1.342 1.183-2.433 2.633-2.433h6.708c1.45 0 2.634 1.091 2.634 2.433v2.358a.617.617 0 0 1-.617.625ZM14.441 14.792a1.75 1.75 0 0 1-1.75-1.75v-.259c0-.275-.225-.5-.5-.5s-.5.225-.5.5v.259a1.75 1.75 0 1 1-3.5 0v-.259c0-.275-.225-.5-.5-.5s-.5.225-.5.5v.259a1.75 1.75 0 1 1-3.5 0v-.275a.505.505 0 0 0-.491-.5H2.89a.63.63 0 0 1-.616-.634.628.628 0 0 1 .625-.616h.316c.95.016 1.725.8 1.725 1.75v.275c0 .275.225.5.5.5s.5-.225.5-.5v-.259a1.75 1.75 0 1 1 3.5 0v.259c0 .275.225.5.5.5s.5-.225.5-.5v-.259a1.75 1.75 0 1 1 3.5 0v.259c0 .275.225.5.5.5s.5-.225.5-.5v-.259c0-.966.784-1.75 1.75-1.75h.375a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625h-.375c-.275 0-.5.225-.5.5v.259a1.75 1.75 0 0 1-1.75 1.75Z"
      fill="#292D32"
    />
    <path
      d="M6.667 4.792a.63.63 0 0 1-.625-.625V2.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v1.667a.63.63 0 0 1-.625.625ZM13.333 4.792a.63.63 0 0 1-.625-.625V2.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v1.667a.63.63 0 0 1-.625.625ZM10 4.792a.63.63 0 0 1-.625-.625v-2.5A.63.63 0 0 1 10 1.042a.63.63 0 0 1 .625.625v2.5a.63.63 0 0 1-.625.625Z"
      fill="#292D32"
    />
  </svg>
)

export default CakeIcon

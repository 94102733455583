import axios from 'axios'
import { BACKEND_URL, API_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'

export const handleDeleteStudent = async (
  classId,
  studentId,
  loggedInUserId,
  modalData,
  refetch,
  navigate
) => {
  try {
    const { data } = await axios.post(
      BACKEND_URL + API_URL.classes.deleteStudentsFromClass,
      {
        classId,
        studentId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    if (data?.success) {
      if (modalData.id === loggedInUserId) {
        navigate(-1)
      } else {
        refetch()
        ShowToast('success', `${modalData.name} removed successfully`)
      }
    }
  } catch (error) {
    ShowToast({ type: 'error', message: error?.message || String(error) })
  }
}

import React, { useState, useEffect } from 'react';
import EditSkills from 'components/EditSkills';
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper';
import Input from 'components/Input';
import DeleteIcon from 'assets/icons/delete';
import NothingToShow from '../NothingToShow';
import useGetReferences from 'hooks/useGetReferences';
import { updateReferences } from './updateReferences';
import VerifyEditAccess from 'components/VerifyEditAccess';
import NoAccess from 'components/NoAccess';

const EditReferences = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate) =>
        canEdit ? (
          <VerifiedEditReferences id={id} navigate={navigate} />
        ) : (
          <NoAccess />
        )
      }
    </VerifyEditAccess>
  );
};

const VerifiedEditReferences = ({ id, navigate }) => {
  const { data, loading: referencesLoading } = useGetReferences(id);

  const initialState = {
    firstName: '',
    lastName: '',
    designation: '',
    contactNumber: '',
    email: '',
  };

  const [value, setValue] = useState([initialState]);
  const [loading, setLoading] = useState(false);

  const handleChange = (newValue, field, index) => {
    setValue(
      value.map((object, i) =>
        index === i ? { ...object, [field]: newValue } : object
      )
    );
  };

  useEffect(() => {
    if (data.length) setValue(data);
  }, [data]);

  return (
    <EditSkills
      pageTitle='Update References'
      onUpdateClick={() => updateReferences(value, setLoading, id, navigate)}
    >
      <SimpleEditWrapper
        pageTitle='References'
        customized={true}
        addAnotherField={() => {
          setValue([...value, initialState]);
        }}
        disabled={loading || referencesLoading}
      >
        {value.length > 0 ? (
          value.map((item, index) => (
            <div
              key={index}
              className='flex items-start lg:items-center flex-col lg:flex-row gap-2'
            >
              <div className='flex gap-2 flex-col sm:flex-row w-full lg:w-auto'>
                <Input
                  labelClasses={index === 0 ? 'block' : 'sm:hidden block'}
                  label='FirstName'
                  value={item.firstName}
                  handleChange={(...props) =>
                    handleChange(...props, 'firstName', index)
                  }
                />
                <Input
                  label='Last Name'
                  labelClasses={index === 0 ? 'block' : 'sm:hidden block'}
                  value={item.lastName}
                  handleChange={(...props) =>
                    handleChange(...props, 'lastName', index)
                  }
                />
              </div>
              <div className='flex gap-2 flex-col sm:flex-row w-full lg:w-auto'>
                <Input
                  label='Designation'
                  labelClasses={index === 0 ? 'block' : 'sm:hidden block'}
                  value={item.designation}
                  handleChange={(...props) =>
                    handleChange(...props, 'designation', index)
                  }
                />
                <Input
                  label='Contact Number'
                  labelClasses={index === 0 ? 'block' : 'sm:hidden block'}
                  value={item.contactNumber}
                  type='tel'
                  handleChange={(...props) =>
                    handleChange(...props, 'contactNumber', index)
                  }
                />
              </div>
              <div className='grid grid-cols-none sm:grid-cols-2 w-full lg:flex lg:w-[350px] '>
                <Input
                  label='Email'
                  labelClasses={index === 0 ? 'block' : 'sm:hidden block'}
                  value={item.email}
                  type='email'
                  handleChange={(...props) =>
                    handleChange(...props, 'email', index)
                  }
                />
              </div>
              <div
                className={`flex items-center justify-center w-full lg:w-auto ${
                  index === 0 && 'pt-0 sm:pt-5'
                }`}
                onClick={() =>
                  setValue([
                    ...value.slice(0, index),
                    ...value.slice(index + 1),
                  ])
                }
              >
                <DeleteIcon />
              </div>
            </div>
          ))
        ) : (
          <NothingToShow />
        )}
      </SimpleEditWrapper>
    </EditSkills>
  );
};

export default EditReferences;

import * as React from 'react'

const DownloadCvIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={17}
    height={21}
    viewBox='0 0 194 266'
  >
    <path d='M11 2.1c-3.2 1.3-7.8 5.6-9.6 9.1C.2 13.5 0 32.6 0 134.2v120.3l2.3 3.8c1.3 2.1 3.6 4.7 5.3 5.7 2.8 1.9 5.5 2 89.6 2h86.7l3.6-3.1c6.9-6.1 6.5 1.1 6.5-104.1V63.5l-31.3-31.3L131.5 1l-59 .1c-36.6 0-59.9.4-61.5 1zm112.2 33.6c.3 24.8.6 25.9 6.5 31.5 4.7 4.4 7.1 4.8 30.9 4.8H183l-.2 90.1c-.3 87.8-.3 90.1-2.2 91.5-1.7 1.2-14.7 1.4-83.8 1.4-80.5 0-81.8 0-83.8-2s-2-3.3-2-119 0-117 2-119 3.3-2 55.9-2h53.9l.4 22.7zm32.3 5.3 19 19h-17.8c-23.3 0-21.7 1.5-21.7-21 0-9.4.3-17 .8-17 .4 0 9.3 8.5 19.7 19z' />
    <path d='M92.7 97.8c-1.5 1.7-1.7 6.1-1.7 42.5 0 22.4-.2 40.7-.4 40.7s-4.5-4.3-9.7-9.5c-9.3-9.5-12-11.2-15.7-10.1-2.5.8-4.1 4.5-3.2 7.1.5 1.1 8 9.1 16.7 17.7C90.2 197.6 95.3 202 97 202c1.7 0 6.8-4.4 18.7-16.2 17.1-17 18.6-19.4 14.7-23.2-3.4-3.5-6.2-2-17 8.6L103 181.4v-40.5c0-35-.2-40.8-1.6-42.7-1.9-2.7-6.4-2.9-8.7-.4z' />
    <path d='M37.2 203.6c-2.1 1.4-2.2 2.3-2.2 12.7 0 11.9.3 12.7 3.7 14.6 3.1 1.6 113.5 1.6 116.6 0 3.4-1.9 3.7-2.7 3.7-14.2 0-9.4-.2-10.9-2-12.7-2.5-2.5-5.5-2.5-8 0-1.6 1.6-2 3.3-2 9v7H47v-7c0-5.7-.4-7.4-2-9-2.4-2.4-4.8-2.5-7.8-.4z' />
  </svg>
)

export default DownloadCvIcon

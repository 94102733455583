import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import userImage from 'assets/images/blank_user.png'
import TrashIcon from 'assets/icons/trash'
import CustomTable from 'components/Table'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { API_URL, BACKEND_URL, FRONTEND_URL } from 'utils/apiConstants'
import useGetClassDetails from 'hooks/useGetClassDetails'
import axios from 'axios'
import { ROUTES } from 'utils/routes'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import { ShowToast } from 'utils/toastMessages'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'
import CustomInput from 'components/Input'
import EditIcon from 'assets/icons/edit'
import ChangeSchoolDetailModal from './ChangeSchoolDetailModal'
import { useEffect } from 'react'
import _ from 'lodash'
import Tag from 'components/Tag'
import AddIcon from 'assets/icons/add'
import { Close } from '@mui/icons-material'
import { translation } from 'utils/germanTranslation'
import classCodeInvitationExample from 'assets/images/class-code-invitation-example.png'
import FullscreenIcon from 'assets/icons/fullscreen'
import { handleDeleteStudent } from './utils'

export const EditSchool = 'edit-school'
export const CopyLink = 'copy-link'
export const ShowInvitationCodeFullScreen = 'show-invitation-code-full-screen'

const ClassDetails = () => {
  const { classId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, refetch } = useGetClassDetails(classId)
  const navigate = useNavigate()
  const loggedInUser = useSelector(user)
  const [value, setValue] = useState({
    className: '',
    schoolName: '',
    schoolPlace: '',
    schoolZipCode: '',
  })
  const [loadingInvitationLink, setLoadingInvitationLink] = useState(true)
  const [invitationCode, setInvitationLink] = useState('')

  const [modalData, setModalData] = useState({
    open: false,
    name: '',
    id: '',
  })
  const [adminModalData, setAdminModalData] = useState({
    open: false,
    email: '',
  })

  const navigateToAllClasses = () => {
    navigate(ROUTES({ userId: loggedInUser.id }).teacher.classes.all)
  }

  const handleOpenModal = (id, userName) => {
    setModalData({
      open: true,
      name: userName,
      id,
    })
  }

  const handleCloseModal = (id, userName) => {
    setModalData({
      open: false,
      name: '',
      id: '',
    })
  }

  const handleDelete = () => {
    handleDeleteStudent(
      classId,
      modalData.id,
      loggedInUser.id,
      modalData,
      refetch,
      navigate
    )
    setModalData({
      open: false,
      name: '',
      id: '',
    })
  }

  const getClassInvitationCode = async () => {
    setLoadingInvitationLink(true)
    try {
      const { data } = await axios.get(
        BACKEND_URL + API_URL.classes.shareCLass + classId,
        {
          classId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        setInvitationLink(data.code)
        setLoadingInvitationLink(false)
        if (searchParams.get(EditSchool) !== 'true')
          setSearchParams({ [CopyLink]: true })
      }
    } catch (error) {
      ShowToast({ message: error })
      setLoadingInvitationLink(false)
    }
  }
  useEffect(() => {
    if (searchParams.get(CopyLink) === 'true') getClassInvitationCode()
  }, [searchParams])

  const handleAddSecondAdmin = async (e) => {
    e.preventDefault()
    if (!adminModalData.email) {
      return ShowToast({ message: 'Please enter email' })
    }
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.classes.addSecondAdmin,
        {
          classId,
          secondAdminEmail: adminModalData.email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        ShowToast({
          type: 'success',
          message: translation.SECOND_ADMIN_ADDED_SUCCESSFULLY,
        })
        refetch()
        setAdminModalData({
          open: false,
          email: '',
        })
      } else {
        ShowToast({ type: 'error', message: data })
      }
    } catch (err) {
      ShowToast({ type: 'error', message: err })
    }
  }

  const handleEditSchool = async () => {
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.classes.updateSchoolDetails,
        {
          ...value,
          classId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        ShowToast({ type: 'success', message: data.message })
        setSearchParams({ [EditSchool]: false })
        refetch()
      }
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const adminsId = [data?.firstAdmin, data?.secondAdmin]

  useEffect(() => {
    if (data)
      setValue({
        className: data.className,
        schoolName: data.schoolName,
        schoolPlace: data.schoolPlace,
        schoolZipCode: data.schoolZipCode,
      })
  }, [data])

  return (
    <div>
      <Box className='max-w-[1760px] px-4 sm:px-8 w-full bg-softWhite py-10 flex flex-col gap-6 min-h-screen'>
        <Button
          title={translation.back}
          titleClasses='text-[#458EFF] text-lg font-semibold'
          containerClasses='rounded-md w-[118px] h-[60px] bg-[#E9F1FC] mt-4 xs:mt-2'
          onClick={navigateToAllClasses}
        />
        <div className='flex items-center gap-4'>
          <h1 className='text-2xl font-bold'>{data?.className}</h1>
          <Button
            title='Klassencode generieren'
            containerClasses='text-xs bg-blue-400 text-white p-2 py-1 rounded-full font-medium cursor-pointer'
            onClick={getClassInvitationCode}
          />
          {/* </div> */}
          <IconButton
            onClick={() =>
              setSearchParams({
                [EditSchool]: true,
              })
            }
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className='flex gap-4 items-center'>
          <h1>
            <span className='font-bold'>Schule: </span>
            {[data?.schoolName, data?.schoolPlace, data?.schoolZipCode]
              .filter(Boolean)
              .join(', ')}
          </h1>
        </div>
        <div className='flex items-center justify-between'>
          <h1 className='text-2xl font-bold'>Admins</h1>
          {data?.admins?.length === 1 && (
            <Button
              title='Zweiten Administrator hinzufügen'
              containerClasses='text-xs bg-blue-400 text-white p-2 py-1 rounded-full font-medium cursor-pointer'
              onClick={() => {
                setAdminModalData({
                  open: true,
                })
              }}
            />
          )}
        </div>
        <CustomTable
          data={data?.admins}
          headerTemplate={
            <TableHead className='bg-[#F0F2F9]'>
              <TableRow>
                <TableCell style={{ minWidth: '9rem', width: '44rem' }}>
                  Admin
                </TableCell>
                <TableCell style={{ minWidth: '9rem' }}>Email ID</TableCell>
                <TableCell style={{ width: '9rem' }}>
                  {translation.actions}
                </TableCell>
              </TableRow>
            </TableHead>
          }
          rowTemplate={(row) => (
            <TableRow key={row?.id}>
              <TableCell>
                <div className='flex items-center'>
                  <div>
                    <img
                      src={
                        row?.avatar
                          ? `${BACKEND_URL}/${row?.avatar}`
                          : userImage
                      }
                      className='object-cover rounded-full border-8 border-white w-[60px] h-[60px]'
                      alt='user'
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className='flex items-center gap-2'>
                    <p>{row?.firstName + ' ' + row?.lastName}</p>
                    <p className='bg-blue-700 text-white rounded-full p-1 px-2 text-xs select-none'>
                      {row?.id === data?.firstAdmin
                        ? 'Erster Admin'
                        : 'Zweite Admin'}
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell className='py-2'>
                {(loggedInUser.id === data?.firstAdmin ||
                  row?.id === data?.secondAdmin) &&
                  adminsId.includes(loggedInUser?.id) &&
                  data?.admins?.length === 2 && (
                    <div
                      className='w-11 rounded-lg flex items-center justify-center h-full '
                      onClick={() =>
                        handleOpenModal(
                          row?.id,
                          `${row?.firstName + ' ' + row?.lastName}`
                        )
                      }
                    >
                      <TrashIcon containerClasses='cursor-pointer' />
                    </div>
                  )}
              </TableCell>
            </TableRow>
          )}
        />
        <h1 className='text-2xl font-bold'>
          Lehrpersonen
          <span className='opacity-40'> ({data?.teachers?.length || 0})</span>
        </h1>
        <CustomTable
          data={data?.teachers}
          headerTemplate={
            <TableHead className='bg-[#F0F2F9]'>
              <TableRow>
                <TableCell style={{ minWidth: '9rem', width: '44rem' }}>
                  Name Lehrperson
                </TableCell>
                <TableCell style={{ minWidth: '9rem' }}>Email ID</TableCell>
                <TableCell style={{ width: '9rem' }}>
                  {translation.actions}
                </TableCell>
              </TableRow>
            </TableHead>
          }
          rowTemplate={(row) => (
            <TableRow key={row?.id}>
              <TableCell>
                <div className='flex items-center'>
                  <div>
                    <img
                      src={
                        row?.avatar
                          ? `${BACKEND_URL}/${row?.avatar}`
                          : userImage
                      }
                      className='object-cover rounded-full border-8 border-white w-[60px] h-[60px]'
                      alt='user'
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className='flex items-center gap-2'>
                    <p>{row?.firstName + ' ' + row?.lastName}</p>
                    {row?.id === data?.admin && (
                      <p className='bg-blue-700 text-white rounded-full p-1 px-2 text-xs select-none'>
                        Admin
                      </p>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell className='py-2'>
                {adminsId.includes(loggedInUser?.id) && (
                  <div
                    className='w-11 rounded-lg flex items-center justify-center h-full '
                    onClick={() =>
                      handleOpenModal(
                        row?.id,
                        `${row?.firstName + ' ' + row?.lastName}`
                      )
                    }
                  >
                    <TrashIcon containerClasses='cursor-pointer' />
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
        />
        <Tag
          icon={<AddIcon />}
          showBorder={false}
          titleClasses='text-sm font-semibold'
          title={`${translation.teacher} ${translation.add.toLowerCase()}`}
          onClick={() =>
            navigate(
              ROUTES({ userId: loggedInUser?.id, classId }).teacher.classes.add
                .teachers
            )
          }
        />
        <div className='flex items-center gap-4'>
          <h1 className='text-2xl font-bold'>
            Schüler*innen
            <span className='opacity-40'> ({data?.students?.length || 0})</span>
          </h1>
          <p className='text-sm'>
            Schüler*in anklicken, um in das jeweilige Profil zu gelangen
          </p>
        </div>
        <CustomTable
          data={data?.students}
          headerTemplate={
            <TableHead className='bg-[#F0F2F9]'>
              <TableRow>
                <TableCell style={{ minWidth: '9rem', width: '44rem' }}>
                  Name Schüler*in
                </TableCell>
                <TableCell style={{ minWidth: '9rem' }}>Email ID</TableCell>
                <TableCell style={{ width: '9rem' }}>
                  {translation.actions}
                </TableCell>
              </TableRow>
            </TableHead>
          }
          rowTemplate={(row) => (
            <TableRow key={row?.id}>
              <TableCell>
                <div className='flex items-center'>
                  <Link to={ROUTES({ userId: row?.id }).student.dashboard}>
                    <div>
                      <img
                        src={
                          row?.avatar
                            ? `${BACKEND_URL}/${row?.avatar}`
                            : userImage
                        }
                        className='object-cover rounded-full border-8 border-white w-[60px] h-[60px]'
                        alt='user'
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  </Link>
                  <Link
                    to={
                      ROUTES({ userId: row?.id, className: data.className })
                        .student.dashboard
                    }
                  >
                    <p className='hover:underline underline-offset-4 transition-all'>
                      {row?.firstName + ' ' + row?.lastName}
                    </p>
                  </Link>
                </div>
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell className='py-2'>
                {adminsId?.includes(loggedInUser?.id) && (
                  <div
                    className='object-cover rounded-full border-8 border-white w-[60px] h-[60px]'
                    onClick={() =>
                      handleOpenModal(
                        row?.id,
                        `${row?.firstName + ' ' + row?.lastName}`
                      )
                    }
                  >
                    <TrashIcon containerClasses='cursor-pointer' />
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
        />
        <Tag
          title={`Schüler*in mit Klassencode hinzufügen`}
          icon={<AddIcon />}
          showBorder={false}
          titleClasses='text-sm font-semibold'
          onClick={getClassInvitationCode}
        />
      </Box>

      {/* CustomModal to change the school information */}
      <CustomModal
        open={
          searchParams.get(EditSchool) === 'true' ||
          searchParams.get(CopyLink) === 'true'
        }
        handleClose={() =>
          setSearchParams({
            [EditSchool]: 'false',
            [CopyLink]: 'false',
          })
        }
        padding='0'
        maxWidth='450px'
      >
        {searchParams.get(EditSchool) === 'true' ? (
          <ChangeSchoolDetailModal
            value={value}
            setValue={setValue}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            onSubmit={handleEditSchool}
          />
        ) : searchParams.get(CopyLink) === 'true' ? (
          <div className='text-softBlack'>
            <h1 className='text-center text-gray-500 font-bold text-xl border-b border-yellow-400 py-4'>
              Klassencode generieren
            </h1>
            <div className='flex items-center justify-center flex-col text-center gap-4 py-8 px-4'>
              <h1 className='text-3xl text-softBlack font-bold flex items-center'>
                Klassencode*:{' '}
                {loadingInvitationLink ? (
                  <CircularProgress size={26} />
                ) : (
                  invitationCode
                )}
                <FullscreenIcon
                  className='ml-3 w-8 cursor-pointer hover:scale-105 transition-all'
                  onClick={() =>
                    setSearchParams({
                      [ShowInvitationCodeFullScreen]: 'true',
                    })
                  }
                />
              </h1>
              <Button
                onClick={getClassInvitationCode}
                title='neuen Code generieren'
                containerClasses='text-xs bg-blue-400 text-white p-2 py-1 rounded-full font-medium cursor-pointer'
              />
              <div className='text-xs'>
                <p>*Code verfällt nach 24 Stunden</p>
                <p>
                  Neue/r Schüler*in hinzufügen - neuer Code muss generiert
                  werden
                </p>
                <p>
                  Dies hat keine Auswirkungen auf bereits angemeldete
                  Schüler*innen
                </p>
              </div>
              <div className='h-1 border-t-2 border-black w-4/5 my-3'></div>
              <h1 className='font-bold text-sm'>
                Schüler*innen geben den Klassencode im eigenen Profil ein
              </h1>

              <img
                src={classCodeInvitationExample}
                alt='class code invitation example'
                width={380}
              />
            </div>
          </div>
        ) : null}
      </CustomModal>

      <CustomModal
        open={modalData.open}
        handleClose={handleCloseModal}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Möchten Sie „
            <span className='text-blue-600 font-bold'> {modalData.name} </span>“
            wirklich aus dieser Klasse entfernen?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={() => handleDelete()}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={() => handleCloseModal()}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={adminModalData.open}
        handleClose={() => setAdminModalData({ open: false, email: 'null' })}
      >
        <form onSubmit={handleAddSecondAdmin}>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Bitte geben Sie die E-Mail-Adresse des zweiten Administrators ein:
          </p>
          <CustomInput
            label='Email'
            value={adminModalData.email}
            onChange={(newValue) =>
              setAdminModalData({
                ...adminModalData,
                email: newValue.target.value,
              })
            }
          />
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.add}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
              type='submit'
            />
            <Button
              title={translation.cancel}
              onClick={() =>
                setAdminModalData({ ...adminModalData, open: false })
              }
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
              type='button'
            />
          </div>
        </form>
      </CustomModal>

      <CustomModal
        open={searchParams.get(ShowInvitationCodeFullScreen) === 'true'}
        handleClose={() =>
          setSearchParams({
            [ShowInvitationCodeFullScreen]: 'false',
          })
        }
        maxWidth='100vw'
        maxHeight='100vh'
        height='100%'
      >
        <div className='flex flex-col items-center text-softBlack h-full gap-4'>
          <Close
            className='ml-auto cursor-pointer'
            fontSize='large'
            onClick={() =>
              setSearchParams({
                [ShowInvitationCodeFullScreen]: 'false',
              })
            }
          />
          <h1 className='text-4xl font-bold text-center mt-8'>
            Klassencode in deinem Profil eingeben
          </h1>
          <img
            src={classCodeInvitationExample}
            alt='class code invitation example'
            width={480}
          />
          <h1 className='text-9xl font-bold center'>{invitationCode}</h1>
        </div>
      </CustomModal>
    </div>
  )
}

export default ClassDetails

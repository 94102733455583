import { CircularProgress } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import VerifiedTeacherClasses from './VerifiedTeacherClasses'

const Classes = () => {
  const [loading, setLoading] = useState(true)
  const [isTeacher, setIsTeacher] = useState(false)
  const id = localStorage.getItem('id')
  const verifyUser = async () => {
    try {
      const { data } = await axios.get(
        BACKEND_URL + API_URL.user.verifyTeacher,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data.success) setIsTeacher(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    verifyUser()
  }, [])

  return (
    <div>
      {loading ? (
        <div className='h-screen flex justify-center items-center'>
          <CircularProgress />
        </div>
      ) : isTeacher ? (
        <VerifiedTeacherClasses userId={id} />
      ) : (
        <div className='text-center'>
          Sie sind nicht berechtigt, diese Seite anzuzeigen.
        </div>
      )}
    </div>
  )
}

export default Classes

import * as React from 'react';

const DeleteIcon = ({ ...props }) => (
  <svg
    width={24}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='cursor-pointer'
    {...props}
  >
    <path
      d='M12 2.5c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10Zm3.36 12.3c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-2.3-2.3-2.3 2.3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l2.3-2.3-2.3-2.3a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 2.3-2.3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.3 2.3 2.3 2.3Z'
      fill='#B0BEC5'
    />
  </svg>
);

export default DeleteIcon;

import axios from 'axios';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetReferences = (userId) => {
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUserData = useCallback(async () => {
    const reqHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      id: userId,
    };
    try {
      const { data } = await axios.get(
        BACKEND_URL + API_URL.student.references,
        {
          headers: reqHeaders,
        }
      );
      const references = JSON.parse(data?.data);
      setValue(references);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error.message,
        {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        }
      );
    }
  }, [userId]);

  useEffect(() => {
    getUserData();
  }, [userId, getUserData]);

  return {
    referencesLoading: loading,
    data: value,
    refetch: getUserData,
  };
};

export default useGetReferences;

import * as React from 'react';

const TrashIcon = ({ containerClasses, onClick = () => {} }) => (
  <div className={containerClasses} onClick={onClick}>
    <svg width={40} height={40} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29 14.73h-.08c-5.29-.53-10.57-.73-15.8-.2l-2.04.2a.755.755 0 0 1-.83-.68c-.04-.42.26-.78.67-.82l2.04-.2c5.32-.54 10.71-.33 16.11.2.41.04.71.41.67.82a.74.74 0 0 1-.74.68Z'
        fill='#05060E'
      />
      <path
        d='M16.5 13.72c-.04 0-.08 0-.13-.01a.753.753 0 0 1-.61-.86l.22-1.31c.16-.96.38-2.29 2.71-2.29h2.62c2.34 0 2.56 1.38 2.71 2.3l.22 1.3c.07.41-.21.8-.61.86-.41.07-.8-.21-.86-.61l-.22-1.3c-.14-.87-.17-1.04-1.23-1.04H18.7c-1.06 0-1.08.14-1.23 1.03l-.23 1.3a.75.75 0 0 1-.74.63ZM23.21 30.75h-6.42c-3.49 0-3.63-1.93-3.74-3.49l-.65-10.07c-.03-.41.29-.77.7-.8.42-.02.77.29.8.7l.65 10.07c.11 1.52.15 2.09 2.24 2.09h6.42c2.1 0 2.14-.57 2.24-2.09l.65-10.07c.03-.41.39-.72.8-.7.41.03.73.38.7.8l-.65 10.07c-.11 1.56-.25 3.49-3.74 3.49Z'
        fill='#05060E'
      />
      <path
        d='M21.66 25.25h-3.33c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.33c.41 0 .75.34.75.75s-.34.75-.75.75Z'
        fill='#05060E'
      />
      <path
        d='M22.5 21.25h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75Z'
        fill='#292D32'
      />
      <rect x={0.5} y={0.5} width={39} height={39} rx={11.5} stroke='#99AECF' />
    </svg>
  </div>
);

export default TrashIcon;

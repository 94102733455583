import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import Button from 'components/Button'
import { getLetterTitle } from 'components/Student/MotivationLetter/RowTemplate'
import React from 'react'
import { translation } from 'utils/germanTranslation'

const SelectLetterScreen = ({
  letters,
  setValue,
  setSelectLetter,
  value,
  user,
}) => {
  const lettersWithCompanyName = [...letters]
    ?.filter((letter) => Boolean(letter.company.name))
    .sort((a, b) => a?.company?.name.localeCompare(b?.company?.name))

  const lettersWithoutCompanyName = letters?.filter(
    (letter) => !Boolean(letter.company.name)
  )

  return (
    <div className='bg-softWhite p-4 rounded-lg'>
      <h1 className='font-bold'>
        Wähle ein Motivationsschreiben aus, welches im Anhang hinzugefügt wird
      </h1>
      <div className='overflow-auto h-96'>
        {letters.length
          ? [...lettersWithCompanyName, ...lettersWithoutCompanyName].map(
              (letter) => (
                <FormGroup key={letter?.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          value.isMotivationLetterAttached === letter?.id
                        }
                        onChange={() => {
                          setValue({
                            ...value,
                            isMotivationLetterAttached: letter?.id,
                          })
                          setSelectLetter(false)
                        }}
                      />
                    }
                    label={getLetterTitle(letter, user)}
                    className='break-words'
                  />
                </FormGroup>
              )
            )
          : translation.NO_MOTIVATION_LETTERS_AVAILABLE}
      </div>
      <Button
        title={translation.back}
        onClick={() => setSelectLetter(false)}
        containerClasses='bg-blue-200 px-4 py-2 rounded-lg mt-3'
      />
    </div>
  )
}

export default SelectLetterScreen

import jwtDecode from 'jwt-decode'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomModal from './CustomModal'
import CustomInput from './Input'
import Button from './Button'
import axios from 'axios'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'
import { ROUTES } from 'utils/routes'
import { translation } from 'utils/germanTranslation'
import { CircularProgress } from '@mui/material'

const ResetPassword = () => {
  const [decodedToken, setDecodedToken] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { token } = useParams()
  const [value, setValue] = useState({
    password: '',
    confirmPassword: '',
  })

  const validateToken = async (id, token) => {
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.user.validateResetPasswordToken,
        { id, token }
      )
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(true)
      ShowToast({ message: error })
    }
  }

  useEffect(() => {
    setLoading(true)
    try {
      const decodedToken = jwtDecode(token)
      validateToken(decodedToken.id, token)
      setDecodedToken(decodedToken)
    } catch (error) {
      setLoading(false)
      setError(true)
      ShowToast({ message: error })
    }
  }, [token])

  const handleChange = (newValue, key) => {
    setValue((prevValue) => ({
      ...prevValue,
      [key]: newValue,
    }))
  }

  const setNewPassword = async (e) => {
    e.preventDefault()
    const { password, confirmPassword } = value
    if (password !== confirmPassword) {
      ShowToast({ message: 'Password and Confirm Password do not match' })
      return
    }
    try {
      const { data } = await axios.post(BACKEND_URL + API_URL.setNewPassword, {
        password,
        userId: decodedToken?.id,
      })
      ShowToast({ type: 'success', message: data.message })
      navigate(ROUTES().login)
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  return (
    <div>
      <CustomModal open={true} containerClasses='w-[90%]'>
        {!loading ? (
          <>
            <h1 className='text-center'>
              Setze ein neues Passwort und bestätige dieses und klicke auf
              speichern
            </h1>
            <h1 className='text-center mb-4 font-bold '>
              {decodedToken?.email}
            </h1>
            <CustomInput
              label='Neues Passwort'
              type='password'
              labelClasses='font-bold text-black'
              value={value.password}
              handleChange={(newValue) => handleChange(newValue, 'password')}
              required
              disabled={error}
            />
            <CustomInput
              containerClasses='mt-4'
              label='Bestätige das neue Passwort'
              type='password'
              labelClasses='font-bold text-black'
              value={value.confirmPassword}
              handleChange={(newValue) =>
                handleChange(newValue, 'confirmPassword')
              }
              disabled={error}
              required
            />
            <Button
              title={translation.submit}
              disabled={
                !value.password ||
                !value.confirmPassword ||
                value.password !== value.confirmPassword ||
                error
              }
              onClick={setNewPassword}
              type='button'
              containerClasses='bg-[#458EFF] text-white w-[120px] rounded-md h-[40px] m-auto mt-4'
            />
          </>
        ) : (
          <CircularProgress />
        )}
      </CustomModal>
    </div>
  )
}

export default ResetPassword

import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useGetBannersCount = () => {
  const [value, setValue] = useState()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getBannersCount()
  }, [])

  const getBannersCount = async () => {
    try {
      const { data } = await axios.get(BACKEND_URL + API_URL.count.banners, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      setValue(data.count)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError('Network Error')
    }
  }

  return { loading, data: value, refetch: getBannersCount, error }
}

export default useGetBannersCount

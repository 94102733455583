import EditIcon from 'assets/icons/edit'
import React from 'react'
import PhoneIcon from 'assets/icons/phone'
import MailIcon from 'assets/icons/mail'
import LocationIcon from 'assets/icons/location'
import CakeIcon from 'assets/icons/cake'
import { Link } from 'react-router-dom'
import { ROUTES } from 'utils/routes'
import { Skeleton } from '@mui/material'
import moment from 'moment'
import { ArrowBack } from '@mui/icons-material'
import useGetReferences from 'hooks/useGetReferences'
import { translation } from 'utils/germanTranslation'
import { NoSiblings } from 'utils'
import { format, parseISO } from 'date-fns'

const PersonalDetails = ({
  id,
  canEdit,
  toggleProfile,
  setToggleProfile,
  personalData,
  loading,
}) => {
  const { data: references, referencesLoading } = useGetReferences(id)

  return (
    <div
      className={`${
        toggleProfile && 'toggleProfile'
      } translate-x-full md:transform-none transition-all ease-out z-[101] fixed md:static right-0 w-full md:w-1/2 lg:w-[30%] h-full overflow-y-scroll md:overflow-y-hidden bg-[#E9F1FC] flex flex-col gap-8 sm:gap-4 2xl:gap-8 items-center py-8 px-8 md:pt-4 2xl:pt-8`}
    >
      <div className='flex justify-between items-center w-full'>
        <div
          className='flex items-center gap-2 cursor-pointer'
          onClick={() => setToggleProfile(false)}
        >
          <ArrowBack className='md:hidden block' />
          <p className='text-base md:text-lg 2xl:text-xl font-bold'>
            {translation.personalInformation}
          </p>
        </div>
        {canEdit && (
          <Link to={ROUTES({ userId: id }).student.edit.personalInfo}>
            <EditIcon />
          </Link>
        )}
      </div>
      <div className='flex flex-col gap-2 border-b border-[#CEE0FD] pb-4 w-full'>
        <div className='flex items-center gap-3'>
          <PhoneIcon />

          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.phone ? (
            <a href={`tel:${personalData?.phone}`} className='w-full'>
              {personalData?.phone}
            </a>
          ) : (
            ''
          )}
        </div>
        <div className='flex items-center gap-3'>
          <div>
            <img
              src='https://cdn-icons-png.flaticon.com/512/4697/4697023.png'
              alt=''
              width='20'
            />
          </div>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : (
            personalData?.nationality || ''
          )}
        </div>
        <div className='flex items-center gap-3'>
          <div>
            <MailIcon />
          </div>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.email ? (
            <a href={`mailto:${personalData?.email}`} className='w-full'>
              {personalData?.email}
            </a>
          ) : (
            ''
          )}
        </div>
        <div className='flex items-center gap-3'>
          <div>
            <LocationIcon />
          </div>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : (
            <p>
              {[
                personalData?.address,
                personalData?.postalCode,
                personalData?.place,
              ]
                .filter(Boolean)
                .join(', ')}
            </p>
          )}
        </div>
        <div className='flex items-center gap-3'>
          <CakeIcon />
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.dateOfBirth ? (
            <p>
              {moment(personalData?.dateOfBirth).format('DD/MM/YYYY')}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className='flex flex-col gap-2 border-b border-[#CEE0FD] pb-4 w-full'>
        <div className='flex flex-col '>
          <h3 className='font-bold pb-2'>{translation.parents}</h3>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.fatherFirstName || personalData?.fatherLastName ? (
            <p>
              {[personalData?.fatherFirstName, personalData?.fatherLastName]
                .filter(Boolean)
                .join(' ')}
              {personalData?.fatherOccupation
                ? ' (' + personalData?.fatherOccupation + ')'
                : null}
            </p>
          ) : (
            ''
          )}
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.motherFirstName || personalData?.motherLastName ? (
            <p>
              {[personalData?.motherFirstName, personalData?.motherLastName]
                .filter(Boolean)
                .join(' ')}
              {personalData?.motherOccupation
                ? ' (' + personalData?.motherOccupation + ')'
                : null}
            </p>
          ) : (
            ''
          )}
        </div>
        <div className='flex flex-col '>
          <h3 className='font-bold pb-2'>{translation.siblings}</h3>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.siblings?.length > 0 ? (
            personalData?.siblings?.map((sibling, index) => (
              <p key={index}>{`${sibling?.siblingName}${
                sibling?.siblingOccupation
                  ? ' (' + sibling?.siblingOccupation + ')'
                  : ''
              }`}</p>
            ))
          ) : (
            NoSiblings
          )}
        </div>
      </div>
      <div className='flex flex-col gap-2 border-b border-[#CEE0FD] pb-4 w-full'>
        <div className='flex flex-col '>
          <h3 className='font-bold text-lg pb-2'>{translation.strengths}</h3>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.strengths?.length > 0 ? (
            personalData?.strengths?.map((strength, index) => (
              <p key={index}>{strength}</p>
            ))
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='flex flex-col gap-2 border-b border-[#CEE0FD] pb-4 w-full'>
        <div className='flex flex-col '>
          <h3 className='font-bold text-lg pb-2'>{translation.weaknesses}</h3>
          {loading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : personalData?.weaknesses?.length > 0 ? (
            personalData?.weaknesses?.map((weakness, index) => (
              <p key={index}>{weakness}</p>
            ))
          ) : (
            ''
          )}
        </div>
      </div>

      <div className='w-full'>
        <div className='flex justify-between items-center w-full pb-4'>
          <p className='text-base md:text-2xl font-bold'>
            {translation.references}
          </p>
          {canEdit && (
            <Link
              to={
                ROUTES({ userId: id }).student.edit.personalInfo + '#references'
              }
            >
              <EditIcon />
            </Link>
          )}
        </div>
        <div className='flex flex-col'>
          {referencesLoading ? (
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='100%' />
          ) : references.length ? (
            references.map((reference, index) => (
              <div
                key={index}
                className={index !== references?.length ? 'pb-3' : 'pb-0'}
              >
                <p className='text-lg font-semibold'>
                  {`${reference?.firstName} ${reference?.lastName} ${
                    reference?.designation
                      ? '(' + reference?.designation + ')'
                      : ''
                  }`}
                </p>
                <p className='text-sm'>
                  {`${reference?.contactNumber} • ${reference?.email}`}
                </p>
              </div>
            ))
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonalDetails

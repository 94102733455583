import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useState, useEffect, createContext } from 'react'
import { useSelector } from 'react-redux'

import 'assets/css/App.css'
import ReactGA from 'react-ga'
import Test from 'components/Test'
import Invite from 'components/Invite'
import Footer from 'components/Footer'
import { user } from 'store/reducers/user'
import AllJobs from 'components/Admin/Job'
import Error404 from 'components/Error404'
import Diary from 'components/Student/Diary'
import Navbar from 'components/Navbar/Navbar'
import 'react-toastify/dist/ReactToastify.css'
import AboutUs from 'components/Footer/AboutUs'
import Classes from 'components/Teacher/Classes'
import LandingPage from 'components/LandingPage'
import AddJob from 'components/Student/Jobs/Add'
import ExportDiary from 'components/ExportDiary'
import StudentJobs from 'components/Student/Jobs'
import WatchProfile from 'components/WatchProfile'
import AdminClasses from 'components/Admin/Classes'
import UpdateJob from 'components/Admin/Job/Update'
import AddDiary from 'components/Student/EditDiary'
import Impressum from 'components/Footer/Impressum'
import EditTrial from 'components/Student/EditTrial'
import ResetPassword from 'components/ResetPassword'
import JobDetail from 'components/Student/JobDetail'
import TermsOfUse from 'components/Footer/TermsOfUse'
import { translation } from 'utils/germanTranslation'
import AdminDashboard from 'components/Admin/Dashboard'
import Anleitungen from 'components/Footer/Anleitungen'
import EditHobbies from 'components/Student/EditHobbies'
import 'react-image-gallery/styles/css/image-gallery.css'
import AddClass from 'components/Teacher/Classes/AddClass'
import EditReferences from 'components/Student/References'
import EditLanguage from 'components/Student/EditLanguage'
import StudentsAndTeachers from 'components/Admin/Teachers'
import StudentDashboard from 'components/Student/Dashboard'
import EditDocuments from 'components/Student/EditDocuments'
import StudentOptions from 'components/Student/StudentOptions'
import AccountSettings from 'components/Teacher/Classes/Settings'
import ClassDetails from 'components/Teacher/Classes/ClassDetails'
import MotivationLetter from 'components/Student/MotivationLetter'
import EditPersonalDetail from 'components/Student/EditPersonalDetail'
import AddStudentsToClass from 'components/Teacher/Classes/AddStudents'
import EditAcademicHistory from 'components/Student/EditAcademicHistory'
import AddMotivationLetter from 'components/Student/EditMotivationLetter'
import WebBanner from 'components/Admin/Banner'
import AddBanner from 'components/Admin/Banner/AddBanner'
import UpdateBanner from 'components/Admin/Banner/UpdateBanner'
import CookiePopup from 'components/LandingPage/CookiePopup'
// import ScrollToTop from 'components/ScrollToTop'

const TRACKING_ID = 'G-TKYZNKW0VJ'
ReactGA.initialize(TRACKING_ID)

const PrivateRoute = () => {
  const auth = useSelector(user).id

  return auth ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' />
  )
}

const ProtectedTeacherRoute = () => {
  const { role } = useSelector(user)
  const navigate = useNavigate()
  const [isTeacher, setIsTeacher] = useState(false)

  useEffect(() => {
    if (String(role).toLowerCase() === 'teacher') {
      setIsTeacher(true)
    } else {
      navigate(-1)
    }
  }, [role, navigate])

  return (
    isTeacher && (
      <>
        <Outlet />
      </>
    )
  )
}

const ProtectedAdminRoute = () => {
  const { role } = useSelector(user)
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (String(role).toLowerCase() === 'admin') {
      setIsAdmin(true)
    } else {
      navigate(-1)
    }
  }, [role, navigate])

  return (
    isAdmin && (
      <>
        <Outlet />
      </>
    )
  )
}

export const NavbarContext = createContext()

const App = () => {
  const [
    isAdminTeacherViewingStudentProfile,
    setIsAdminTeacherViewingStudentProfile,
  ] = useState({ isWatching: false, studentId: '' })

  const [showLocationModal, setShowLocationModal] = useState(false)

  return (
    <NavbarContext.Provider
      value={{
        isAdminTeacherViewingStudentProfile,
        setIsAdminTeacherViewingStudentProfile,
        showLocationModal,
        setShowLocationModal,
      }}
    >
      <ToastContainer />
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <div className='min-h-screen h-full'>
          <Routes>
            <Route index element={<LandingPage isRegister={true} />} />
            <Route path='/404' element={<Error404 />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/impressum' element={<Impressum />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/anleitungen' element={<Anleitungen />} />
            <Route path='/login' element={<LandingPage isRegister={false} />} />
            <Route path='/test' element={<Test />} />
            <Route path='/export-diary' element={<ExportDiary />} />
            <Route path='/invite/:token' element={<Invite />} />
            <Route path='/watch-profile/:userId' element={<WatchProfile />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route path='/jobs'>
              <Route index element={<StudentJobs />} />
              <Route path=':title' element={<JobDetail />} />
            </Route>
            <Route path='/:id/dashboard' element={<StudentDashboard />} />
            <Route path='/:id/' element={<PrivateRoute />}>
              <Route index element={<StudentOptions />} />
              <Route path='admin' element={<ProtectedAdminRoute />}>
                <Route path='dashboard' element={<AdminDashboard />} />
                <Route path='web-banner'>
                  <Route index element={<WebBanner />} />
                  <Route path='add' element={<AddBanner />} />
                  <Route path='update/:bannerId' element={<UpdateBanner />} />
                </Route>
                <Route path='job'>
                  <Route index element={<AllJobs />} />
                  <Route
                    path='add'
                    element={<AddJob title={`Job ${translation.add}`} />}
                  />
                  <Route path='update/:jobId' element={<UpdateJob />} />
                </Route>
                <Route path='classes' element={<AdminClasses />} />
                <Route
                  path='teachers'
                  element={<StudentsAndTeachers role='Teachers' />}
                />
                <Route
                  path='students'
                  element={<StudentsAndTeachers role='Students' />}
                />
              </Route>
              <Route path='diary' element={<Diary />} />
              <Route
                path='motivation-letter-library'
                element={<MotivationLetter />}
              />
              <Route path='classes' element={<ProtectedTeacherRoute />}>
                <Route index element={<Classes />} />
                <Route path='add' element={<AddClass />} />
                <Route path=':classId'>
                  <Route index element={<ClassDetails />} />
                  <Route path='add'>
                    <Route
                      path='students'
                      element={<AddStudentsToClass role='Student' />}
                    />
                    <Route
                      path='teachers'
                      element={<AddStudentsToClass role='Teacher' />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path='settings'>
                <Route index element={<AccountSettings />} />
              </Route>
              <Route path='edit'>
                <Route path='academic' element={<EditAcademicHistory />} />
                <Route path='personal' element={<EditPersonalDetail />} />
                <Route path='references' element={<EditReferences />} />
                <Route path='documents' element={<EditDocuments />} />
                <Route path='languages' element={<EditLanguage />} />
                <Route path='hobbies' element={<EditHobbies />} />
                <Route path='diary' element={<AddDiary />} />
                <Route
                  path='motivation-letter'
                  element={<AddMotivationLetter />}
                />
                <Route path='trial' element={<EditTrial />} />
              </Route>
            </Route>
          </Routes>
          <CookiePopup />
        </div>
        <Footer />
      </BrowserRouter>
    </NavbarContext.Provider>
  )
}

export default App

import React from 'react'

const Error404 = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        id='mainImage_create'
        data-name='mainImage'
        viewBox='0 0 171.2 81.5'
      >
        <style id='svgStyle'>
          {
            '@import url(https://fonts.googleapis.com/css?family=Merriweather);@keyframes earmove{0%,30%{transform:translateX(-.3px) translateY(.6px)}60%{transform:translateX(-.7px) translateY(0)}70%,to{transform:translateX(-.7px) translateY(-.3px)}}@keyframes move{0%,30%{transform:translateX(-.3px) translateY(.6px)}60%{transform:translateX(2px) translateY(0)}70%,to{transform:translateX(2px) translateY(-.3px)}}@keyframes neck{0%,50%{transform:translateY(.7px)}to{transform:translateY(0)}}.changeColor{fill:#4f86ed}.cls-7{opacity:.8}.cls-2{fill:#fff}.cls-10,.cls-11,.cls-12,.cls-14,.cls-16,.cls-3{fill:none}.cls-3{stroke:#5c7690}.cls-10,.cls-11,.cls-12,.cls-3{stroke-miterlimit:10}.cls-14,.cls-15,.cls-16,.cls-3{stroke-width:.5px}.cls-4{fill:#ffe1d9}.cls-5{fill:#ffcfbf}.cls-10,.cls-12{stroke:#d26f51}.cls-10,.cls-11{stroke-width:.38px}.cls-11{stroke:#000}.cls-12{stroke-width:.19px}.cls-13{opacity:.45}.cls-14,.cls-15,.cls-16{stroke:#b0bec5;stroke-linejoin:round}.cls-15{fill:#edf0f2}.cls-16{stroke-linecap:round}.earMove,.faceMove{transition:all ease-in-out 2s;transform-origin:50% 50%;animation:earmove 1.5s linear infinite alternate}.faceMove{animation:move 1.5s linear infinite alternate}'
          }
        </style>
        <path
          id='c-1'
          d='M46.62 52.5c5.78 4.9 21.14 8.4 39.19 8.4s33.41-3.5 39.19-8.4c-5.78-4.9-21.14-8.4-39.19-8.4s-33.4 3.5-39.19 8.4Z'
          className='changeColor'
          style={{
            fill: '#000',
            opacity: 0.3,
          }}
        />
        <path
          d='M99.73 47.71H68.65a7.13 7.13 0 0 0-7.13 7.13V60a152.58 152.58 0 0 0 24.3 1.83 157.87 157.87 0 0 0 21.05-1.35v-5.64a7.13 7.13 0 0 0-7.14-7.13Z'
          className='cls-2'
        />
        <path
          d='M123.56 55.81C115 58.94 101.27 61 85.81 61c-26 0-47-5.71-47-12.76 0-3.45 5.05-6.58 13.25-8.88M55.37 38.47a140 140 0 0 1 30.44-3c26 0 47 5.71 47 12.76 0 2.4-2.44 4.65-6.69 6.57M53.41 38.95l.94-.24'
          className='cls-3'
        />
        <path
          d='m91.68 47.71-.75-11.2-11.78 7.33-1.69 3.87h-1.67c0 3.36 3.76 6.08 8.4 6.08s8.4-2.72 8.4-6.08Z'
          className='cls-4'
        />
        <path
          d='M78 46.53a27.19 27.19 0 0 0 6.41.82c3.1 0 7.11-2.19 7.11-2.19l-.42-6.2-11.95 4.88Z'
          className='cls-5'
          style={{
            transition: 'all ease-in-out 2s',
            transformOrigin: '50% 50%',
            animation: 'neck 1.5s linear infinite alternate',
          }}
        />
        <path
          d='M92.59 32.22V28.5l-15.82-.79v4.51h15.82z'
          className='earMove'
        />
        <circle
          cx={78.06}
          cy={34.04}
          r={2.47}
          className='earMove'
          style={{
            fill: '#fecbb6',
          }}
        />
        <path
          d='m81.74 57.06-21.11-7.34a6.72 6.72 0 1 0-2.93 12.77h35.55c0-5.71-11.51-5.43-11.51-5.43ZM77.46 25h13.46v14.38a6.73 6.73 0 0 1-6.73 6.73 6.73 6.73 0 0 1-6.73-6.73V25Z'
          className='cls-4'
        />
        <path
          id='c-2'
          d='M74.82 26.48h19.14v2.45H74.82z'
          className='changeColor cls-7'
          style={{
            fill: '#000',
          }}
          transform='rotate(2.49 84.62 27.854)'
        />
        <path
          id='c-3'
          d='M84.36 18.69h.5a7.8 7.8 0 0 1 7.8 7.8h-16.1a7.8 7.8 0 0 1 7.8-7.8Z'
          className='changeColor cls-7'
          style={{
            fill: '#000',
          }}
          transform='rotate(2.49 84.735 22.557)'
        />
        <path
          id='c-4'
          d='m82.44 23.89 9.74.43.41.02-.11 2.5-11.52-.51 1.48-2.44z'
          className='changeColor cls-8'
          style={{
            fill: '#000',
          }}
        />
        <circle
          cx={78.72}
          cy={23.73}
          r={3.73}
          className='faceMove'
          style={{
            fill: '#fecb02',
          }}
          transform='rotate(-87.51 78.711 23.734)'
        />
        <circle
          cx={78.72}
          cy={23.73}
          r={2.36}
          className='cls-2 faceMove'
          transform='rotate(-87.51 78.711 23.734)'
        />
        <circle cx={90.92} cy={34.04} r={2.47} className='cls-4 earMove' />
        <path
          d='m112.2 53-9.87-21.92-3-5.48-11.86-.22 7.42 3.35h-3.34l5.82 4.58 2 22.26A6.72 6.72 0 1 0 112.2 53Z'
          className='cls-4'
        />
        <ellipse
          cx={80.09}
          cy={33.12}
          className='faceMove'
          rx={0.53}
          ry={0.59}
        />
        <ellipse
          cx={86.34}
          cy={33.12}
          className='faceMove'
          rx={0.53}
          ry={0.59}
        />
        <path
          d='m84.19 31.08-2.45 5.93h2.65M83.06 40.36a4 4 0 0 1 2.75-1'
          className='cls-10 faceMove'
        />
        <path
          d='m81.07 30.33-2.6.25M86.34 30.45l1.81.63'
          className='cls-11 faceMove'
        />
        <path
          d='m106.86 47.82 4.13-1.71M107.43 49.9l4.12-1.71M107.99 51.98l4.12-1.71'
          className='cls-12'
        />
        <g className='cls-13'>
          <path d='M85.81 2.46h10.77v3.5H85.81z' className='cls-14' />
          <path d='M96.58 2.46h10.77v3.5H96.58z' className='cls-15' />
          <path d='M92.19 5.95h10.77v3.5H92.19z' className='cls-14' />
          <path d='M107.36 5.95h2.27M110.68 5.95h.89' className='cls-16' />
        </g>
        <g className='cls-13'>
          <path d='M125 23.12h10.77v3.5H125z' className='cls-16' />
          <path d='M130.39 26.62h10.77v3.5h-10.77z' className='cls-15' />
          <path
            d='M119.62 26.62h10.77v3.5h-10.77zM141.16 26.62h4.57M125 23.12h-9.6M117.95 26.62h-2.55'
            className='cls-16'
          />
        </g>
        <g className='cls-13'>
          <path
            d='M39.34 16.12h10.77v3.5H39.34zM39.34 23.11h10.77v3.5H39.34zM50.11 23.11h10.77v3.5H50.11zM50.11 16.12h10.77v3.5H50.11z'
            className='cls-16'
          />
          <path d='M44 19.61h10.77v3.5H44z' className='cls-15' />
          <path
            d='M33.23 19.61H44v3.5H33.23zM60.89 19.61h4.62M39.34 16.12h-3.88M36.45 26.61h-3.22M63.2 23.11h2.31'
            className='cls-16'
          />
        </g>
        <path
          d='M115.4 58.12V38.27l4.8-1.26M129.01 53.21V43.14l2.73-1.01'
          className='cls-3'
        />
        <path
          d='M115.4 42.13a53.27 53.27 0 0 1 8 2A42 42 0 0 1 129 47M115.4 47.34a53.27 53.27 0 0 1 8 2 42 42 0 0 1 5.6 2.88M115.4 52.56a53.27 53.27 0 0 1 8 2l1 .42'
          className='cls-3'
        />
        <path
          d='M78.84 26.09v-4.71l-10.79-3.06a.91.91 0 0 0-.45-.13c-1.17 0-2.11 2.46-2.11 5.5s.95 5.5 2.11 5.5a.9.9 0 0 0 .44-.12Z'
          className='faceMove'
          style={{
            fill: '#fffdbb',
            opacity: 0.5,
          }}
        />
        <path
          d='M57.7 62.49h35.55a3.67 3.67 0 0 0-.33-1.49H53.43a6.69 6.69 0 0 0 4.27 1.49Z'
          className='cls-5'
        />
        <path d='M88.15 60.27s1.7.95 1.7 2.22' className='cls-12' />
        <path
          d='M101.81 61a6.68 6.68 0 0 0 8.51 0ZM90.92 30.25l-13.46-.56v-1.05l13.46.58v1.03z'
          className='cls-5'
        />
        <text
          style={{
            fontSize: '50%',
            fontFamily: '&quot',
          }}
          transform='translate(44.725 78)'
        >
          {'404 Page not found'}
        </text>
      </svg>
      )
    </>
  )
}

export default Error404

import * as React from 'react';

const DurationIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity={0.5} fill='#05060E'>
      <path d='M10 19.548c-4.942 0-8.959-4.017-8.959-8.958 0-4.942 4.017-8.959 8.958-8.959 4.942 0 8.959 4.017 8.959 8.959 0 4.941-4.017 8.958-8.959 8.958Zm0-16.667c-4.25 0-7.709 3.459-7.709 7.709s3.458 7.708 7.708 7.708 7.709-3.458 7.709-7.708S14.249 2.88 9.999 2.88Z' />
      <path d='M13.09 13.865a.558.558 0 0 1-.316-.092l-2.583-1.541c-.642-.384-1.117-1.225-1.117-1.967V6.848a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v3.417c0 .3.25.742.509.891l2.583 1.542c.3.175.392.559.217.858a.642.642 0 0 1-.542.309Z' />
    </g>
  </svg>
);

export default DurationIcon;

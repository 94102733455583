import * as React from 'react'

const AddAcademicHistory = () => (
  <svg width={88} height={89} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 44.001a44 44 0 1 0 88 0 44 44 0 0 0-88 0Z' fill='#E1D6FF' />
    <path
      d='M71.342 64.517c0-1.275-12.534-2.31-27.998-2.31s-28 1.035-28 2.31c0 1.275 12.54 2.31 28 2.31s27.998-1.034 27.998-2.31Z'
      fill='#F1E6FF'
    />
    <path d='M14.355 25.145h58.577v38.492H14.355V25.145Z' fill='#fff' />
    <path
      d='M60.697 21.799h4.385a1.155 1.155 0 0 1 1.155 1.155v2.191h-6.695v-2.191a1.155 1.155 0 0 1 1.155-1.155Z'
      fill='#F1E6FF'
      stroke='#020064'
    />
    <path
      d='M52.848 25.145h8.367v38.492h-8.367V25.145Zm-26.778 0h8.368v38.492H26.07V25.145Z'
      fill='#F1E6FF'
    />
    <path
      d='M22.205 21.799h4.383a1.155 1.155 0 0 1 1.155 1.155v2.191H21.05v-2.191a1.155 1.155 0 0 1 1.155-1.155Zm17.255-3.348h8.367a3.347 3.347 0 0 1 3.348 3.348H36.112a3.347 3.347 0 0 1 3.348-3.348Z'
      fill='#F1E6FF'
      stroke='#020064'
    />
    <path
      d='M55.358 31.84a1.674 1.674 0 1 0 3.348 0 1.674 1.674 0 0 0-3.348 0Zm0 25.104a1.674 1.674 0 1 0 3.348 0 1.674 1.674 0 0 0-3.348 0ZM28.581 31.84a1.673 1.673 0 1 0 3.346 0 1.673 1.673 0 0 0-3.346 0Zm0 25.104a1.673 1.673 0 1 0 3.346 0 1.673 1.673 0 0 0-3.346 0Z'
      fill='#fff'
      stroke='#020064'
    />
    <path
      d='M52.848 25.145h8.367v38.492h-8.367V25.145Zm-26.778 0h8.368v38.492H26.07V25.145Z'
      stroke='#020064'
    />
    <path
      d='M14.355 25.145h58.577v38.492H14.355V25.145Zm36.819-3.346v3.346m-15.062-3.346v3.346m18.41 36.819h1.673'
      stroke='#020064'
    />
    <path
      d='M51.378 60.069a14.978 14.978 0 1 0 29.955 0 14.978 14.978 0 0 0-29.955 0Z'
      fill='#F1E6FF'
    />
    <path
      d='M51.378 60.069a14.978 14.978 0 1 0 29.955 0 14.978 14.978 0 0 0-29.955 0Z'
      stroke='#020064'
    />
    <path
      d='M68.852 67.557v-4.992h4.992a2.497 2.497 0 0 0 0-4.993h-4.992V52.58a2.497 2.497 0 0 0-4.993 0v4.992h-4.992a2.497 2.497 0 0 0 0 4.992h4.992v4.993a2.497 2.497 0 0 0 4.993 0Z'
      fill='#fff'
      stroke='#020064'
    />
  </svg>
)

export default AddAcademicHistory

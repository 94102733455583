import { IconButton } from '@mui/material'
import DeleteIcon from 'assets/icons/delete'
import AutoComplete from 'components/AutoComplete'
import Button from 'components/Button'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import React from 'react'
import { translation } from 'utils/germanTranslation'

const CustomAbilitySection = ({
  setValue,
  value,
  abilities,
  setAbilities,
  ability,
}) => {
  return (
    <SimpleEditWrapper
      pageTitle={translation[ability]}
      customized={true}
      containerClasses='p-0'
      addAnotherField={() => {
        setValue({
          ...value,
          [ability]: [...value[ability], ''],
        })
      }}
    >
      <h3 className='font-semibold'>{translation[ability]}:</h3>
      {value[ability].map((strength, index) => (
        <div
          key={index}
          className={`flex flex-col sm:flex-row items-center justify-between gap-2 mr-8'`}
        >
          <AutoComplete
            options={abilities}
            setOptions={setAbilities}
            value={strength}
            controlled={false}
            handleChange={(newValue) =>
              setValue({
                ...value,
                [ability]: [
                  ...value[ability].slice(0, index),
                  newValue || '',
                  ...value[ability].slice(index + 1),
                ],
              })
            }
            autoHighlight
          />
          <IconButton
            onClick={() => {
              setValue({
                ...value,
                [ability]: [
                  ...value[ability].slice(0, index),
                  ...value[ability].slice(index + 1),
                ],
              })
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
    </SimpleEditWrapper>
  )
}

const Abilities = ({
  value,
  setValue,
  strengthOptions,
  setStrengthOptions,
  weaknessOptions,
  setWeaknessOptions,
  onUpdateClick,
  isLoading,
}) => {
  return (
    <SimpleEditWrapper customized={true} showAddIcon={false}>
      <p className='font-medium'>
        Wähle 1-3 Stärken und Schwächen aus (klicke ins Feld oder schreibe
        direkt ins Feld und klicke ENTER).
      </p>
      <div className='grid grid-cols-none sm:grid-cols-2 gap-6'>
        <CustomAbilitySection
          ability='strengths'
          value={value}
          setValue={setValue}
          abilities={strengthOptions}
          setAbilities={setStrengthOptions}
        />
        <CustomAbilitySection
          ability='weaknesses'
          value={value}
          setValue={setValue}
          abilities={weaknessOptions}
          setAbilities={setWeaknessOptions}
        />
      </div>
      <div className='flex justify-end'>
        <Button
          title={translation.update}
          onClick={onUpdateClick}
          titleClasses='text-white text-lg font-semibold'
          containerClasses='rounded-md w-full sm:w-[138px] h-[60px] bg-[#458EFF]'
          disabled={isLoading}
        />
      </div>
    </SimpleEditWrapper>
  )
}

export default Abilities

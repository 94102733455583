import * as React from 'react';

const CalenderIcon = () => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity={0.6} fill='#05060E'>
      <path d='M6.667 4.792a.63.63 0 0 1-.625-.625v-2.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v2.5a.63.63 0 0 1-.625.625ZM13.334 4.792a.63.63 0 0 1-.625-.625v-2.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v2.5a.63.63 0 0 1-.625.625ZM17.083 8.2H2.917a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h14.166a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z' />
      <path d='M13.333 18.958H6.667c-3.042 0-4.792-1.75-4.792-4.791V7.082c0-3.042 1.75-4.792 4.792-4.792h6.666c3.042 0 4.792 1.75 4.792 4.792v7.083c0 3.042-1.75 4.792-4.792 4.792ZM6.667 3.542c-2.384 0-3.542 1.158-3.542 3.541v7.083c0 2.384 1.158 3.542 3.542 3.542h6.666c2.384 0 3.542-1.158 3.542-3.541V7.082c0-2.383-1.158-3.542-3.542-3.542H6.667Z' />
      <path d='M7.083 12.083a.829.829 0 0 1-.316-.066.96.96 0 0 1-.275-.175.962.962 0 0 1-.175-.276.83.83 0 0 1-.067-.316c0-.217.092-.434.242-.592a.96.96 0 0 1 .275-.175.796.796 0 0 1 .633 0c.05.017.1.042.15.075l.125.1c.033.042.075.084.1.125.033.05.058.1.075.15.025.05.042.1.05.15.008.059.017.108.017.167a.876.876 0 0 1-.242.591.876.876 0 0 1-.592.242ZM10 12.083a.876.876 0 0 1-.592-.241l-.1-.125a.631.631 0 0 1-.075-.15.53.53 0 0 1-.05-.15c-.008-.059-.017-.109-.017-.167a.83.83 0 0 1 .067-.317c.042-.1.1-.191.175-.275a.835.835 0 0 1 .909-.175.86.86 0 0 1 .274.175c.15.159.242.375.242.592 0 .058-.008.108-.017.167a.529.529 0 0 1-.05.15.634.634 0 0 1-.075.15l-.1.125a.86.86 0 0 1-.274.175.83.83 0 0 1-.317.066ZM7.083 15a.83.83 0 0 1-.316-.066.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317c0-.217.092-.433.242-.592a.963.963 0 0 1 .275-.175.832.832 0 0 1 .908.175c.033.042.075.084.1.125.033.05.058.1.075.15.025.05.042.1.05.159.008.05.017.108.017.158a.876.876 0 0 1-.242.591.876.876 0 0 1-.592.242Z' />
    </g>
  </svg>
);

export default CalenderIcon;

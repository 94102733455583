import * as React from 'react'
const WordIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={38}
    height={38}
    viewBox='-4 0 64 64'
    {...props}
  >
    <g fillRule='evenodd'>
      <path
        fill='#107cad'
        d='M5.11 0A5.07 5.07 0 0 0 0 5v53.88A5.07 5.07 0 0 0 5.11 64h45.78A5.07 5.07 0 0 0 56 58.88v-38.6L37.06 0z'
      />
      <path
        fill='#084968'
        d='M56 20.35v1H43.18s-6.31-1.26-6.13-6.71c0 0 .21 5.71 6 5.71z'
      />
      <path
        fill='#90d0fe'
        d='M37.07 0v14.56a5.78 5.78 0 0 0 6.11 5.79H56z'
        opacity={0.5}
      />
    </g>
    <path
      fill='#fff'
      d='M14.24 53.86h-3a1.08 1.08 0 0 1-1.08-1.08v-9.85a1.08 1.08 0 0 1 1.08-1.08h3a6 6 0 1 1 0 12zm0-10.67h-2.61v9.34h2.61a4.41 4.41 0 0 0 4.61-4.66 4.38 4.38 0 0 0-4.61-4.68zm14.42 10.89a5.86 5.86 0 0 1-6-6.21 6 6 0 1 1 11.92 0 5.87 5.87 0 0 1-5.92 6.21zm0-11.09c-2.7 0-4.41 2.07-4.41 4.88s1.71 4.88 4.41 4.88 4.41-2.09 4.41-4.88S31.35 43 28.66 43zm18.45.38a.75.75 0 0 1 .2.52.71.71 0 0 1-.7.72.64.64 0 0 1-.51-.24 4.06 4.06 0 0 0-3-1.38 4.61 4.61 0 0 0-4.63 4.88 4.63 4.63 0 0 0 4.63 4.88 4 4 0 0 0 3-1.37.7.7 0 0 1 .51-.24.72.72 0 0 1 .7.74.78.78 0 0 1-.2.51 5.33 5.33 0 0 1-4 1.69 6.22 6.22 0 0 1 0-12.43 5.26 5.26 0 0 1 4 1.72z'
    />
  </svg>
)
export default WordIcon

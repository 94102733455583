import React from 'react'
import { Button } from '@mui/material'

import LocationIcon from 'assets/images/location.png'

const ChangeKanton = ({
  shouldShowButton,
  handleLocationModal,
  containerClasses,
}) => {
  return (
    <React.Fragment>
      {shouldShowButton && (
        <Button
          onClick={handleLocationModal}
          className={`min-w-[42px] text-softBlack normal-case items-center font-bold bg-white rounded-xl px-0 sm:px-3 h-[46px] text-xs gap-2 ${containerClasses}`}
        >
          <img src={LocationIcon} alt='location-drop' className='w-3' />
          <div className='hidden sm:flex flex-col items-center text-center'>
            <h1>Mein Wohnkanton:</h1>
            {localStorage.getItem('location') && (
              <span>{localStorage.getItem('location')}</span>
            )}
          </div>
        </Button>
      )}
    </React.Fragment>
  )
}

export default ChangeKanton

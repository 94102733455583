import axios from 'axios';
import { TrialApprenticeship } from 'components/Student/EditMotivationLetter/Step1';
import { NEWEST, OLDEST } from 'components/Student/EditTrial/VerifiedEditTrial';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetDiary = (id, diaryId, onlyTrials = false) => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const reqHeaders = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    id,
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const url = diaryId
        ? `${API_URL.diary.findById}${diaryId}`
        : API_URL.diary.all;
      const { data: userData } = await axios.get(BACKEND_URL + url, {
        headers: reqHeaders,
      });
      const diary = JSON.parse(userData.data);
      if (onlyTrials) {
        let temp = [...diary];
        if (userData.sortBy === NEWEST) {
          const sortedRow = [...diary]
            .filter((x) => x.type === TrialApprenticeship)
            .sort((a, b) => {
              return new Date(b.date?.from) - new Date(a.date?.from);
            });
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].type === TrialApprenticeship) {
              temp[index] = sortedRow.shift();
            }
          }
        } else if (userData.sortBy === OLDEST) {
          const sortedRow = [...diary]
            .filter((x) => x.type === TrialApprenticeship)
            .sort((a, b) => {
              return new Date(a.date?.from) - new Date(b.date?.from);
            });
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].type === TrialApprenticeship) {
              temp[index] = sortedRow.shift();
            }
          }
        }
        setValue(temp);
      } else {
        setValue(diary);
      }
      setSortBy(userData.sortBy);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return {
    loading,
    data: value,
    sortBy,
    refetch: getUserData,
    error,
  };
};

export default useGetDiary;

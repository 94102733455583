import * as React from 'react';

const MailIcon = () => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.167 17.708H5.834a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h8.333c2.383 0 3.542-1.158 3.542-3.541V7.082c0-2.383-1.159-3.542-3.542-3.542H5.834c-2.384 0-3.542 1.159-3.542 3.542a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625c0-3.042 1.75-4.792 4.792-4.792h8.333c3.042 0 4.792 1.75 4.792 4.792v5.833c0 3.042-1.75 4.792-4.792 4.792Z'
      fill='#05060E'
    />
    <path
      d='M10 10.725c-.7 0-1.408-.217-1.95-.658L5.442 7.983a.623.623 0 0 1 .775-.975l2.608 2.084c.633.508 1.708.508 2.342 0l2.608-2.084a.615.615 0 0 1 .875.1.615.615 0 0 1-.1.875l-2.608 2.084c-.534.441-1.242.658-1.942.658ZM6.667 14.375h-5a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h5a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z'
      fill='#05060E'
    />
    <path
      d='M4.167 11.041h-2.5a.63.63 0 0 1-.625-.625.63.63 0 0 1 .625-.625h2.5a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z'
      fill='#292D32'
    />
  </svg>
);

export default MailIcon;

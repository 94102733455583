import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

const CustomTable = ({
  data = [],
  headerTemplate,
  rowTemplate,
  loading,
  customizeRow,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' size='small'>
        {headerTemplate}
        <TableBody>
          {!loading && data.length > 0 && !customizeRow
            ? data.map((row, index) => rowTemplate(row, index))
            : rowTemplate}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

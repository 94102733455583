import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import React, { useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import axios from 'axios'
import { ShowToast } from 'utils/toastMessages'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'

import useGetAdminJobs from 'hooks/useGetAdminJobs'
import {
  JOB_OVERVIEW_PAGE,
  LANDING_PAGE_BANNER_TYPE,
  bannerTypeMapping,
  multiBannerMapping,
} from './constants'
import UploadBanner from './UploadBanner'
import BannerForm from './BannerForm'
import BannerSettings from './BannerSettings'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { user as userState } from 'store/reducers/user'
import { ROUTES } from 'utils/routes'
import useFetchAllCompanies from 'hooks/useFetchAllCompanies'

const initialValues = {
  id: '',
  image: null,
  company: '',
  multiBanner: 'nein',
  firstUrl: '',
  secondUrl: '',
  type: '',
  startDate: '',
  endDate: '',
  jobs: [],
  jobTypes: [],
  kantons: [],
  isNational: false,
}

const AddBanner = ({ title, banner, toUpdate }) => {
  const navigate = useNavigate()

  const user = useSelector(userState)

  const [value, setValue] = useState(banner || initialValues)
  const [updatingJob, setUpdatingJob] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)

  const handleChange = (newValue, field) => {
    if (
      field === 'type' &&
      (newValue === LANDING_PAGE_BANNER_TYPE || newValue === JOB_OVERVIEW_PAGE)
    ) {
      if (newValue === LANDING_PAGE_BANNER_TYPE)
        setValue({
          ...value,
          type: newValue,
          jobs: [],
          kantons: [],
        })
      else if (newValue === JOB_OVERVIEW_PAGE)
        setValue({
          ...value,
          type: newValue,
          jobs: [],
        })
    } else if (field === 'isNational' && Boolean(newValue)) {
      setValue({ ...value, kantons: [], isNational: true })
    } else {
      setValue({ ...value, [field]: newValue })
    }
  }

  const handleBannerImageUpload = async (file) => {
    const formData = new FormData()
    formData.append('banner', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.banner.uploadBannerImage,
        formData,
        config
      )
      if (data.success) return data.file
      return null
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleDateChange = (date, field) => {
    if (date !== 'Invalid Date') {
      setValue((prevValue) => {
        return { ...prevValue, [field]: date }
      })
    }
  }

  const handleSubmit = async () => {
    let uploadedImageLink = ''
    if (uploadedImage) {
      uploadedImageLink = await handleBannerImageUpload(uploadedImage)
    }

    try {
      const verb = toUpdate ? 'put' : 'post'
      const url = toUpdate
        ? `${BACKEND_URL}${API_URL.banner.updateBanner}/${banner.id}`
        : `${BACKEND_URL}${API_URL.banner.addBanner}`
      const { data } = await axios[verb](
        url,
        {
          ...value,
          image: uploadedImage ? uploadedImageLink : value.image,
          multiBanner: multiBannerMapping[value.multiBanner],
          type: bannerTypeMapping[value.type],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      if (data.success) {
        setUpdatingJob(false)
        ShowToast({ type: 'success', message: data?.message })
        navigate(ROUTES({ userId: user.id }).admin.banner.allBanners)
      }
    } catch (error) {
      setUpdatingJob(false)
      ShowToast({ type: 'error', message: error })
    }
    // }
  }

  useGetBack(value)

  const { data: jobs } = useGetAdminJobs()
  const { companies, setCompanies } = useFetchAllCompanies()

  return (
    <div>
      <EditSkills
        pageTitle='Werbebanner'
        onUpdateClick={handleSubmit}
        cancelButtonTitle={translation.back.toLowerCase()}
        disabled={updatingJob}
        submitButtonTitle={translation.submit}
      >
        <SimpleEditWrapper
          showAddIcon={false}
          customized={true}
          pageTitle={title}
        >
          <div className='w-full items-start xs:items-center flex-col xs:flex-row flex gap-4 rounded-md'>
            <UploadBanner
              image={
                uploadedImage
                  ? URL.createObjectURL(uploadedImage)
                  : value.image
                  ? `${BACKEND_URL}/${value.image}`
                  : null
              }
              setImage={(image) =>
                uploadedImage
                  ? setUploadedImage(image)
                  : value.image
                  ? handleChange(image, 'image')
                  : setUploadedImage(image)
              }
            />
            <BannerForm
              companies={companies}
              company={value.company}
              multiBanner={value.multiBanner}
              firstUrl={value.firstUrl}
              secondUrl={value.secondUrl}
              type={value.type}
              handleChange={handleChange}
              setCompanies={setCompanies}
            />
          </div>

          <BannerSettings
            jobs={value.jobs}
            jobTypes={value.jobTypes}
            kantons={value.kantons}
            type={value.type}
            databaseJobs={jobs}
            startDate={value.startDate}
            endDate={value.endDate}
            isNational={Boolean(value.isNational)}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
          />
        </SimpleEditWrapper>
      </EditSkills>
    </div>
  )
}
export default AddBanner

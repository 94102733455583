import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

export const deleteDiaryById = async (
  id,
  diaryId,
  handleModalClose,
  refetch
) => {
  const reqHeaders = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    id,
  };
  try {
    const { data } = await axios.get(
      BACKEND_URL + API_URL.diary.deleteById + diaryId,
      {
        headers: {
          ...(diaryId ? { ...reqHeaders, diaryid: diaryId } : reqHeaders),
        },
      }
    );
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
    handleModalClose();
    refetch();
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  }
};

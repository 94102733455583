import React, { useEffect, useState } from 'react'
import { Box, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'

import EditIcon from 'assets/icons/edit'
import TrashIcon from 'assets/icons/trash'
import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import SortableTableCell from 'components/SortableTableCell'
import CustomTable from 'components/Table'
import useGetAdminJobs from 'hooks/useGetAdminJobs'
import { Link, useParams } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ROUTES } from 'utils/routes'
import { ShowToast } from 'utils/toastMessages'
import VerifyAdmin from '../VerifyAdmin'
import { translation } from 'utils/germanTranslation'
import NothingToShow from 'components/Student/NothingToShow'

const AllJobs = () => (
  <VerifyAdmin>
    <VerifiedAllJobs />
  </VerifyAdmin>
)

const modalDataInitialState = {
  id: '',
  title: '',
  open: false,
}

const NAME = 'title'
const CATEGORY = 'category'
const initialSortOrder = {
  [NAME]: { order: null, active: false },
  [CATEGORY]: { order: null, active: false },
}

const VerifiedAllJobs = () => {
  const { id: userId } = useParams()
  const { data, refetch } = useGetAdminJobs()

  const [modalData, setModalData] = useState(modalDataInitialState)
  const [sortBy, setSortBy] = useState('')
  const [value, setValue] = useState([])
  const [sortOrder, setSortOrder] = useState(initialSortOrder)

  const handleJobDelete = async (jobId) => {
    try {
      await axios.delete(BACKEND_URL + API_URL.job.deleteById + modalData.id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      setModalData(modalDataInitialState)
      refetch()
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleCloseModal = () => setModalData(modalDataInitialState)

  useEffect(() => {
    setValue(data)
    handleSort(NAME, data)
  }, [data])

  const handleSort = (key, value) => {
    if (value.length) {
      const newSortOrder = {
        ...initialSortOrder,
        [key]: {
          order: sortOrder[key].order === 'asc' ? 'desc' : 'asc',
          active: true,
        },
      }
      setSortOrder(newSortOrder)
      setSortBy(key)

      let sortedData = [...value.filter((job) => Boolean(job[key]))]
      sortedData.sort((a, b) => {
        var nameA = a[key]?.toLowerCase(),
          nameB = b[key]?.toLowerCase()
        if (nameA < nameB)
          if (newSortOrder[key].order === 'asc') {
            return -1
          } else {
            return 1
          }
        if (nameA > nameB) {
          if (newSortOrder[key].order === 'asc') {
            return 1
          } else {
            return -1
          }
        }
        return 0
      })

      setValue([...sortedData, ...value.filter((job) => !Boolean(job[key]))])
    }
  }

  const handleSortHover = (key) => {
    const newSortOrder = {
      ...sortOrder,
      [key]: {
        order: sortOrder[key].order,
        active: !sortOrder[key].active,
      },
    }
    setSortOrder(newSortOrder)
  }

  return (
    <div className='w-full h-full bg-softWhite'>
      <Box className='max-w-[1760px] min-h-screen px-4 pt-10 sm:px-8 w-full gap-4'>
        <div className='flex items-center justify-between pb-9'>
          <h3 className='text-2xl font-bold'>
            {translation.job}{' '}
            <span className='opacity-40'>({value.length})</span>
          </h3>
          <Link to={ROUTES({ userId }).admin.job.addJob}>
            <Button
              title={`${translation.job} ${translation.add} +`}
              titleClasses='bg-[#458EFF] text-white font-semibold w-[156px] h-[56px] flex items-center justify-center rounded-md'
            />
          </Link>
        </div>

        {value?.length > 0 ? (
          <>
            <CustomTable
              data={value}
              headerTemplate={
                <TableHead className='bg-[#F0F2F9]'>
                  <TableRow>
                    <SortableTableCell
                      title='Berufsbezeichnung'
                      onHover={() => handleSortHover(NAME)}
                      onHoverLeave={() => handleSortHover(NAME)}
                      performSorting={() => {
                        handleSort(NAME, value)
                      }}
                      isActive={sortOrder[NAME].active}
                      order={sortOrder[NAME].order}
                    />
                    <SortableTableCell
                      title={CATEGORY}
                      onHover={() => handleSortHover(CATEGORY)}
                      onHoverLeave={() => handleSortHover(CATEGORY)}
                      performSorting={() => {
                        handleSort(CATEGORY, value)
                      }}
                      isActive={sortOrder[CATEGORY].active}
                      order={sortOrder[CATEGORY].order}
                    />
                    <TableCell style={{ width: '10rem' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
              }
              rowTemplate={(row) => (
                <TableRow key={row?.id}>
                  <TableCell className='py-6'>{row?.title}</TableCell>
                  <TableCell className='py-6'>{row?.category}</TableCell>
                  <TableCell className='py-6 flex items-center gap-4'>
                    <Link
                      to={
                        ROUTES({ userId, jobId: row?.id }).admin.job.editJobById
                      }
                    >
                      <EditIcon containerClasses='cursor-pointer' />
                    </Link>
                    <TrashIcon
                      containerClasses='cursor-pointer'
                      onClick={() =>
                        setModalData({
                          open: true,
                          id: row?.id,
                          title: row?.title,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
            />
          </>
        ) : (
          <NothingToShow />
        )}
      </Box>
      <CustomModal
        open={modalData.open}
        handleClose={handleCloseModal}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Möchten Sie diesen Job wirklich entfernen{' '}
            <span className='text-blue-600 font-bold'>({modalData.title})</span>
            ?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={() => handleJobDelete()}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={() => handleCloseModal()}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default AllJobs

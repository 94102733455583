import React, { useState, useEffect } from 'react'
import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import AutoComplete from 'components/AutoComplete'
import CustomInput from 'components/Input'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import { updateDiary } from './updateDiary'
import useGetDiary from 'hooks/useGetDiary'
import { useSearchParams } from 'react-router-dom'
import NoAccess from 'components/NoAccess'
import VerifyEditAccess from 'components/VerifyEditAccess'
import Button from 'components/Button'
import { uuidv4 } from 'utils'
import { translation } from 'utils/germanTranslation'

const AddDiary = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate) =>
        canEdit ? (
          <VerifiedAddDiary userId={id} navigate={navigate} />
        ) : (
          <NoAccess />
        )
      }
    </VerifyEditAccess>
  )
}

const INTERVIEW = 'Bewerbungsgespräch',
  TRIAL_APPRENTICESHIP = 'Schnupperlehre',
  APPLICATION = 'Lehrstellenbewerbung',
  PHONE_CALL = 'Telefongespräch',
  EMAIL = 'Email'

export const options = [
  { title: TRIAL_APPRENTICESHIP },
  { title: INTERVIEW },
  { title: APPLICATION },
  { title: PHONE_CALL },
  { title: EMAIL },
]

const VerifiedAddDiary = ({ userId, navigate }) => {
  const initialValue = {
    id: uuidv4(),
    type: INTERVIEW,
    job: '',
    companyName: '',
    contactPerson: '',
    contactEmail: '',
    date: null,
    notes: '',
    createdAt: new Date(),
  }
  const [params, setParams] = useSearchParams()
  const diaryId = params.get('index')
  const { loading: diaryLoading, data } = useGetDiary(userId, diaryId)

  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ ...initialValue, type: '' })
  const [typeOptions, setTypeOptions] = useState(options)

  const handleChange = (newValue, field) => {
    if (newValue === TRIAL_APPRENTICESHIP && field === 'type') {
      setErrors({ ...initialValue, type: '' })
      const temp = {
        ...value,
        [field]: field === 'type' ? newValue : newValue || '',
        date: { from: null, to: null },
        place: '',
      }
      setValue(temp)
    } else if (
      field === 'type' &&
      newValue !== TRIAL_APPRENTICESHIP &&
      value.type === TRIAL_APPRENTICESHIP
    ) {
      setErrors({ ...initialValue, type: '' })
      const temp = { ...value }
      temp[field] = field === 'type' ? newValue : newValue || ''
      temp.date = null
      delete temp.place
      setValue(temp)
    } else {
      setValue({
        ...value,
        [field]: field === 'type' ? newValue : newValue || '',
      })
    }
  }

  const handleDateChange = (parsedDate, enteredValue, field) => {
    if (field === '') {
      const date =
        parsedDate !== 'Invalid date'
          ? moment(parsedDate, 'DD/MM/YYYY')
          : moment(enteredValue, 'DD/MM/YYYY')
      setValue({
        ...value,
        date: date['_d'],
      })
    } else {
      const date =
        parsedDate !== 'Invalid date'
          ? moment(parsedDate, 'DD/MM/YYYY')
          : moment(enteredValue, 'DD/MM/YYYY')
      setValue({
        ...value,
        date: { ...value.date, [field]: date['_d'] },
      })
    }
  }

  useEffect(() => {
    if (data?.type) setValue(data)
  }, [data])

  return (
    <EditSkills
      pageTitle='Mein Lehrstell-Journal'
      onUpdateClick={() =>
        updateDiary(value, setLoading, userId, diaryId, navigate)
      }
      loading={diaryLoading}
      disabled={loading || diaryLoading}
    >
      <Button
        title={translation.back}
        titleClasses='text-[#458EFF] text-lg font-semibold'
        containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#E9F1FC]'
        onClick={() => navigate(-1)}
      />
      <SimpleEditWrapper
        pageTitle={translation.diary}
        showAddIcon={false}
        customized={true}
      >
        <h1 className='text-center font-medium'>
          Hier kannst du Tagebucheinträge hinzufügen: Wähle den Typ aus, fülle
          die Felder aus und klicke dann auf "aktualisieren"
        </h1>
        <div className='flex gap-4 flex-col'>
          <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
            <AutoComplete
              label={translation.type}
              options={typeOptions}
              setOptions={setTypeOptions}
              value={value.type || ''}
              handleChange={(value) => handleChange(value, 'type')}
              error={errors.type ? true : false}
              helperText={errors.type}
              disablePortal
              controlled={false}
            />
          </div>
          <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
            <CustomInput
              label={translation.job}
              type='text'
              value={value.job}
              handleChange={(...props) => handleChange(...props, 'job')}
              error={errors.job ? true : false}
              helperText={errors.job}
            />
            <CustomInput
              label={translation.companyName}
              type='text'
              value={value.companyName}
              handleChange={(...props) => handleChange(...props, 'companyName')}
              error={errors.companyName ? true : false}
              helperText={errors.companyName}
            />
            <CustomInput
              label={translation.contactPerson}
              type='text'
              value={value.contactPerson}
              handleChange={(...props) =>
                handleChange(...props, 'contactPerson')
              }
              error={errors.contactPerson ? true : false}
              helperText={errors.contactPerson}
            />
            {value.type === TRIAL_APPRENTICESHIP && (
              <CustomInput
                label={translation.place}
                type='text'
                value={value.place}
                handleChange={(...props) => handleChange(...props, 'place')}
                error={errors.place ? true : false}
                helperText={errors.place}
              />
            )}
            <CustomInput
              label={translation.contactEmail}
              type='text'
              value={value.contactEmail}
              handleChange={(...props) =>
                handleChange(...props, 'contactEmail')
              }
              error={errors.contactEmail ? true : false}
              helperText={errors.contactEmail}
            />
          </div>
          <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
            <DatePicker
              label={
                value.type === TRIAL_APPRENTICESHIP
                  ? translation.from
                  : translation.date
              }
              inputFormat='dd/MM/yyyy'
              value={
                Object(value.date).hasOwnProperty('from')
                  ? value.date.from
                  : value.date
              }
              handleChange={(...props) =>
                handleDateChange(
                  ...props,
                  value.type === TRIAL_APPRENTICESHIP ? 'from' : ''
                )
              }
              error={
                Object(errors.date).hasOwnProperty('from')
                  ? errors.date.from
                    ? true
                    : false
                  : errors.date
                  ? true
                  : false
              }
              helperText={
                Object(errors.date).hasOwnProperty('from')
                  ? errors.date.from
                  : errors.date
              }
            />
            {value.type === TRIAL_APPRENTICESHIP && (
              <DatePicker
                label={translation.to}
                inputFormat='dd/MM/yyyy'
                value={value?.date?.to || value?.date?.from || null}
                handleChange={(...props) => handleDateChange(...props, 'to')}
                error={
                  Object(errors.date).hasOwnProperty('to')
                    ? errors.date.to
                      ? true
                      : false
                    : errors.date
                    ? true
                    : false
                }
                helperText={
                  Object(errors.date).hasOwnProperty('to')
                    ? errors.date.to
                    : errors.date
                }
              />
            )}
          </div>
          <CustomInput
            label={translation.notes}
            value={value.notes}
            handleChange={(...props) => handleChange(...props, 'notes')}
            error={errors.notes ? true : false}
            helperText={errors.notes}
          />
        </div>
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default AddDiary

import CookieIcon from 'assets/icons/cookie'
import Button from 'components/Button'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { translation } from 'utils/germanTranslation'

const CookiePopup = () => {
  const [showAcceptCookies, setShowAcceptCookies] = useState(false)

  useEffect(() => {
    const visited = localStorage.getItem('visited')
    if (visited) {
      setShowAcceptCookies(true)
    }
  }, [])

  const cookieDocument = require(`../../assets/documents/Datenschutzerklärung_rechtliche_dokumente.pdf`)

  const openCookieConsentPdf = () => window.open(cookieDocument, '_blank')

  return (
    <>
      {!showAcceptCookies ? (
        <div className='fixed bottom-0 z-30 w-full bg-white rounded-md shadow-md mt-6 p-4 text-softBlack'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center gap-4'>
              <CookieIcon />
              <h2 className='font-semibold'>
                Deine Privatsphäre ist uns sehr wichtig
              </h2>
            </div>
            <Button
              title={translation.accept}
              onClick={() => {
                localStorage.setItem('visited', true)
                setShowAcceptCookies(true)
              }}
              containerClasses='bg-[#458EFF] text-white rounded-full h-[22px] px-2'
              titleClasses='text-center px-1 text-xs sm:text-base'
            />
          </div>

          <p className='text-sm'>
            Wir verwenden Cookies und Targeting Technologien gemäss unserer
            Datenschutzerklärung, zur Unterstützung der Benutzerfreundlichkeit
            und personalisiertem Inhalt. Mit der weiteren Nutzung der Webseite
            erklärst du dich damit einverstanden.{' '}
            <button
              onClick={openCookieConsentPdf}
              className='text-blue-500 cursor-all-scroll'
            >
              Unsere Datenschutzerklärung
            </button>
          </p>
        </div>
      ) : null}
    </>
  )
}

export default CookiePopup

import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { jobCategories, jobTypes } from 'utils'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useGetJobs = (category, search) => {
  let temp = {}
  jobTypes.forEach((category) => (temp[category] = []))
  const [value, setValue] = useState(!category ? temp : [])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData()
  }, [category, search])

  const getData = async () => {
    try {
      const url = search ? API_URL.job.searchJob + search : API_URL.job.allJobs
      const { data: jobs } = await axios.get(BACKEND_URL + url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      let allJobs = JSON.parse(jobs.data)
      setValue(allJobs)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError('Network Error')
    }
  }

  return { loading, data: value, refetch: getData, error }
}

export default useGetJobs

import React from 'react';

const Button = ({
  backgroundColor,
  containerClasses,
  title,
  titleClasses,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`flex items-center justify-center ${
        disabled ? 'opacity-40' : 'cursor-pointer button'
      }  ${containerClasses} `}
      style={{ backgroundColor }}
      disabled={disabled}
      {...props}
    >
      <p className={titleClasses}>{title}</p>
    </button>
  );
};

export default Button;

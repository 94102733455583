export const getGreetingBasedOnGender = (
  gender,
  recipient,
  GENDERS,
  UNKNOWN_GENDER
) => {
  return Object.keys(GENDERS).includes(gender)
    ? gender !== UNKNOWN_GENDER
      ? [GENDERS[gender].description, recipient?.lastName]
          .filter(Boolean)
          .join(' ')
      : GENDERS[gender].description
    : ''
}

export const MALE_GENDER = 'Herr'
export const FEMALE_GENDER = 'Frau'
export const UNKNOWN_GENDER = 'Unbekannt'

export const GENDERS = {
  [MALE_GENDER]: { title: 'Mr.', description: 'Sehr geehrter Herr' },
  [FEMALE_GENDER]: { title: 'Ms.', description: 'Sehr geehrte Frau' },
  [UNKNOWN_GENDER]: {
    title: 'Unknown',
    description: 'Sehr geehrte Damen und Herren',
  },
}

export const getRecipientGender = (value) =>
  value?.recipient?.gender !== UNKNOWN_GENDER && value?.recipient?.gender

import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material'
import { IconButton, InputAdornment, useMediaQuery } from '@mui/material'
import CustomInput from 'components/Input'
import React, { useEffect, useRef, useState } from 'react'

export const allKantons = [
  {
    id: 16,
    year: 2023,
    code: '19',
    name: 'Aargau',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 19,
    year: 2023,
    code: '15',
    name: 'Appenzell Ausserrhoden',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 20,
    year: 2023,
    code: '16',
    name: 'Appenzell Innerrhoden',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 11,
    year: 2023,
    code: '13',
    name: 'Basel-Land',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 14,
    year: 2023,
    code: '12',
    name: 'Basel-Stadt',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 9,
    year: 2023,
    code: '02',
    name: 'Bern',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 7,
    year: 2023,
    code: '10',
    name: 'Fribourg',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 25,
    year: 2023,
    code: '25',
    name: 'Genève',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 4,
    year: 2023,
    code: '08',
    name: 'Glarus',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 12,
    year: 2023,
    code: '18',
    name: 'Graubünden',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 10,
    year: 2023,
    code: '26',
    name: 'Jura',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 5,
    year: 2023,
    code: '03',
    name: 'Luzern',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 23,
    year: 2023,
    code: '24',
    name: 'Neuchâtel',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 26,
    year: 2023,
    code: '07',
    name: 'Nidwalden',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 24,
    year: 2023,
    code: '06',
    name: 'Obwalden',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 22,
    year: 2023,
    code: '14',
    name: 'Schaffhausen',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 18,
    year: 2023,
    code: '05',
    name: 'Schwyz',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 15,
    year: 2023,
    code: '11',
    name: 'Solothurn',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 17,
    year: 2023,
    code: '17',
    name: 'St. Gallen',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 6,
    year: 2023,
    code: '20',
    name: 'Thurgau',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 3,
    year: 2023,
    code: '21',
    name: 'Ticino',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 8,
    year: 2023,
    code: '04',
    name: 'Uri',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 2,
    year: 2023,
    code: '23',
    name: 'Valais',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 1,
    year: 2023,
    code: '22',
    name: 'Vaud',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 13,
    year: 2023,
    code: '09',
    name: 'Zug',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 21,
    year: 2023,
    code: '01',
    name: 'Zürich',
    type: 'Kanton',
    country: 'CHE',
  },
  {
    id: 123,
    year: 2023,
    code: '011',
    name: 'keine Angabe',
    type: 'Kanton',
    country: 'CHE',
  },
]

const SelectKanton = ({ search, setSearch, isLocationSpecified }) => {
  // const media = useMediaQuery()
  const matches = useMediaQuery('(max-width:640px)')

  // console.log(media)
  const [kantons, setKantons] = useState(allKantons)
  const [showKantons, setShowKantons] = useState(false)

  const wrapperRef = useRef(null)

  const handleKantonChange = (value) => {
    const kantonName = value.trim()
    setSearch(kantonName)
  }

  const handleKantonSelect = (kanton) => {
    setSearch(kanton.name)
    setShowKantons(false)
  }

  useEffect(() => {
    if (search) {
      const filteredKantons = allKantons.filter((kanton) =>
        kanton.name.toLowerCase().includes(search.toLowerCase())
      )
      setKantons(filteredKantons)
    } else {
      setKantons(allKantons)
    }
  }, [search])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowKantons(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [wrapperRef])

  return (
    <div className='relative' ref={wrapperRef}>
      <CustomInput
        value={search}
        handleChange={handleKantonChange}
        onFocus={() => setShowKantons(true)}
        disabled={!isLocationSpecified}
        inputProps={{ autoComplete: 'off' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={() => handleKantonChange('')}
                className={
                  !search
                    ? 'opacity-0 pointer-events-none'
                    : 'opacity-100 cursor-pointer'
                }
              >
                <Close />
              </IconButton>
              <IconButton
                onClick={() => setShowKantons((prevValue) => !prevValue)}
              >
                {showKantons ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showKantons && (
        <div
          style={{
            display: kantons.length ? 'grid' : 'none',
            gridTemplateRows: `repeat(${
              matches
                ? kantons.length > 14
                  ? '14'
                  : kantons.length
                : kantons.length > 7
                ? '7'
                : kantons.length
            }, minmax(0, 1fr))`,
          }}
          className='kantons-list absolute grid grid-flow-col border-2 rounded p-1 sm:p-2 bg-gray-100 gap-1 w-[16rem] sm:w-[26rem] z-10'
        >
          {kantons.map((kanton) => (
            <button
              key={kanton.id}
              className='text-left text-xs hover:bg-gray-300 px-0 py-[1px] sm:p-[2px] rounded'
              onClick={() => handleKantonSelect(kanton)}
            >
              {kanton.name}
            </button>
          ))}
        </div>
      )}{' '}
    </div>
  )
}

export default SelectKanton

import React, { useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import Button from 'components/Button'
import CustomTable from 'components/Table'
import { Link, useNavigate } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import { translation } from 'utils/germanTranslation'
import NothingToShow from '../NothingToShow'
import ZoomInstructionsButton from 'components/ZoomInstructionsButton'
import ZoomInstructionsModal from 'components/ZoomInstructionsModal'

const DetailPageWithTable = ({
  pageTitle,
  buttons,
  loading,
  rows,
  HeaderTemplate,
  rowTemplate,
  sortingOptions = [],
  sortBy,
  handleSortBy,
  AddButton,
  emptyPageMessage,
  titleIcon,
  showZoomInstructions = true,
  isClassTeacher = false,
}) => {
  const navigate = useNavigate()
  const [showInstructionsModal, setShowInstructionsModal] = useState(false)

  return (
    <Box className='max-w-[1760px] m-auto px-4 sm:px-8 w-full py-10 bg-softWhite min-h-screen text-softBlack'>
      <Box className='flex flex-col xs:flex-row items-start xs:items-center justify-between gap-4'>
        <Typography className='font-bold text-3xl flex items-center gap-5'>
          <span>
            {pageTitle}{' '}
            <span className='opacity-30'>({!loading && rows.length})</span>
          </span>
          {titleIcon}
        </Typography>
        <Box className='flex flex-row items-center justify-center gap-6 w-full xs:w-auto'>
          {buttons.map((button, index) => (
            <div key={index} className='w-full xs:w-auto'>
              {button.link ? (
                <Link to={button.link}>
                  <Button key={index} {...button} />
                </Link>
              ) : (
                button
              )}
            </div>
          ))}
        </Box>
      </Box>
      <div className='flex justify-between items-end sm:items-center flex-col sm:flex-row gap-4'>
        <Button
          title={translation.back}
          titleClasses='text-[#458EFF] text-lg font-semibold'
          containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#E9F1FC] mt-4 xs:mt-2'
          onClick={() => navigate(-1)}
        />
        {!isClassTeacher && showZoomInstructions && (
          <ZoomInstructionsButton
            containerClasses=''
            onClick={() => setShowInstructionsModal(true)}
          />
        )}
      </div>
      {sortingOptions.length ? (
        <div className='flex items-center justify-end gap-2 w-full mt-4'>
          <div className='w-full sm:w-56 flex items-center'>
            <span className='w-full'>{translation.sortBy}:</span>
            <CustomSelect
              options={sortingOptions}
              value={sortBy}
              handleChange={handleSortBy}
              disabled={loading || !rows.length}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='mt-4'>
        {rows.length ? (
          <CustomTable
            loading={loading}
            data={rows}
            headerTemplate={<HeaderTemplate />}
            rowTemplate={(row, index) => rowTemplate(row, index)}
          />
        ) : loading ? (
          <div className='flex items-center justify-center h-screen'>
            <CircularProgress />
          </div>
        ) : (
          <NothingToShow title={emptyPageMessage} />
        )}
        {!isClassTeacher && AddButton}
      </div>
      <ZoomInstructionsModal
        showInstructionsModal={showInstructionsModal}
        setShowInstructionsModal={setShowInstructionsModal}
      />
    </Box>
  )
}

export default DetailPageWithTable

import * as React from 'react';

const ProfileIcon = () => (
  <svg width={122} height={130} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M.422 60.578a60.58 60.58 0 1 0 121.158 0 60.58 60.58 0 0 0-121.158 0Z'
      fill='#F9EDB0'
    />
    <path
      d='M10.158 60.57a50.242 50.242 0 1 0 100.485 0 50.242 50.242 0 0 0-100.485 0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
    />
    <path
      d='M61.14 81.621a31.693 31.693 0 0 0-29.513 20.141 50.255 50.255 0 0 0 58.691-.822 31.688 31.688 0 0 0-29.177-19.319v0Z'
      fill='#F0DB6C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M68.285 76.24a20.475 20.475 0 0 1-14.307 0v6.19a31.452 31.452 0 0 0-6.575 2.309l8.244 4.834c.247.145.46.34.628.574l3.85 5.39a1.239 1.239 0 0 0 2.014 0l3.848-5.39c.168-.233.383-.429.63-.574l8.242-4.834a31.452 31.452 0 0 0-6.574-2.31v-6.19Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M50.34 97.178a1.773 1.773 0 0 0 2.377-.794l3.274-6.549a2 2 0 0 0-.353-.263l-8.243-4.834a31.723 31.723 0 0 0-6.751 4.392 27.779 27.779 0 0 0 9.695 8.048v0ZM71.912 97.178a1.773 1.773 0 0 1-2.376-.794l-3.274-6.549c.108-.1.226-.189.354-.263l8.242-4.834a31.686 31.686 0 0 1 6.751 4.392 27.781 27.781 0 0 1-9.697 8.048v0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M70.352 23.371c0 5.083-4.121 43.973-9.204 43.973-5.082 0-9.205-38.89-9.205-43.973 0-5.082 4.121-10.227 9.205-10.227s9.204 5.143 9.204 10.227Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
    />
    <path
      d='M81.576 51.964v-5.113a20.452 20.452 0 0 0-40.904 0v5.113a4.09 4.09 0 1 0 0 8.178c.085 0 .165-.02.25-.025a20.425 20.425 0 0 0 40.404 0c.085 0 .165.025.25.025a4.09 4.09 0 1 0 0-8.178v0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M46.813 60.66a2.556 2.556 0 1 0 5.112 0 2.556 2.556 0 0 0-5.112 0ZM70.33 60.66a2.557 2.557 0 1 0 5.113 0 2.557 2.557 0 0 0-5.113 0Z'
      fill='#F0DB6C'
    />
    <path
      d='M67.263 65.851a8.84 8.84 0 0 1-12.267 0M56.03 54.01a3.324 3.324 0 0 0-6.136 0M72.368 54.01a3.324 3.324 0 0 0-6.136 0'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M40.684 51.964V50.94s0-2.046 3.069-2.046h14.316a4.006 4.006 0 0 0 4.089-4.089c0-3.713 0-10.796 8.414-12.071a2.444 2.444 0 0 1 2.808 2.368c.093 5.281 1.103 16.862 8.207 16.862.84 0 1.658.26 2.343.744a23.522 23.522 0 1 0-45.585 0 4.055 4.055 0 0 1 2.34-.745v0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M42.726 65.957c.068 4.433-1.101 7.525-2.553 7.525-1.412 0-2.557-3.435-2.557-7.67 0-4.237 1.145-7.67 2.557-7.67M79.555 65.96c-.067 4.433 1.1 7.522 2.553 7.522 1.411 0 2.556-3.435 2.556-7.67 0-4.237-1.145-7.67-2.556-7.67'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M66.064 126.661c0 .885 2.522 1.735 7.01 2.361 4.488.626 10.575.978 16.922.978 6.347 0 12.434-.352 16.922-.978 4.488-.626 7.009-1.476 7.009-2.361 0-.886-2.521-1.735-7.009-2.362-4.488-.626-10.575-.978-16.922-.978-6.347 0-12.434.352-16.922.978-4.488.627-7.01 1.476-7.01 2.362Z'
      fill='#F0DB6C'
    />
    <path
      d='M115.064 100.224a5.06 5.06 0 0 0-5.06-5.06h-1.695a3.376 3.376 0 0 1-3.157-2.189l-.889-2.368a3.376 3.376 0 0 0-3.158-2.19H89.757a3.373 3.373 0 0 0-3.165 2.19l-.888 2.368a3.375 3.375 0 0 1-3.159 2.189H69.518a5.06 5.06 0 0 0-5.06 5.06v20.239a5.06 5.06 0 0 0 5.06 5.06h40.478a5.06 5.06 0 0 0 5.068-5.06v-20.239Z'
      fill='#F0DB6C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M75.29 91.794h-4.81a.969.969 0 0 0-.967.97v2.403h6.745v-2.403a.969.969 0 0 0-.968-.97ZM106.908 112.827c.15.026.302.042.455.047a3.372 3.372 0 0 0 3.302-3.374 3.372 3.372 0 0 0-3.302-3.373c-.153.005-.305.02-.455.045v6.655Z'
      fill='#F0DB6C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M82.064 109.497a12.65 12.65 0 1 0 25.298 0 12.65 12.65 0 0 0-25.298 0v0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M102.295 109.502a7.587 7.587 0 0 1-9.07 7.446 7.595 7.595 0 0 1-5.965-5.964 7.591 7.591 0 1 1 15.035-1.482Z'
      fill='#F0DB6C'
    />
    <path
      d='M102.295 109.502a7.587 7.587 0 0 1-9.07 7.446 7.595 7.595 0 0 1-5.965-5.964 7.591 7.591 0 1 1 15.035-1.482v0ZM71.955 97.381v21.927'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M80.391 119.619a2.533 2.533 0 0 1-1.56 2.34 2.532 2.532 0 0 1-3.308-3.306 2.527 2.527 0 0 1 2.338-1.563 2.532 2.532 0 0 1 2.53 2.529Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ProfileIcon;

import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

export const updateReferences = async (value, setLoading, id, navigate) => {
  setLoading(true);
  try {
    const { data } = await axios.put(
      BACKEND_URL + API_URL.student.references,
      { value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    );
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
    navigate(-1);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

import React from 'react'

const JobDetailCard = ({ containerClasses, icon, title, data }) => {
  return (
    <div
      className={`bg-white shadow rounded-2xl flex flex-col-reverse sm:flex-row ${containerClasses}`}
    >
      <div className='w-full'>
        <div className='flex items-center pb-6 gap-3'>
          {icon && <img src={icon} alt='expression' className='w-8' />}
          <h1 className=' text-lg sm:text-2xl font-bold'>{title}</h1>
        </div>
        <p className='text-sm sm:text-base whitespace-pre-wrap'>{data}</p>
      </div>
    </div>
  )
}

export default JobDetailCard

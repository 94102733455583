import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/Input'
import React from 'react'
import { multiBannerOptions } from './constants'
import { InputAdornment } from '@mui/material'
import AutoComplete from 'components/AutoComplete'

const BannerForm = ({
  companies = [],
  company,
  multiBanner,
  firstUrl,
  secondUrl,
  handleChange,
  setCompanies,
}) => {
  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='flex gap-4'>
        <AutoComplete
          label='Werbekunde'
          value={company}
          options={companies}
          setOptions={setCompanies}
          width='w-full'
          handleChange={(newValue) => handleChange(newValue, 'company')}
          controlled={false}
        />
        <CustomSelect
          label='Multibanner'
          value={multiBanner}
          options={multiBannerOptions}
          handleChange={(value) => handleChange(value, 'multiBanner')}
        />
      </div>
      <CustomInput
        label='URL 1 - (Verlinkung zur Unternehmenspage)'
        value={firstUrl}
        width='w-full'
        handleChange={(newValue) => handleChange(newValue, 'firstUrl')}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>https://</InputAdornment>
          ),
        }}
      />
      <CustomInput
        label='URL 2 - (Verlinkung zur Unternehmenspage)'
        value={secondUrl}
        width='w-full'
        handleChange={(newValue) => handleChange(newValue, 'secondUrl')}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>https://</InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default BannerForm

import React from 'react'
import Tag from 'components/Tag'
import AddIcon from 'assets/icons/add'
import { translation } from 'utils/germanTranslation'
import NothingToShow from 'components/Student/NothingToShow'

const SimpleEditWrapper = ({
  value,
  fields,
  children,
  pageTitle,
  addAnotherField,
  showAddIcon = true,
  customized = false,
  containerClasses = 'p-6',
  heading,
}) => {
  return (
    <div
      className={`bg-white flex flex-col rounded-2xl gap-4 w-full ${containerClasses}`}
    >
      <p className='font-semibold text-lg'>{heading}</p>
      {!customized ? (
        <>
          {value.length > 0 && (
            <div className='flex '>
              {fields.map((title, index) => (
                <p key={index} className='hidden sm:block w-[49%] capitalize'>
                  {title}
                </p>
              ))}
            </div>
          )}
          {value.length > 0 ? children : <NothingToShow />}
        </>
      ) : (
        children
      )}

      {showAddIcon && (
        <Tag
          icon={<AddIcon />}
          showBorder={false}
          titleClasses='text-sm font-semibold'
          title={`${pageTitle} ${translation.add.toLowerCase()}`}
          containerClasses='pt-4'
          onClick={addAnotherField}
        />
      )}
    </div>
  )
}

export default SimpleEditWrapper

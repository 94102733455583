import * as React from 'react';

const AddHobbies = () => (
  <svg width={97} height={82} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M93.05 48.966a44.001 44.001 0 1 0-84.08 18.15h80.16a43.829 43.829 0 0 0 3.92-18.15Z'
      fill='#AEE0D2'
      fillOpacity={0.91}
    />
    <path
      d='M5.6 67.116h85.8M.65 67.116H3.4M93.6 67.116h2.75'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.35 41.86A20.707 20.707 0 0 0 22.29 62.566v4.565h42.117v-4.565a20.706 20.706 0 0 0-21.059-20.704Z'
      fill='#AEE0D2'
    />
    <path
      d='M43.35 41.86A20.707 20.707 0 0 0 22.29 62.566v4.565h42.117v-4.565a20.706 20.706 0 0 0-21.059-20.704ZM30.013 67.128V60.81M56.687 67.128V60.81'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m48.39 42.428-.028-.044a20.673 20.673 0 0 0-5.017-.523 20.708 20.708 0 0 0-5.028.525l-.016.037-1.549 6.742 6.6 17.963 6.6-17.963-1.562-6.737Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.35 38.348h-4.913v7.448l4.913 3.275 4.914-3.275v-7.448H43.35Z'
      fill='#fff'
    />
    <path
      d='M43.35 38.348h-4.913v7.448l4.913 3.275 4.914-3.275v-7.448H43.35Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m43.35 49.579-3.519 13.337 3.519 4.212 3.519-4.212L43.35 49.58Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m48.503 53.004-5.153-3.425-5.154 3.425'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M57.389 22.91v-3.52a14.04 14.04 0 0 0-28.079 0v3.52a2.81 2.81 0 0 0-2.645 3.895 2.808 2.808 0 0 0 2.645 1.72c.06 0 .11-.014.172-.017a14.02 14.02 0 0 0 27.734 0c.058 0 .11.017.173.017a2.809 2.809 0 0 0 0-5.615Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M33.523 28.179a1.754 1.754 0 1 0 3.508 0 1.754 1.754 0 0 0-3.508 0ZM49.667 28.179a1.755 1.755 0 1 0 3.511 0 1.755 1.755 0 0 0-3.511 0Z'
      fill='#AEE0D2'
    />
    <path
      d='M39.126 31.744a6.123 6.123 0 0 0 8.447 0M39.84 23.616a2.288 2.288 0 0 0-4.223 0M51.083 23.606a2.288 2.288 0 0 0-4.223 0'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M53.44 3.718C50.761.15 35.935.152 33.26 3.718c-11.11 2.471-7.243 21.477-6.108 20.344a2.673 2.673 0 0 1 3.098-.456c1.87-2.808 2.57-2.808 2.57-7.019 1.549.929 18.46.54 19.34-6.05-.301 5.207 1.82 9.043 4.29 13.074a2.675 2.675 0 0 1 3.099.455c1.133 1.129 5.004-17.876-6.11-20.348Z'
      fill='#fff'
    />
    <path
      d='M53.44 3.718C50.761.15 35.935.152 33.26 3.718c-11.11 2.471-7.243 21.477-6.108 20.344a2.673 2.673 0 0 1 3.098-.456c1.87-2.808 2.57-2.808 2.57-7.019 1.549.929 18.46.54 19.34-6.05-.301 5.207 1.82 9.043 4.29 13.074a2.675 2.675 0 0 1 3.099.455c1.133 1.129 5.004-17.876-6.11-20.348Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m38.436 41.857-1.684 7.308 6.6 17.963h-3.513l-5.436-3.777a1.403 1.403 0 0 1-.44-1.81l1.946-3.68-5.17-4.432a1.403 1.403 0 0 1-.412-1.525c1.31-3.782 2.244-8.81 8.109-10.047Z'
      fill='#AEE0D2'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m48.264 41.857 1.683 7.308-6.6 17.963h3.51l5.436-3.777a1.404 1.404 0 0 0 .44-1.81l-1.946-3.68 5.17-4.432a1.404 1.404 0 0 0 .413-1.525c-1.307-3.782-2.241-8.81-8.106-10.047Z'
      fill='#AEE0D2'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M81.552 63.26a1.274 1.274 0 0 0-1.274-1.272H72a1.274 1.274 0 0 1-1.272-1.274v-8.278a1.274 1.274 0 0 0-1.274-1.272h-6.367a1.272 1.272 0 0 0-1.277 1.272v8.278a1.274 1.274 0 0 1-1.273 1.274h-8.274a1.273 1.273 0 0 0-1.273 1.272v6.367a1.274 1.274 0 0 0 1.273 1.274h8.277a1.272 1.272 0 0 1 1.27 1.274v8.276a1.275 1.275 0 0 0 1.274 1.274h6.366a1.275 1.275 0 0 0 1.274-1.274v-8.276a1.274 1.274 0 0 1 1.273-1.274h8.277a1.273 1.273 0 0 0 1.274-1.274l.004-6.367Z'
      fill='#fff'
    />
    <path
      d='M81.552 63.26a1.274 1.274 0 0 0-1.274-1.272H72a1.274 1.274 0 0 1-1.272-1.274v-8.278a1.274 1.274 0 0 0-1.274-1.272h-6.367a1.272 1.272 0 0 0-1.277 1.272v8.278a1.274 1.274 0 0 1-1.273 1.274h-8.274a1.273 1.273 0 0 0-1.273 1.272v6.367a1.274 1.274 0 0 0 1.273 1.274h8.277a1.272 1.272 0 0 1 1.27 1.274v8.276a1.275 1.275 0 0 0 1.274 1.274h6.366a1.275 1.275 0 0 0 1.274-1.274v-8.276a1.274 1.274 0 0 1 1.273-1.274h8.277a1.273 1.273 0 0 0 1.274-1.274l.004-6.367Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AddHobbies;

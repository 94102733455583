import React from 'react'
import { Box } from '@mui/system'
import { Modal } from '@mui/material'

const CustomModal = ({
  open,
  handleClose,
  containerClasses,
  children,
  maxWidth = '693px',
  maxHeight = '625px',
  padding = '16px',
  height = 'auto',
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      {...props}
    >
      <Box
        padding={padding}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        height={height}
        className={`bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-md rounded-md w-full ${containerClasses}`}
      >
        {children}
      </Box>
    </Modal>
  )
}

export default CustomModal

const NEIN = 'nein'
const HORIZONTAL_BANNER = 'Horizontal teilen'
const VERTICAL_BANNER = 'Vertikal teilen'

export const multiBannerMapping = {
  [NEIN]: 'null',
  [HORIZONTAL_BANNER]: 'Horizontal',
  [VERTICAL_BANNER]: 'Vertical',
}

export const multiBannerOptions = [NEIN, HORIZONTAL_BANNER, VERTICAL_BANNER]

const LARGE_TOP_RIGHT_BANNER_TYPE = 'Beruf gross'
const SMALL_BOTTOM_LEFT_BANNER_TYPE = 'Beruf klein'
export const LANDING_PAGE_BANNER_TYPE = 'Landing Page'
export const JOB_OVERVIEW_PAGE = 'Berufe Übersicht'
export const JOB_CATEGORY = 'Job Category'

export const bannerTypeMapping = {
  [SMALL_BOTTOM_LEFT_BANNER_TYPE]: 'bottom',
  [LARGE_TOP_RIGHT_BANNER_TYPE]: 'top',
  [LANDING_PAGE_BANNER_TYPE]: 'landingPage',
  [JOB_OVERVIEW_PAGE]: 'jobsOverviewPage',
  [JOB_CATEGORY]: 'jobCategory',
}

export const bannerTypeOptions = [
  SMALL_BOTTOM_LEFT_BANNER_TYPE,
  LARGE_TOP_RIGHT_BANNER_TYPE,
  LANDING_PAGE_BANNER_TYPE,
  JOB_OVERVIEW_PAGE,
  JOB_CATEGORY,
]

import { Box } from '@mui/system'
import AdminClassStatsIcon from 'assets/icons/adminClassStats'
import AdminDownloadStatsIcon from 'assets/icons/adminDownloadStats'
import AdminEmailStatsIcon from 'assets/icons/adminEmailStats'
import AdminStudentsStatsIcon from 'assets/icons/adminStudentStats'
import AdminTeacherStatsIcon from 'assets/icons/adminTeacherStatsIcon'
import useGetClassesCount from 'hooks/useGetClassesCount'
import useGetDownloadsCount from 'hooks/useGetDownloadsCount'
import useGetEmailCount from 'hooks/useGetEmailCount'
import useGetStudentsCount from 'hooks/useGetStudentsCount'
import useGetTeachersCount from 'hooks/useGetTeachersCount'
import React from 'react'
import VerifyAdmin from '../VerifyAdmin'
import AdminStatsCard from './AdminStatsCard'
import { translation } from 'utils/germanTranslation'
import useGetBannersCount from 'hooks/useGetBannersCount'

const AdminDashboard = () => (
  <VerifyAdmin>
    <VerifiedAdminDashboard />
  </VerifyAdmin>
)

const VerifiedAdminDashboard = () => {
  const { data: teachersCount } = useGetTeachersCount()
  const { data: studentsCount } = useGetStudentsCount()
  const { data: classesCount } = useGetClassesCount()
  const { data: emailsCount } = useGetEmailCount()
  const { data: downloadsCount } = useGetDownloadsCount()
  const { data: bannerCount } = useGetBannersCount()
  return (
    <div className='w-full h-full bg-softWhite'>
      <Box className='max-w-[1760px] min-h-screen px-4 pt-12 sm:px-8 w-full gap-4'>
        <div className='flex items-center justify-between'>
          <h1 className='text-3xl font-bold'>{translation.dashboard}</h1>
        </div>
        <div className='grid grid-cols-none sm:grid-cols-2 xl:grid-cols-3 pt-6 gap-4'>
          <AdminStatsCard
            title={translation.teacher}
            icon={<AdminTeacherStatsIcon />}
            count={teachersCount}
          />
          <AdminStatsCard
            title={translation.student}
            icon={<AdminStudentsStatsIcon />}
            count={studentsCount}
          />
          <AdminStatsCard
            title={translation.classes}
            icon={<AdminClassStatsIcon />}
            count={classesCount}
          />
          <AdminStatsCard
            title='Emails'
            icon={<AdminEmailStatsIcon />}
            count={emailsCount}
          />
          <AdminStatsCard
            title='Downloads'
            icon={<AdminDownloadStatsIcon />}
            count={downloadsCount}
          />
          <AdminStatsCard
            title='Werbebanner'
            icon={<AdminDownloadStatsIcon />}
            count={bannerCount}
          />
        </div>
      </Box>
    </div>
  )
}

export default AdminDashboard

import AutoComplete from 'components/AutoComplete'
import CustomInput from 'components/Input'
import React from 'react'
import StepTitle from './StepTitle'
import { translation } from 'utils/germanTranslation'
import { GENDERS } from './utils'

export const TrialApprenticeship = 'Schnupperlehre'
export const Apprenticeship = 'Lehrstelle'

export const MotivationLetterTypeOptions = [TrialApprenticeship, Apprenticeship]

const Step1 = ({ value, handleChange, step }) => {
  return (
    <>
      <StepTitle step={step} />
      <div className='flex gap-4 flex-col mt-5'>
        <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6 '>
          <AutoComplete
            label='Ich schreibe ein Motivationsschreiben für eine:'
            options={MotivationLetterTypeOptions}
            value={value.type || null}
            handleChange={(value) => handleChange(value, 'type')}
            disablePortal
            labelClasses='overflow-visible'
          />
        </div>
        <h1 className='text-softBlack font-medium'>Firmeninformation</h1>
        <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
          <CustomInput
            label={translation.companyName}
            type='text'
            value={value.company.name}
            handleChange={(newValue) =>
              handleChange(newValue, 'company', 'name')
            }
          />
          <CustomInput
            label='Adresse der Firma'
            type='text'
            value={value.company.address}
            handleChange={(newValue) =>
              handleChange(newValue, 'company', 'address')
            }
          />
          <CustomInput
            label={
              value.type === TrialApprenticeship
                ? 'Postleitzahl der Firma'
                : 'Postleitzahl des Unternehmens'
            }
            type='text'
            value={value.company.postcode}
            handleChange={(newValue) =>
              handleChange(newValue, 'company', 'postcode')
            }
          />
          <CustomInput
            label='Ort der Firma'
            type='text'
            value={value.company.city}
            handleChange={(newValue) =>
              handleChange(newValue, 'company', 'city')
            }
          />
        </div>
        <h1 className='text-softBlack font-medium'>Berufsinformation</h1>
        <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
          <CustomInput
            label='Beruf'
            type='text'
            value={value.job.title}
            handleChange={(newValue) => handleChange(newValue, 'job', 'title')}
          />
          <AutoComplete
            label='EBA oder EFZ'
            options={['EBA', 'EFZ']}
            value={value.job.type || null}
            handleChange={(value) => handleChange(value, 'job', 'type')}
            disablePortal
          />
        </div>
        <h1 className='text-softBlack font-medium'>
          An wen schreibst du den Brief?
        </h1>
        <div className='grid grid-cols-none sm:grid-cols-2 lg:grid-cols-4 gap-6'>
          <AutoComplete
            label='Geschlecht'
            options={Object.keys(GENDERS)}
            value={value.recipient.gender || null}
            handleChange={(value) => handleChange(value, 'recipient', 'gender')}
            disablePortal
          />
          <CustomInput
            label={translation.firstName}
            type='text'
            value={value.recipient.firstName}
            handleChange={(newValue) =>
              handleChange(newValue, 'recipient', 'firstName')
            }
          />
          <CustomInput
            label={translation.lastName}
            type='text'
            value={value.recipient.lastName}
            handleChange={(newValue) =>
              handleChange(newValue, 'recipient', 'lastName')
            }
          />
        </div>
      </div>
    </>
  )
}

export default Step1

import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import CustomInput from 'components/Input'
import React, { useState } from 'react'
import DeleteIcon from 'assets/icons/delete'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import axios from 'axios'
import { Box, Modal } from '@mui/material'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'
import { ROUTES } from 'utils/routes'
import { translation } from 'utils/germanTranslation'
import useGetBack from 'hooks/useGetBack'
import { ShowToast } from 'utils/toastMessages'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const AddClass = () => {
  const loggedInUser = useSelector(user)

  const navigate = useNavigate()
  const errorEmailsInitialState = { teachers: [] }
  const [emptyClassModal, setEmptyClassModal] = useState(false)
  const [errorEmails, setErrorEmails] = useState(errorEmailsInitialState)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setErrorEmails(errorEmailsInitialState)
    navigate(ROUTES({ classId: value.id }).teacher.classes.overview)
  }

  const { classId } = useParams()
  const initialValues = {
    id: '',
    className: '',
    schoolName: '',
    schoolPlace: '',
    schoolZipCode: '',
    admins: [
      {
        id: loggedInUser.id,
        email: loggedInUser.email,
        isAvailable: false,
        role: 'admin1',
      },
      { id: '', email: '', isAvailable: false, role: 'admin2' },
    ],
    teachers: [],
  }
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValues)
  const onBackButtonEvent = useGetBack(value)
  const pageTitle = 'Klasse hinzufügen'

  const handleTempErrorEmails = (role, email, tempErrorEmails) => {
    return {
      ...tempErrorEmails,
      [role]: [...tempErrorEmails[role], email],
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    let tempErrorEmails = { admins: [], teachers: [] }
    let nonExistentMemberEmails = []
    const emails = { admins: [], teachers: [] }

    // Checking for the class admins availability
    for (let index = 0; index < value.admins.length; index++) {
      if (value.admins[index].email) {
        const data = await checkMemberAvailability(
          value.admins[index].email,
          'teachers'
        )
        if (data.success) {
          emails.admins.push(data.data.id)
        } else {
          if (!data.userExists) {
            nonExistentMemberEmails.push(value.admins[index].email)
          }
          tempErrorEmails = handleTempErrorEmails(
            'admins',
            value.admins[index].email,
            tempErrorEmails
          )
        }
      }
    }

    // Checking for the class teachers availability
    for (let index = 0; index < value.teachers.length; index++) {
      if (value.teachers[index].email) {
        const data = await checkMemberAvailability(
          value.teachers[index].email,
          'teachers'
        )
        if (data.success) {
          emails.teachers.push(data.data.id)
        } else {
          if (!data.userExists) {
            nonExistentMemberEmails.push(value.teachers[index].email)
          }
          tempErrorEmails = handleTempErrorEmails(
            'teachers',
            value.teachers[index].email,
            tempErrorEmails
          )
        }
      }
    }

    const { data } = await axios.post(
      BACKEND_URL + API_URL.classes.add,
      {
        data: {
          className: value.className,
          emails: emails,
          nonExistentMemberEmails,
          errorEmails: tempErrorEmails,
          schoolName: value.schoolName,
          schoolPlace: value.schoolPlace,
          schoolZipCode: value.schoolZipCode,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    if (Boolean(data.success)) {
      toast.success(
        `${value.className} erfolgreich erstellt` || 'Class added successfully',
        {
          position: 'top-center',
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        }
      )
      setValue({ ...value, id: data?.classId })
    }
    setErrorEmails(tempErrorEmails)
    handleOpen()
    setLoading(true)
  }

  const handleAddMember = async (memberField) => {
    setValue({
      ...value,
      [memberField]: [...value[memberField], { email: '', isAvailable: false }],
    })
  }

  const handleMemberChange = (newValue, index, memberField, field) => {
    if (value[memberField][index].isAvailable) {
      setValue({
        ...value,
        [memberField]: [
          ...value[memberField].slice(0, index),
          {
            email: newValue,
            isAvailable: false,
            id: '',
          },
          ...value[memberField].slice(index + 1),
        ],
      })
    } else {
      setValue({
        ...value,
        [memberField]: [
          ...value[memberField].slice(0, index),
          {
            ...value[memberField][index],
            [field]: newValue,
          },
          ...value[memberField].slice(index + 1),
        ],
      })
    }
  }

  const handleDelete = (index, memberField) => {
    setValue({
      ...value,
      [memberField]: [
        ...value[memberField].slice(0, index),
        ...value[memberField].slice(index + 1),
      ],
    })
  }

  const checkMemberAvailability = async (email, memberField) => {
    const url = API_URL.classes.checkTeacherAvailability
    const { data } = await axios.post(
      BACKEND_URL + url,
      { email, classId: classId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    return data
  }

  return (
    <>
      <EditSkills
        pageTitle={pageTitle}
        onUpdateClick={() => {
          if (
            value.schoolName.trim() === '' &&
            value.schoolPlace.trim() === '' &&
            value.schoolZipCode.trim() === ''
          ) {
            return ShowToast({
              message:
                'Bitte alle Pflichtfelder ausfüllen (Name/Postleitzahl/Ort)',
            })
          }
          // Check if every field is empty
          let allTeachersEmpty = true
          value.teachers.forEach((teacher) => {
            if (teacher.email) {
              allTeachersEmpty = false
            }
          })
          if (value.className === '' && allTeachersEmpty) {
            setEmptyClassModal(true)
          } else {
            handleSubmit()
          }
        }}
        disabled={loading}
        submitButtonTitle='Klasse erstellen'
      >
        <SimpleEditWrapper customized showAddIcon={false}>
          <div className='grid grid-cols-none sm:grid-cols-3 gap-6 items-center '>
            <CustomInput
              label={translation.className}
              value={value.className}
              handleChange={(newValue) =>
                setValue({ ...value, className: newValue })
              }
            />
          </div>
          <div className='grid grid-cols-3 gap-3'>
            <CustomInput
              label={translation.schoolName}
              value={value.schoolName}
              handleChange={(newValue) =>
                setValue({ ...value, schoolName: newValue })
              }
            />
            <CustomInput
              label={translation.postalCode}
              value={value.schoolZipCode}
              handleChange={(newValue) =>
                setValue({ ...value, schoolZipCode: newValue })
              }
            />
            <CustomInput
              label='Ort der Schule'
              value={value.schoolPlace}
              handleChange={(newValue) =>
                setValue({ ...value, schoolPlace: newValue })
              }
            />
          </div>
          <SimpleEditWrapper
            customized={true}
            showAddIcon={false}
            containerClasses=''
            heading={
              <div>
                <h1 className='font-semibold'>Lehrperson</h1>
                <p className='text-sm'>
                  (weitere Adminlehrperson kann auch später noch hinzugefügt
                  werden)
                </p>
              </div>
            }
          >
            <div className='col-span-full grid grid-cols-3'>
              <SimpleEditWrapper
                customized
                showAddIcon
                addAnotherField={() => handleAddMember('teachers')}
                pageTitle={'Lehrperson mit Leserechten'}
                containerClasses='p-0 col-span-full'
              >
                <div className='grid grid-cols-none sm:grid-cols-2 gap-4'>
                  {value?.admins?.map((admin, index) => (
                    <CustomInput
                      key={index}
                      label={
                        index === 1
                          ? 'Emailadresse einer weiteren Lehrperson mit Adminrechten'
                          : ` E-Mail-Adresse der 1. Adminlehrperson`
                      }
                      type='email'
                      value={admin.email}
                      handleChange={(newValue) => {
                        handleMemberChange(newValue, index, 'admins', 'email')
                      }}
                      disabled={admin.id === loggedInUser.id}
                    />
                  ))}
                </div>
                <p>
                  (verlässt die 1. Adminlehrperson die Klasse, wird die 2.
                  Adminlehrperson automatisch zum Administrator)
                </p>
                {value?.teachers?.map((teacher, index) => {
                  return (
                    <div key={index}>
                      <div className=' gap-6 grid grid-cols-none sm:grid-cols-2 md:grid-cols-3 items-center '>
                        <div className='relative'>
                          <CustomInput
                            label={`Email von ${translation.teacher} ${
                              index + 1
                            }`}
                            type='email'
                            value={teacher.email}
                            handleChange={(newValue) => {
                              handleMemberChange(
                                newValue,
                                index,
                                'teachers',
                                'email'
                              )
                            }}
                          />
                        </div>
                        <div
                          className='mt-4 mx-auto sm:mx-0'
                          onClick={() => handleDelete(index, 'teachers')}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </SimpleEditWrapper>
            </div>
          </SimpleEditWrapper>
          <SimpleEditWrapper
            customized={true}
            showAddIcon={false}
            containerClasses=''
            heading='Schüler*innen'
          >
            <div className='text-[#245DC4] text-[16.6px]'>
              <h1 className='font-bold'>
                Vorgehen: Schüler*innen in Klasse hinzufügen
              </h1>
              <div className='ml-6 mt-2 font-medium'>
                <p>
                  <span className='mr-3'>1.</span> SchülerInnen erstellen ein
                  eigenes Profil/ registrieren sich auf Lehrstell.ch
                </p>
                <p>
                  <span className='mr-3'>2.</span> Lehrperson erstellt eine
                  Klasse
                </p>
                <p>
                  <span className='mr-3'>3.</span> Lehrperson generiert in der
                  Klassenübersicht einen Klassencode und teilt diesen den
                  Schüler*innen
                </p>
              </div>
            </div>
          </SimpleEditWrapper>
        </SimpleEditWrapper>
      </EditSkills>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='flex flex-col gap-2'>
          <h1 className='text-center'>
            <span className='font-bold text-lg'>"{value.className}"</span> wurde
            erfolgreich erstellt.
          </h1>

          {errorEmails?.teachers?.length > 0 ? (
            <>
              <div>
                Im Folgenden sind die E-Mails aufgeführt, die dem Kurs nicht
                hinzugefügt werden konnten
              </div>
              {errorEmails?.admins?.length > 0 && (
                <div>
                  <p>Emails der Admin</p>
                  {errorEmails?.admins?.map((teacher, index) => (
                    <p className='list-item list-inside' key={index}>
                      {teacher}
                    </p>
                  ))}
                </div>
              )}
              {errorEmails?.teachers?.length > 0 && (
                <div>
                  <p>Emails der {translation.teacher}:</p>
                  {errorEmails?.teachers?.map((teacher, index) => (
                    <p className='list-item list-inside' key={index}>
                      {teacher}
                    </p>
                  ))}
                </div>
              )}
            </>
          ) : (
            ''
          )}
          <Button
            title='Ok'
            onClick={() =>
              navigate(
                ROUTES({ userId: loggedInUser.id, classId: value.id }).teacher
                  .classes.overview
              )
            }
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px]'
          />
        </Box>
      </Modal>
      <CustomModal open={emptyClassModal}>
        {/* Modal for empty class */}
        <h1
          className='
          text-center font-bold text-lg   
          '
        >
          Sie sind dabei, eine Klasse ohne Schüler oder Lehrer zu erstellen.
          Bist du dir sicher, dass du weitermachen willst?
        </h1>
        <div className='flex justify-center gap-2'>
          <Button
            title={translation.cancel}
            onClick={() => setEmptyClassModal(false)}
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] px-4'
          />
          <Button
            title={translation.sure}
            onClick={() => {
              setEmptyClassModal(false)
              handleSubmit()
            }}
            containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] px-4'
          />
        </div>
      </CustomModal>
    </>
  )
}
export default AddClass

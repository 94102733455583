import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { NEWEST } from './VerifiedEditTrial'

export const updateTrial = async (
  value,
  setLoading,
  id,
  navigate,
  sortBy = NEWEST,
  onBackButtonEvent
) => {
  try {
    const { data } = await axios.post(
      BACKEND_URL + API_URL.trial.add,
      { sortBy: sortBy || NEWEST, value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
    window.removeEventListener('popstate', onBackButtonEvent)
    navigate(-2)
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
  }
}

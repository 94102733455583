import * as React from 'react';

const UploadJobImage = () => (
  <svg width={53} height={52} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x={0.5}
      width={52}
      height={52}
      rx={26}
      fill='#C7DCFC'
      fillOpacity={0.54}
    />
    <path
      d='M30.304 37.958h-7.607c-5.728 0-8.178-2.45-8.178-8.178v-.152c0-5.18 2.041-7.676 6.615-8.108a.9.9 0 0 1 .956.793.877.877 0 0 1-.793.957c-3.663.338-5.028 2.065-5.028 6.37v.152c0 4.748 1.68 6.428 6.428 6.428h7.607c4.748 0 6.428-1.68 6.428-6.428v-.152c0-4.328-1.388-6.055-5.122-6.37a.874.874 0 0 1-.793-.945.867.867 0 0 1 .945-.793c4.643.396 6.72 2.905 6.72 8.12v.151c0 5.705-2.45 8.155-8.178 8.155Z'
      fill='#458EFF'
    />
    <path
      d='M26.5 30.375a.881.881 0 0 1-.875-.875V16.223c0-.478.397-.875.875-.875s.875.397.875.875V29.5a.881.881 0 0 1-.875.875Z'
      fill='#458EFF'
    />
    <path
      d='M30.409 19.7a.865.865 0 0 1-.619-.257l-3.29-3.29-3.29 3.29a.88.88 0 0 1-1.236 0 .88.88 0 0 1 0-1.236l3.908-3.909a.88.88 0 0 1 1.236 0l3.909 3.909a.88.88 0 0 1 0 1.236.846.846 0 0 1-.619.257Z'
      fill='#458EFF'
    />
  </svg>
);

export default UploadJobImage;

// import './styles.css';
import { Paragraph, Document, Packer, TextRun } from 'docx'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { getSiblingsInOrder, getSortedAcademicHistory } from 'utils'
import { NoTrialCompleted } from './CVGenerator'

export default function Test({
  language = [
    {
      language: 'Italian',
      proficiency: 'School Knowledge (From 3rd Year)',
    },
    {
      language: 'English',
      proficiency: 'School Knowledge (From 3rd Year)',
    },
  ],
  hobbies = [
    {
      title: 'Cricket',
      description: 'I love playing cricket',
    },
  ],
  academicHistory = [
    {
      startingYear: 2022,
      endingYear: 2021,
      schoolName: 'UET',
      place: 'Lahore',
    },
  ],
  trial = [
    {
      startingYear: '2023-02-19T19:00:00.000Z',
      endingYear: '2023-02-13T19:00:00.000Z',
      job: 'Ebiz',
      company: 'Developer',
      place: 'Lahore',
    },
  ],
  personalData = {
    id: '218374af-db6e-4a76-830f-8ca934dd55c6',
    firstName: 'Burhan',
    lastName: 'Haroon',
    email: 'mirzaburhan0007@gmail.com',
    nationality: 'Pakistan',
    phone: '03164180039',
    postalCode: '54000',
    address: '860 Ravi Block',
    place: 'Lahore',
    fatherFirstName: 'Harrooon',
    fatherLastName: 'Ali',
    fatherOccupation: 'Clerk',
    motherFirstName: 'Mother',
    motherLastName: 'Name',
    motherOccupation: 'Numen',
    siblings: [{ siblingName: 'Abdullah', siblingOccupation: 'brother' }],
    strengths: [''],
    weaknesses: [''],
    dateOfBirth: '2023-02-14T19:00:00.000Z',
    aboutMeVideo: {},
    references: [
      {
        firstName: 'Burhan',
        lastName: 'Haroon',
        designation: 'Clerk',
        contactNumber: '23123213',
        email: 'gejixi9089@otanhome.com',
      },
    ],
  },
}) {
  const siblings = getSiblingsInOrder(personalData)
  const sortedEducation = getSortedAcademicHistory(academicHistory)

  const AcademicHistoryTemplate = (sortedEducation) => {
    let temp = []
    sortedEducation.forEach((item, index) => {
      temp.push(
        ...title(
          `${item.startingYear} - ${item.endingYear}`,
          `${item.schoolName}, ${item.place}`,
          true,
          false
        )
      )
    })
    return temp
  }
  const title = (heading = '', value = '', tabs = true, bold = true) => {
    let tempHeading = `${heading}${tabs ? '\t\t' : ''}`
    let result = [
      new TextRun({
        text: `${tempHeading}\t\t`,
        bold,
        break: 1,
        font: 'Calibri',
        size: 24,
      }),
      new TextRun({
        text: value,
        font: 'Calibri',
        size: 24,
      }),
    ]

    return result
  }

  const LineBreak = () =>
    new TextRun({
      text: `\t\t\t\t\t\t\t\t\t\t\t\t`,
      bold: true,
      break: 1,
      underline: {
        color: '000000',
        type: 'single',
      },
    })

  const LanguagesTemplate = () => {
    let temp = []
    language.forEach((item, index) => {
      temp.push(
        ...title(`${item.language}\t`, `${item.proficiency}`, true, false)
      )
    })
    return temp
  }

  const HobbiesTemplate = () => {
    let temp = []
    hobbies.forEach((item, index) => {
      temp.push(...title(`${item.title}\t`, `${item.description}`, true, false))
    })
    return temp
  }

  const TrialTemplate = () => {
    let temp = []
    trial.forEach((item, index) => {
      temp.push(
        ...title(
          `${moment(item.startingYear).format('DD.MM')} - ${moment(
            item.endingYear
          ).format('DD.MM.YYYY')}\t`,
          item.job + ', ' + item.company + ', ' + item.place,
          false,
          false
        )
      )
    })
    return temp.length ? temp : title(NoTrialCompleted)
  }

  const ReferencesTemplate = () => {
    let temp = []
    personalData.references.forEach((item, index) => {
      temp.push(
        ...title(
          `${item.firstName} ${item.lastName} ${
            item.designation ? '(' + item.designation + ')' : ''
          }`,
          '',
          false,
          false
        )
      )
      temp.push(...title(`Telefon: ${item.contactNumber}`, '', false, false))
      temp.push(...title(`${item.email}`, '', false, false))
    })
    return temp
  }

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Lebenslauf',
                  bold: true,
                  font: 'Calibri',
                  size: 40,
                }),
                new TextRun({
                  break: 2,
                  text: '',
                }),
                ...title('Vorname', personalData?.firstName),
                ...title('Nachname', personalData?.lastName),
                ...title('Address', personalData?.address),
                ...title('Telefon', personalData?.phone),
                ...title('Email\t', personalData?.email),
                ...title('Nationalität', personalData.nationality),
                ...title(
                  'Geburtsdatum\t',
                  personalData?.dateOfBirth
                    ? `${moment(personalData?.dateOfBirth).date()}, ${moment(
                        personalData?.dateOfBirth
                      ).format('MMMM')} ${moment(
                        personalData?.dateOfBirth
                      ).year()}`
                    : '',
                  false
                ),
                ...title(
                  'Eltern\t',
                  `${personalData?.fatherFirstName} ${
                    personalData?.fatherLastName
                  } ${
                    personalData?.fatherOccupation &&
                    '(' + personalData?.fatherOccupation + ')'
                  }`
                ),
                ...title(
                  '\t',
                  `${personalData?.motherFirstName} ${
                    personalData?.motherLastName
                  } ${
                    personalData?.motherOccupation &&
                    '(' + personalData?.motherOccupation + ')'
                  }`
                ),
                ...(siblings !== '' ? title('Geschwister', siblings) : null),

                LineBreak(),

                ...(academicHistory.length !== ''
                  ? title('Schulbildung')
                  : null),

                ...AcademicHistoryTemplate(sortedEducation),

                LineBreak(),

                ...(language.length ? title('Sprachen') : null),

                ...LanguagesTemplate(),

                LineBreak(),

                ...(hobbies.length ? title('Hobbies') : null),

                ...HobbiesTemplate(),

                LineBreak(),

                ...(trial.length ? title('Schnupperlehren') : null),

                ...TrialTemplate(),

                LineBreak(),

                ...(personalData.references.length
                  ? title('Referenzen')
                  : null),

                ...ReferencesTemplate(),
              ],
            }),
          ],
        },
      ],
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'example.docx')
    })
  }
  return (
    <div className='App'>
      <h1>Hello {title}</h1>
      <h2>Edit to see some magic happen!</h2>
      <button onClick={generate}>Generate doc</button>
    </div>
  )
}

import * as React from 'react';

const TasterDiaryIcon = () => (
  <svg width={136} height={130} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M64.976 130c35.808 0 64.837-29.101 64.837-65 0-35.898-29.029-65-64.837-65C29.167 0 .139 29.102.139 65c0 35.899 29.028 65 64.837 65Z'
      fill='#F9EDB0'
    />
    <path
      d='M95.556 106.123h-60.46a4.077 4.077 0 0 1-2.887-1.198 4.098 4.098 0 0 1-1.195-2.894V23.563c0-1.13.448-2.216 1.246-3.016a4.25 4.25 0 0 1 3.009-1.25h60.104c1.128 0 2.21.45 3.008 1.25a4.271 4.271 0 0 1 1.246 3.017v78.467a4.096 4.096 0 0 1-1.191 2.891 4.075 4.075 0 0 1-2.88 1.201Z'
      fill='#F0DB6C'
    />
    <path
      d='M95.556 106.123h-60.46a4.077 4.077 0 0 1-2.887-1.198 4.098 4.098 0 0 1-1.195-2.894V23.563c0-1.13.448-2.216 1.246-3.016a4.25 4.25 0 0 1 3.009-1.25h60.104c1.128 0 2.21.45 3.008 1.25a4.271 4.271 0 0 1 1.246 3.017v78.467a4.096 4.096 0 0 1-1.191 2.891 4.075 4.075 0 0 1-2.88 1.201Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M90.838 26.06H39.804c-1.127 0-2.04.917-2.04 2.046V97.31c0 1.13.913 2.046 2.04 2.046h51.034c1.127 0 2.04-.916 2.04-2.046V28.106c0-1.13-.913-2.046-2.04-2.046Z'
      fill='#fff'
    />
    <path
      d='M92.878 51.17 76.873 68.556a5.647 5.647 0 0 0 .324 7.962 5.535 5.535 0 0 0 2.431 1.31l-5.203 5.218.796.798-7.028 7.044a2.261 2.261 0 0 0 1.566 3.86c.163 0 .326-.017.486-.05 6.16-1.314 9.293-3.21 9.328-5.644l.425-.428 1.592 1.596 3.914-3.936a5.612 5.612 0 0 0 7.372-.271l.002-34.847Z'
      fill='#F0DB6C'
    />
    <path
      d='M90.838 26.06H39.804c-1.127 0-2.04.917-2.04 2.046V97.31c0 1.13.913 2.046 2.04 2.046h51.034c1.127 0 2.04-.916 2.04-2.046V28.106c0-1.13-.913-2.046-2.04-2.046ZM45.617 44.103h34.369M45.617 54.252h34.369M45.617 65.529h23.954'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M49.926 15.913h30.788c.54.001 1.058.218 1.44.601.38.384.595.903.595 1.445V28.52a2.05 2.05 0 0 1-.599 1.448 2.04 2.04 0 0 1-1.444.6H49.928a2.05 2.05 0 0 1-2.042-2.047V17.957a2.038 2.038 0 0 1 2.04-2.046Z'
      fill='#fff'
    />
    <path
      d='M49.926 15.913h30.788c.54.001 1.058.218 1.44.601.38.384.595.903.595 1.445V28.52a2.05 2.05 0 0 1-.599 1.448 2.04 2.04 0 0 1-1.444.6H49.928a2.05 2.05 0 0 1-2.042-2.047V17.957a2.038 2.038 0 0 1 2.04-2.046ZM55.76 26.06h19.123'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M65.88 20.424c3.106 0 5.624-2.525 5.624-5.64 0-3.113-2.518-5.638-5.625-5.638-3.106 0-5.624 2.525-5.624 5.639 0 3.114 2.518 5.639 5.624 5.639Z'
      fill='#fff'
    />
    <path
      d='M65.88 20.424c3.106 0 5.624-2.525 5.624-5.64 0-3.113-2.518-5.638-5.625-5.638-3.106 0-5.624 2.525-5.624 5.639 0 3.114 2.518 5.639 5.624 5.639Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m89.006 64.825 19.702 7.368 8.288-13.325-21.422-5.8-6.568 11.757Z'
      fill='#F9EDB0'
    />
    <path
      d='m120.413 87.31 15.26-15.298c.059-.057.122-.112.188-.162-5.108-4.443-6.367-13.855-16.19-18.997-12.93-6.77-28.922-5.154-42.97 12.432a3.766 3.766 0 0 0 1.826 6.037 3.744 3.744 0 0 0 4.01-1.309 54.942 54.942 0 0 1 2.808-3.225c.33.354.726.64 1.165.842 14.697 11.155 17.668 12.572 21.263 13.975 5.56 2.166 8.014 3.212 12.102 6.922.025-.458.216-.891.538-1.217Zm-10.069-23.811a8.315 8.315 0 0 1-10.458 1.989l-8.691-3.985c5.071-3.72 10.189-5.274 15.285-4.608 4.336.559 5.339 4.404 3.864 6.604Z'
      fill='#fff'
    />
    <path
      d='M64.958 121.999c13.472 0 24.393-1.263 24.393-2.821 0-1.558-10.921-2.821-24.393-2.821-13.472 0-24.393 1.263-24.393 2.821 0 1.558 10.92 2.821 24.393 2.821Z'
      fill='#F0DB6C'
    />
    <path
      d='M49.055 77.247a5.015 5.015 0 0 0-1.37 4.521 5.016 5.016 0 0 0 2.99 3.653 4.988 4.988 0 0 0 4.691-.463 5.007 5.007 0 0 0 2.222-4.167v1a4.018 4.018 0 0 0 2.45 3.779 3.99 3.99 0 0 0 4.406-.89 4.01 4.01 0 0 0 1.143-2.888c0 1.063.421 2.083 1.171 2.834a3.994 3.994 0 0 0 2.828 1.175h2.999'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M98.654 66.364a3.75 3.75 0 0 1 3.34 2.506c.164.466.235.96.208 1.454l-.659 12.21a3.775 3.775 0 0 1-1.242 2.597 3.75 3.75 0 0 1-4.128.592 3.757 3.757 0 0 1-1.918-2.144 3.77 3.77 0 0 1-.207-1.453l.66-12.208c.053-.996.499-1.93 1.239-2.597a3.747 3.747 0 0 1 2.707-.957Z'
      fill='#F9EDB0'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M94.774 65.19a3.772 3.772 0 0 1 1.548 5.09l-4.478 8.738a3.744 3.744 0 0 1-5.077 1.552 3.77 3.77 0 0 1-1.548-5.09l4.479-8.739a3.745 3.745 0 0 1 5.077-1.552Z'
      fill='#F9EDB0'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M135.861 71.85c-5.108-4.443-6.367-13.855-16.19-18.997-12.93-6.77-28.922-5.154-42.97 12.432a3.766 3.766 0 0 0 1.826 6.037 3.744 3.744 0 0 0 4.01-1.309 54.942 54.942 0 0 1 2.808-3.225c.33.354.726.64 1.165.842 14.697 11.155 17.668 12.572 21.263 13.975 5.56 2.166 8.014 3.212 12.102 6.922m-9.524-25.025a8.332 8.332 0 0 1-4.875 2.887 8.315 8.315 0 0 1-5.59-.901l-8.691-3.985c5.071-3.72 10.189-5.274 15.285-4.608 4.336.559 5.339 4.404 3.864 6.604l.007.003Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m107.092 64.246-4.892-6.9 15.237-10.851a3.823 3.823 0 0 1 5.339.907l.456.642a3.845 3.845 0 0 1-.905 5.352l-15.235 10.85Z'
      fill='#F0DB6C'
    />
    <path
      d='m107.092 64.246-4.892-6.9 15.237-10.851a3.823 3.823 0 0 1 5.339.907l.456.642a3.845 3.845 0 0 1-.905 5.352l-15.235 10.85Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m102.869 53.41 10.71-7.626a2.743 2.743 0 0 1 2.104-.506 2.754 2.754 0 0 1 1.82 1.172'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m78.243 76.712-7.523 5.363a.939.939 0 0 0 .57 1.71c3.155-.13 7.663-.704 7.596-2.911l1.53-1.089-2.173-3.073Z'
      fill='#F0DB6C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m80.969 80.548-2.717-3.835a.943.943 0 0 1 .222-1.312l3.824-2.723 3.804 5.362-3.836 2.722a.937.937 0 0 1-1.297-.214Z'
      fill='#fff'
    />
    <path
      d='m80.969 80.548-2.717-3.835a.943.943 0 0 1 .222-1.312l3.824-2.723 3.804 5.362-3.836 2.722a.937.937 0 0 1-1.297-.214Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='m85.553 77.279-2.717-3.829a1.881 1.881 0 0 1 .443-2.623l18.923-13.482 4.89 6.901-18.923 13.478a1.874 1.874 0 0 1-2.616-.445Z'
      fill='#F0DB6C'
    />
    <path
      d='m85.553 77.279-2.717-3.829a1.881 1.881 0 0 1 .443-2.623l18.923-13.482 4.89 6.901-18.923 13.478a1.874 1.874 0 0 1-2.616-.445Z'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M110.34 63.5a8.317 8.317 0 0 1-10.458 1.989l-10.26-4.713a3.744 3.744 0 0 0-4.16.633 3.762 3.762 0 0 0-1.206 2.643 3.772 3.772 0 0 0 1.033 2.717c.343.361.754.65 1.21.851 14.697 11.158 17.668 12.573 21.264 13.975'
      fill='#fff'
    />
    <path
      d='M110.34 63.5a8.317 8.317 0 0 1-10.458 1.989l-10.26-4.713a3.744 3.744 0 0 0-4.16.633 3.762 3.762 0 0 0-1.206 2.643 3.772 3.772 0 0 0 1.033 2.717c.343.361.754.65 1.21.851 14.697 11.158 17.668 12.573 21.264 13.975'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M110.34 63.5a8.317 8.317 0 0 1-10.458 1.989l-10.26-4.713a3.744 3.744 0 0 0-4.16.633 3.762 3.762 0 0 0-1.206 2.643 3.772 3.772 0 0 0 1.033 2.717c.343.361.754.65 1.21.851 14.697 11.158 17.668 12.573 21.264 13.975'
      fill='#fff'
    />
    <path
      d='M110.34 63.5a8.317 8.317 0 0 1-10.458 1.989l-10.26-4.713a3.744 3.744 0 0 0-4.16.633 3.762 3.762 0 0 0-1.206 2.643 3.772 3.772 0 0 0 1.033 2.717c.343.361.754.65 1.21.851 14.697 11.158 17.668 12.573 21.264 13.975'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default TasterDiaryIcon;

import axios from 'axios'
import { toast } from 'react-toastify'
import { ACADEMIC_HISTORY } from 'utils'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { translation } from 'utils/germanTranslation'

export const updateAcademicHistory = async (
  value,
  setLoading,
  id,
  setErrors,
  initialValue,
  navigate,
  onBackButtonEvent
) => {
  let validation = true
  let errors = Array.from({ length: value.length }).map(() => {
    return { ...initialValue }
  })
  value.forEach((item, index) => {
    if (!item.startingYear) {
      errors[index].startingYear = 'Starting year is not valid'
      validation = false
    }
    if (!item.endingYear) {
      errors[index].endingYear = 'Ending year is not valid'
      validation = false
    }
    if (!item.schoolName) {
      errors[index].schoolName = translation.REQUIRED_FIELD
      validation = false
    }
    if (!item.place) {
      errors[index].place = translation.REQUIRED_FIELD
      validation = false
    }
  })
  setErrors(errors)
  if (!validation) {
    return
  }
  setLoading(true)
  try {
    const { data } = await axios.put(
      BACKEND_URL + API_URL.student.skill + ACADEMIC_HISTORY,
      { value },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
    window.removeEventListener('popstate', onBackButtonEvent)
    navigate(-2)
  } catch (error) {
    toast.error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error.message,
      {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    )
    setLoading(false)
  }
}

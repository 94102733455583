import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { Box } from '@mui/system'
import CustomTable from 'components/Table'
import React, { useEffect, useState } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import userImage from 'assets/images/blank_user.png'
import CustomInput from 'components/Input'
import { useSearchParams } from 'react-router-dom'
import useGetAdminStudentOrTeacher from 'hooks/useGetAdminStudentOrTeacher'
import { NextIcon, PreviousIcon } from 'assets/icons/nextOrPrevious'
import VerifyAdmin from '../VerifyAdmin'
import CustomSelect from 'components/CustomSelect'
import axios from 'axios'
import { ShowToast } from 'utils/toastMessages'
import { translation } from 'utils/germanTranslation'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import TrashIcon from 'assets/icons/trash'

const resultsPerPage = 10
const NEXT = 'next'
const PREV = 'prev'

const StudentsAndTeachers = ({ role }) => (
  <VerifyAdmin>
    <VerifiedStudentsAndTeachers role={role} />
  </VerifyAdmin>
)

const SEARCH_BY_EMAIL = 'SEARCH_BY_EMAIL'
const SEARCH_BY_NAME = 'SEARCH_BY_NAME'
const NAME = 'Name'
const CLASSES = 'Classes'

const VerifiedStudentsAndTeachers = ({ role }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchInitialValue = {
    type: searchParams.get('type') || SEARCH_BY_NAME,
    query: searchParams.get('search') || '',
  }
  const [value, setValue] = useState([])
  const [search, setSearch] = useState(searchInitialValue)
  const [sortBy, setSortBy] = useState('')
  const [limit, setLimit] = useState(resultsPerPage)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)

  const [modalConfig, setModalConfig] = useState({
    show: false,
    userId: null,
  })

  const { data, loading, count, refetch } = useGetAdminStudentOrTeacher(
    role,
    search.query,
    limit,
    offset,
    true,
    search.type === SEARCH_BY_NAME ? true : false
  )

  useEffect(() => {
    const params = new URLSearchParams()
    if (search.query) {
      params.append('type', search.type.trim())
      params.append('search', search.query.trim())
    } else {
      params.delete('type')
      params.delete('search')
    }
    setSearchParams(params.toString().trim())
  }, [search, setSearchParams])

  const handlePageChange = (PAGE) => {
    if (PAGE === NEXT) {
      setOffset(offset + limit)
      setPage((prev) => prev + 1)
    } else if (PAGE === PREV) {
      setOffset(offset - limit)
      setPage((prev) => prev - 1)
    }
  }

  useEffect(() => {
    setOffset(0)
    setPage(1)
    setSearch(searchInitialValue)
    setSortBy('')
  }, [role])

  useEffect(() => {
    setValue(data)
  }, [data])

  const handleGotoPage = (e) => {
    const { value } = e.target
    if (value > 0 && value <= Math.ceil(count / limit)) {
      setOffset((value - 1) * limit)
      setPage(value)
    }
  }

  const deleteUser = async (id) => {
    try {
      const { data } = await axios.delete(
        BACKEND_URL + API_URL.admin.deleteUser + id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      refetch()
      ShowToast({
        type: 'success',
        message: `${
          role === 'Teachers' ? 'Lehrperson' : 'Schüler/in'
        } erfolgreich gelöscht`,
      })
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleSort = (type) => {
    if (type === NAME) {
      setSortBy(NAME)
      setValue((prev) => {
        return prev.sort((a, b) => {
          if (a.firstName > b.firstName) {
            return 1
          }
          if (a.firstName < b.firstName) {
            return -1
          }
          return 0
        })
      })
    }
    if (type === CLASSES) {
      setSortBy(CLASSES)
      setValue((prev) => {
        return prev.sort((a, b) => {
          if (a.classes > b.classes) {
            return 1
          }
          if (a.classes < b.classes) {
            return -1
          }
          return 0
        })
      })
    }
  }

  const handleCloseModal = () => {
    setModalConfig({
      show: false,
      userId: null,
    })
  }

  const isTeacher = role === 'Teachers'

  return (
    <div className='w-full h-full bg-softWhite'>
      <Box className='max-w-[1760px] min-h-screen px-4 py-10 sm:px-8 w-full gap-4'>
        <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 pb-9'>
          <h3 className='text-2xl font-bold '>
            {isTeacher ? translation.teacher : translation.student}{' '}
            <span className='opacity-40'>({count})</span>
          </h3>
          <div className='w-full sm:w-auto'>
            <CustomInput
              value={search.query}
              containerClasses='w-auto'
              placeholder={`Name des ${isTeacher ? 'Lehrers' : 'Schülers'}`}
              handleChange={(newValue) =>
                setSearch({ ...search, query: newValue })
              }
            />
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='demo-form-control-label-placement'
                name='position'
                value={search.type}
                onChange={(_, newValue) =>
                  setSearch({ ...search, type: newValue })
                }
                classes={{ root: 'flex justify-between' }}
              >
                <FormControlLabel
                  value={SEARCH_BY_EMAIL}
                  control={<Radio />}
                  label='Suche per E-Mail'
                  classes={{ label: 'text-sm sm:text-base' }}
                />
                <FormControlLabel
                  value={SEARCH_BY_NAME}
                  control={<Radio />}
                  label='Suche mit Name'
                  classes={{ label: 'text-sm sm:text-base' }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {!loading ? (
          <>
            <div className='flex items-center justify-end gap-2 w-full mb-4'>
              <div className='w-full sm:w-56 flex items-center'>
                <span className='w-full'>{translation.sortBy}:</span>
                <CustomSelect
                  options={[NAME, role === 'Teachers' && 'Classes']}
                  value={sortBy}
                  handleChange={handleSort}
                />
              </div>
            </div>
            <CustomTable
              data={value}
              headerTemplate={
                <TableHead className='bg-[#F0F2F9]'>
                  <TableRow>
                    <TableCell style={{ minWidth: '9rem' }}>
                      Name des {isTeacher ? 'Lehrers' : 'Schülers'}
                    </TableCell>
                    <TableCell style={{ minWidth: '9rem' }}>Email ID</TableCell>
                    <TableCell style={{ minWidth: '9rem' }}>
                      {isTeacher ? translation.classes : 'Klassenname'}
                    </TableCell>
                    <TableCell style={{ minWidth: '9rem' }}></TableCell>
                  </TableRow>
                </TableHead>
              }
              rowTemplate={(row, index) => (
                <TableRow key={index}>
                  <TableCell className='py-6' style={{ minWidth: '9rem' }}>
                    <div className='flex items-center gap-4'>
                      <div>
                        <img
                          src={
                            row?.avatar
                              ? BACKEND_URL + '/' + row?.avatar
                              : userImage
                          }
                          alt='user'
                          className='w-10 h-10 object-cover rounded-full'
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </div>
                      <div>{row?.firstName + ' ' + row?.lastName}</div>
                    </div>
                  </TableCell>
                  <TableCell className='py-6' style={{ minWidth: '9rem' }}>
                    {row?.email}
                  </TableCell>
                  <TableCell className='py-6' style={{ minWidth: '9rem' }}>
                    {role === 'Teachers'
                      ? row?.classes
                      : row?.classDetails?.name || 'N/A'}
                  </TableCell>
                  <TableCell className='py-6' style={{ minWidth: '9rem' }}>
                    <IconButton
                      onClick={() =>
                        setModalConfig({ show: true, userId: row.id })
                      }
                      className='text-red-500'
                    >
                      <TrashIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            />

            {data.length ? (
              <div className='flex items-center gap-4 justify-end mt-6'>
                <PreviousIcon
                  onClick={() => handlePageChange(PREV)}
                  disabled={offset === 0}
                />
                <div className='text-base flex items-center gap-2 font-medium select-none text-[#6B7280]'>
                  <CustomSelect
                    value={page}
                    handleChange={handleGotoPage}
                    length={Math.ceil(count / resultsPerPage)}
                  />
                  of {Math.ceil(count / resultsPerPage)}
                </div>
                <NextIcon
                  onClick={() => handlePageChange(NEXT)}
                  disabled={
                    data.length === 0 ||
                    count / resultsPerPage === page ||
                    count < resultsPerPage
                  }
                />
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <div className='flex items-center justify-center h-full'>
            <CircularProgress />
          </div>
        )}
      </Box>

      <CustomModal
        open={modalConfig.show}
        handleClose={handleCloseModal}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Willst du die Klasse wirklich löschen?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={() => {
                deleteUser(modalConfig.userId)
                handleCloseModal()
              }}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={handleCloseModal}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default StudentsAndTeachers

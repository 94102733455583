import React from 'react'
import VerifiedEditTrial from './VerifiedEditTrial'
import VerifyEditAccess from 'components/VerifyEditAccess'
import NoAccess from 'components/NoAccess'

const EditTrial = () => (
  <VerifyEditAccess>
    {(id, canEdit, navigate) =>
      canEdit ? <VerifiedEditTrial id={id} navigate={navigate} /> : <NoAccess />
    }
  </VerifyEditAccess>
)

export default EditTrial

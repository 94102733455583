import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const CustomTabs = ({ value, data, onClick }) => {
  return (
    <Box className='w-full'>
      <Tabs
        value={value}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#FFDF59',
            height: 4,
          },
        }}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        {data.map((item, index) => (
          <Tab
            sx={{
              '&.Mui-selected': {
                color: '#05060E',
                backgroundColor: '#FBEFBB',
                fontWeight: '900',
              },
            }}
            key={index}
            label={item}
            onClick={() => onClick(item)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default CustomTabs;

import React from 'react'

const VideoAvailableIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='80.62 32.11 63.64 45.26'
      // width={60}
      // height={40}
      {...props}
    >
      <defs>
        <clipPath id='a'>
          <path d='M80.617 34.793h54.442v42.582H80.617Zm0 0' />
        </clipPath>
        <clipPath id='b'>
          <path d='M126.855 56h8.625v18.867h-8.625Zm0 0' />
        </clipPath>
        <clipPath id='c'>
          <path d='M120.965 53.91h23.176v23.176h-23.176Zm0 0' />
        </clipPath>
      </defs>
      <g clipPath='url(#a)'>
        <path
          fill='#ff3131'
          d='M131.242 34.75H84.543a3.858 3.858 0 0 0-3.86 3.863v34.68a3.868 3.868 0 0 0 3.86 3.871h46.7a3.868 3.868 0 0 0 3.859-3.871v-34.68a3.858 3.858 0 0 0-3.86-3.863m-25.883 5.73c0-.714.582-1.292 1.297-1.292h2.461a1.3 1.3 0 0 1 1.305 1.292v2.465a1.31 1.31 0 0 1-1.305 1.305h-2.46c-.716 0-1.298-.59-1.298-1.305ZM91.352 71.414a1.31 1.31 0 0 1-1.305 1.305h-2.461a1.3 1.3 0 0 1-1.297-1.305v-2.46c0-.72.578-1.298 1.297-1.298h2.46c.716 0 1.306.578 1.306 1.297Zm0-28.469c0 .715-.59 1.305-1.305 1.305h-2.461c-.719 0-1.297-.59-1.297-1.305V40.48c0-.714.578-1.292 1.297-1.292h2.46c.716 0 1.306.578 1.306 1.292Zm9.535 28.47c0 .718-.586 1.304-1.305 1.304h-2.46a1.3 1.3 0 0 1-1.298-1.305v-2.46c0-.72.578-1.298 1.297-1.298h2.461a1.3 1.3 0 0 1 1.305 1.297Zm0-28.47a1.31 1.31 0 0 1-1.305 1.305h-2.46c-.72 0-1.298-.59-1.298-1.305V40.48c0-.714.578-1.292 1.297-1.292h2.461a1.3 1.3 0 0 1 1.305 1.292Zm.68 18.735V50.227c0-1.727 1.82-2.852 3.367-2.079l11.453 5.727c1.715.855 1.715 3.305 0 4.16l-11.453 5.723c-1.547.773-3.368-.352-3.368-2.078m8.856 9.734c0 .719-.586 1.305-1.305 1.305h-2.46a1.303 1.303 0 0 1-1.298-1.305v-2.46c0-.72.582-1.298 1.297-1.298h2.461a1.3 1.3 0 0 1 1.305 1.297Zm9.539 0a1.31 1.31 0 0 1-1.305 1.305h-2.46a1.3 1.3 0 0 1-1.298-1.305v-2.46c0-.72.579-1.298 1.297-1.298h2.461c.715 0 1.305.578 1.305 1.297Zm0-28.469c0 .715-.59 1.305-1.305 1.305h-2.46c-.72 0-1.298-.59-1.298-1.305V40.48c0-.714.579-1.292 1.297-1.292h2.461c.715 0 1.305.578 1.305 1.292Zm9.535 28.47c0 .718-.586 1.304-1.305 1.304h-2.46a1.3 1.3 0 0 1-1.297-1.305v-2.46c0-.72.578-1.298 1.296-1.298h2.461a1.3 1.3 0 0 1 1.305 1.297Zm0-28.47a1.31 1.31 0 0 1-1.305 1.305h-2.46c-.72 0-1.297-.59-1.297-1.305V40.48c0-.714.578-1.292 1.296-1.292h2.461a1.3 1.3 0 0 1 1.305 1.292Zm0 0'
        />
      </g>
      {/* <g clipPath='url(#b)'>
        <image
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAACbCAIAAAARNMXYAAAABmJLR0QA/wD/AP+gvaeTAAABfElEQVR4nO3RgQkAIRDAsPf33/lcQhBLMkGha2Y+3vffDuAMIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyOMjDAywsgIIyM2rE4EMy87Nq0AAAAASUVORK5CYII='
          width={60}
          height={40}
          transform='matrix(.2766 0 0 .2782 94.623 32.112)'
        />
      </g> */}
      <g clipPath='url(#c)'>
        <path
          fill='#545454'
          d='M144.258 65.555c-.008 6.41-5.18 11.648-11.653 11.644a11.626 11.626 0 0 1-11.636-11.648c0-6.465 5.23-11.637 11.644-11.64 6.43 0 11.645 5.218 11.645 11.644Zm-9.633 2.55c0-1.433-.004-2.867 0-4.3.004-.543-.191-1.004-.59-1.36-.633-.566-1.367-.683-2.156-.406a1.896 1.896 0 0 0-1.25 1.805c.016 2.86.008 5.718.004 8.578 0 1.613 1.383 1.887 2.043 1.883.996-.004 1.972-.805 1.953-1.957-.024-1.41-.004-2.828-.004-4.243Zm.137-9.168a2.139 2.139 0 0 0-2.149-2.144 2.145 2.145 0 0 0-2.152 2.156 2.157 2.157 0 0 0 2.152 2.145c1.192 0 2.149-.961 2.149-2.157Zm0 0'
        />
      </g>
    </svg>
  )
}

export default VideoAvailableIcon

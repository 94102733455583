import * as React from 'react';

const ShareProfileIcon = () => (
  <svg width={20} height={21} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.183 11.392a.637.637 0 0 1-.625-.559 6.528 6.528 0 0 0-2.783-4.675.627.627 0 0 1-.158-.866c.2-.284.591-.35.866-.159a7.78 7.78 0 0 1 3.309 5.567.632.632 0 0 1-.559.692h-.05ZM2.908 11.433h-.066a.638.638 0 0 1-.559-.691 7.796 7.796 0 0 1 3.259-5.575.628.628 0 1 1 .725 1.025 6.539 6.539 0 0 0-2.742 4.683.616.616 0 0 1-.617.558ZM10.05 19.341a7.765 7.765 0 0 1-3.508-.833.625.625 0 1 1 .567-1.117c1.8.909 3.966.925 5.783.05a.627.627 0 0 1 .833.292.627.627 0 0 1-.291.834 7.682 7.682 0 0 1-3.384.774ZM10.05 7.533a2.941 2.941 0 1 1 0-5.882 2.941 2.941 0 0 1 0 5.882Zm0-4.625a1.693 1.693 0 0 0 0 3.384c.933 0 1.692-.759 1.692-1.692 0-.933-.767-1.692-1.692-1.692ZM4.025 17.725a2.941 2.941 0 1 1-.001-5.882 2.941 2.941 0 0 1 0 5.882Zm0-4.633a1.693 1.693 0 0 0 0 3.383 1.693 1.693 0 0 0 0-3.383ZM15.975 17.725a2.941 2.941 0 1 1 2.941-2.942c-.008 1.617-1.325 2.942-2.941 2.942Zm0-4.633a1.693 1.693 0 0 0 0 3.383c.933 0 1.691-.758 1.691-1.692a1.698 1.698 0 0 0-1.691-1.691Z'
      fill='#fff'
    />
  </svg>
);

export default ShareProfileIcon;

import axios from 'axios'
import { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useFetchAllCompanies = () => {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchAllCompanies = useCallback(async () => {
    try {
      let url = BACKEND_URL + API_URL.banner.allCompanies
      const response = await axios.get(url)

      setCompanies(response.data)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchAllCompanies()
  }, [fetchAllCompanies])

  return { companies, loading, error, refetch: fetchAllCompanies, setCompanies }
}

export default useFetchAllCompanies

import TasterDiaryIcon from 'assets/icons/diary'
import JobInformationIcon from 'assets/icons/information'
import MotivationLetterStudentOptionsIcon from 'assets/icons/motivationLetterStudentOptions'
import ProfileIcon from 'assets/icons/profile'
import { translation } from 'utils/germanTranslation'
import { ROUTES } from 'utils/routes'

export const getStudentOptions = (userId) => {
  return [
    {
      title: 'Mein Profil',
      icon: <ProfileIcon />,
      link: ROUTES({ userId }).student.dashboard,
    },
    {
      title: 'Mein Lehrstell-Journal',
      icon: <TasterDiaryIcon />,
      link: ROUTES({ userId }).student.diary,
    },
    {
      title: translation.motivationLetterLibrary,
      icon: <MotivationLetterStudentOptionsIcon />,
      link: ROUTES({ userId }).student.motivationLetterLibrary,
    },
    {
      title: 'Informationen über Berufe',
      icon: <JobInformationIcon />,
      link: ROUTES({ userId }).student.job.all,
    },
  ]
}

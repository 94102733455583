import * as React from 'react';

const PhoneIcon = () => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.542 18.958c-.942 0-1.933-.225-2.958-.658-1-.425-2.009-1.009-2.992-1.717a26.016 26.016 0 0 1-2.808-2.391 24.898 24.898 0 0 1-2.392-2.8c-.717-1-1.292-2-1.7-2.967-.433-1.034-.65-2.034-.65-2.975 0-.65.117-1.267.342-1.842A4.45 4.45 0 0 1 2.5 1.991c.642-.633 1.375-.95 2.159-.95.325 0 .658.076.941.209.325.15.6.375.8.675L8.334 4.65c.175.241.308.475.4.708.108.25.166.5.166.742 0 .317-.091.625-.266.917a3.334 3.334 0 0 1-.559.708l-.566.591c.008.025.016.042.025.059.1.175.3.475.683.925.408.466.792.891 1.175 1.283.492.483.9.867 1.283 1.184.475.4.784.6.967.691l-.017.042.609-.6c.258-.258.508-.45.75-.575.458-.284 1.041-.333 1.625-.092.216.092.45.217.7.392l2.766 1.966c.309.209.534.476.667.792.125.317.183.609.183.9 0 .4-.091.8-.266 1.175-.175.375-.392.7-.667 1-.475.525-.992.9-1.592 1.142-.575.233-1.2.358-1.858.358ZM4.659 2.292c-.459 0-.884.2-1.292.6-.383.358-.65.75-.817 1.174a3.646 3.646 0 0 0-.258 1.384c0 .775.183 1.616.55 2.483.375.883.9 1.8 1.567 2.717a23.047 23.047 0 0 0 2.258 2.65 23.465 23.465 0 0 0 2.658 2.266c.9.659 1.825 1.192 2.742 1.575 1.425.609 2.758.75 3.858.292.425-.175.8-.442 1.142-.825.192-.208.342-.433.467-.7.1-.208.15-.425.15-.641a.995.995 0 0 0-.092-.417.631.631 0 0 0-.233-.25l-2.767-1.967a2.394 2.394 0 0 0-.458-.258c-.184-.075-.259-.15-.542.025a1.9 1.9 0 0 0-.483.375l-.634.625a1.188 1.188 0 0 1-1.208.25l-.225-.1c-.342-.184-.742-.467-1.183-.842-.4-.341-.834-.742-1.359-1.258A29.055 29.055 0 0 1 7.26 10.1c-.392-.458-.675-.85-.85-1.175l-.1-.25c-.05-.192-.067-.3-.067-.417 0-.3.108-.566.317-.775l.625-.65c.166-.167.291-.325.375-.466a.534.534 0 0 0 .091-.284.775.775 0 0 0-.066-.267 2.39 2.39 0 0 0-.267-.441L5.384 2.642a.768.768 0 0 0-.309-.259 1.044 1.044 0 0 0-.416-.091Zm6.966 10.216-.133.567.225-.583c-.042-.009-.075 0-.092.016Z'
      fill='#05060E'
    />
  </svg>
);

export default PhoneIcon;

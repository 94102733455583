import React from 'react';

const HomeIcon = ({ classes }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    // width='27'
    // height='27'
    className={classes}
    viewBox='0 0 27 27'
    fill='none'
  >
    <path
      d='M20.0138 25.5937H6.98625C3.90375 25.5937 1.40625 23.085 1.40625 20.0025V11.6662C1.40625 10.1362 2.35125 8.21247 3.56625 7.26747L9.63 2.54247C11.4525 1.12497 14.3662 1.05747 16.2562 2.38497L23.2087 7.25622C24.5475 8.18997 25.5938 10.1925 25.5938 11.8237V20.0137C25.5938 23.085 23.0963 25.5937 20.0138 25.5937ZM10.665 3.86997L4.60125 8.59497C3.8025 9.22497 3.09375 10.6537 3.09375 11.6662V20.0025C3.09375 22.1512 4.8375 23.9062 6.98625 23.9062H20.0138C22.1625 23.9062 23.9062 22.1625 23.9062 20.0137V11.8237C23.9062 10.7437 23.13 9.24747 22.2413 8.63997L15.2888 3.76872C14.0063 2.86872 11.8912 2.91372 10.665 3.86997Z'
      fill='#05060E'
    />
    <path
      d='M13.5 21.0938C13.0388 21.0938 12.6562 20.7112 12.6562 20.25V16.875C12.6562 16.4138 13.0388 16.0312 13.5 16.0312C13.9612 16.0312 14.3438 16.4138 14.3438 16.875V20.25C14.3438 20.7112 13.9612 21.0938 13.5 21.0938Z'
      fill='#05060E'
    />
  </svg>
);

export default HomeIcon;

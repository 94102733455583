import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, BACKEND_URL } from 'utils/apiConstants';

const useGetAllLanguages = (id) => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const { data: userData } = await axios.get(
        BACKEND_URL + API_URL.languages.all,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id,
          },
        }
      );
      const skills = JSON.parse(userData.data);
      setValue(skills);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Network Error');
    }
  };

  return { loading, data: value, refetch: getUserData, error };
};

export default useGetAllLanguages;

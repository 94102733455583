import CustomModal from 'components/CustomModal';
import useGetMotivationLetters from 'hooks/useGetMotivationLetters';
import React, { useState } from 'react';
import ChangeEmailTextScreen from './ChangeEmailTextScreen';
import SelectLetterScreen from './SelectLetterScreen';
import ShareProfileScreen from './ShareProfileScreen';
import DeleteDocumentScreen from './DeleteDocumentScreen';

const ShareProfileModal = ({
  value,
  setValue,
  initialValue,
  onSubmit,
  userId,
  user,
}) => {
  const [selectLetter, setSelectLetter] = useState(false);
  const [confirmDeleteDocument, setConfirmDeleteDocument] = useState(false);
  const { data: motivationLetters } = useGetMotivationLetters(userId);

  return (
    <CustomModal
      open={value?.show || selectLetter || value?.showChangeEmailTextModal}
      padding='0'
      handleClose={() =>
        selectLetter
          ? setSelectLetter(false)
          : value.showChangeEmailTextModal
          ? setValue({ ...value, showChangeEmailTextModal: false })
          : setValue(initialValue)
      }
      containerClasses={`${confirmDeleteDocument?'w-[30%]':'w-[80%]'}  p-0`}
    >
      {confirmDeleteDocument ? (
        <DeleteDocumentScreen
          value={value}
          setValue={setValue}
          setConfirmDeleteDocument={setConfirmDeleteDocument}
        />
      ) : selectLetter ? (
        <SelectLetterScreen
          letters={motivationLetters}
          setSelectLetter={setSelectLetter}
          setValue={setValue}
          user={user}
          value={value}
        />
      ) : value.showChangeEmailTextModal ? (
        <ChangeEmailTextScreen value={value} setValue={setValue} />
      ) : (
        <ShareProfileScreen
          value={value}
          setValue={setValue}
          onSubmit={onSubmit}
          user={user}
          setSelectLetter={setSelectLetter}
          setConfirmDeleteDocument={setConfirmDeleteDocument}
        />
      )}
    </CustomModal>
  );
};

export default ShareProfileModal;

import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import EditIcon from 'assets/icons/edit'

import TrashIcon from 'assets/icons/trash'
import { ROUTES } from 'utils/routes'
import { getFormattedDiaryDate } from 'utils'

const DiaryRowTemplate = ({
  index,
  row,
  userId,
  refetch,
  onDelete,
  isClassTeacher = false,
}) => {
  const date = getFormattedDiaryDate(row)

  return (
    <TableRow
      key={index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell className='py-4' component='th' scope='row'>
        {row?.job}
      </TableCell>
      <TableCell className='py-4'>{row?.companyName}</TableCell>
      <TableCell className='py-4 sm:table-cell'>
        {row?.contactPerson}
      </TableCell>
      <TableCell className='py-4 sm:table-cell'>
        {row?.contactEmail}
      </TableCell>
      <TableCell className='py-4'>
        <div className='bg-[#E2F1FF] rounded-full max-w-max px-3 py-1 text-[#2064E9] font-medium'>
          {row?.type}
        </div>
      </TableCell>
      <TableCell className='py-4 sm:table-cell'>{date}</TableCell>
      <TableCell className='py-4 sm:table-cell'>
        <p className='diary-notes'>{row?.notes}</p>
      </TableCell>
      {!isClassTeacher && (
        <TableCell className='py-4 flex gap-2'>
          <Link to={ROUTES({ userId, diaryId: index }).student.edit.diaryById}>
            <EditIcon />
          </Link>
          <button onClick={() => onDelete()}>
            <TrashIcon />
          </button>
        </TableCell>
      )}
    </TableRow>
  )
}

export default DiaryRowTemplate

import React from 'react'
import axios from 'axios'
import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import OTPInput from 'react-otp-input'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ShowToast } from 'utils/toastMessages'

const ClassInvitationModal = ({
  handleChange,
  value,
  show,
  handleClose,
  refetchUser,
}) => {
  const handleInvite = async () => {
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.inviteStudentToClass,
        { code: value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data.success) {
        refetchUser()
        ShowToast({ type: 'success', message: data.message })
        handleClose(false)
      }
    } catch (error) {
      ShowToast({ message: error })
      handleClose(false)
    }
  }
  return (
    <CustomModal
      open={show}
      handleClose={handleClose}
      maxWidth='450px'
      containerClasses='flex items-center justify-center flex-col'
    >
      <h1 className='font-semibold text-lg text-center'>
        Klassencode eingeben und auf bestätigen klicken
      </h1>
      <OTPInput
        value={value}
        onChange={handleChange}
        numInputs={6}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
        containerStyle='h-[100px]'
        inputStyle='border rounded h-[3rem] !w-[3rem]'
        shouldAutoFocus={true}
        placeholder='Klassencode eingeben:'
      />
      <Button
        title='bestätigen'
        type='submit'
        disabled={value.trim().length < 6}
        containerClasses='bg-[#458EFF] text-white px-4 py-2 rounded-lg h-full'
        onClick={handleInvite}
      />
    </CustomModal>
  )
}

export default ClassInvitationModal

import * as React from 'react';

const AdminDownloadStatsIcon = () => (
  <svg width={92} height={92} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect opacity={0.1} width={92} height={92} rx={24} fill='#458EFF' />
    <path
      d='M45.727 52.718c-.427 0-.854-.157-1.19-.494l-5.753-5.752a1.695 1.695 0 0 1 0-2.382 1.695 1.695 0 0 1 2.382 0l4.561 4.561 4.562-4.561a1.695 1.695 0 0 1 2.382 0c.652.651.652 1.73 0 2.381l-5.753 5.753a1.667 1.667 0 0 1-1.19.495Z'
      fill='#458EFF'
    />
    <path
      d='M45.727 52.561a1.698 1.698 0 0 1-1.685-1.685V28.023c0-.922.764-1.686 1.685-1.686.922 0 1.686.764 1.686 1.686v22.853c0 .921-.764 1.685-1.686 1.685Z'
      fill='#458EFF'
    />
    <path
      d='M45.996 66.067c-11.572 0-19.662-8.09-19.662-19.663 0-.921.764-1.685 1.685-1.685.922 0 1.686.764 1.686 1.685 0 9.595 6.696 16.292 16.291 16.292 9.596 0 16.292-6.697 16.292-16.292 0-.921.764-1.685 1.685-1.685.922 0 1.686.764 1.686 1.685 0 11.573-8.09 19.663-19.663 19.663Z'
      fill='#458EFF'
    />
  </svg>
);

export default AdminDownloadStatsIcon;

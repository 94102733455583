import { Text, View } from '@react-pdf/renderer';
import { styles } from '.';

const Title = ({ heading = '', value = '' }) => {
  return (
    <View>
      {value ? (
        <View style={styles.line}>
          <Text style={styles.heading}>{heading}</Text>
          <Text style={{ ...styles.heading_value, width: 330 }}>{value}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default Title;

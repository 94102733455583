import React, { useContext, useState } from 'react'
import CustomModal from './CustomModal'
import SelectKanton, { allKantons } from './LandingPage/SelectKanton'
import Button from './Button'
import { LOCATION_NOT_SPECIFIED } from 'utils'
import { useSelector } from 'react-redux'
import { user } from 'store/reducers/user'
import { NavbarContext } from 'App'

const GetUserLocation = ({
  customHandleSelectLocation,
  showLocationModalFromNavbar,
  setShowLocationModalFromNavbar,
}) => {
  const [showModal, setShowModal] = useState(true)

  const [isLocationSpecified, setIsLocationSpecified] = useState(true)
  const [location, setLocation] = useState(
    localStorage.getItem('location') || ''
  )

  const handleSelectLocation = () => {
    setShowModal(false)
    setShowLocationModalFromNavbar(false)
    localStorage.setItem('location', location)
    customHandleSelectLocation()
  }

  const locationFromCookies = localStorage.getItem('location')

  return (
    <>
      {(showLocationModalFromNavbar ||
        !(
          locationFromCookies === LOCATION_NOT_SPECIFIED ||
          locationFromCookies?.trim()
        )) && (
        <CustomModal
          open={showLocationModalFromNavbar || showModal}
          handleClose={() => {}}
        >
          <div className='text-center flex flex-col gap-6 items-center justify-center'>
            <div>
              <h2 className='text-lg'>
                In <span className='font-bold'>welchem Kanton</span> wohnst du?
              </h2>
              <p className='text-gray-500 text-sm'>
                (zum Anzeigen von Unternehmen/ Lehrstellen in deiner Region )
              </p>
            </div>
            <div className='flex items-center justify-center gap-4 w-full'>
              <span>Mein Wohnkanton:</span>{' '}
              <SelectKanton
                search={location}
                setSearch={setLocation}
                isLocationSpecified={isLocationSpecified}
              />
            </div>

            {/* <div>
              <FormControlLabel
                label={'Ich möchte meinen Wohnkanton nicht angeben'}
                control={
                  <Checkbox
                    checked={!isLocationSpecified}
                    onChange={handleLocationAccess}
                  />
                }
              />
            </div> */}
            <Button
              title='weiter'
              type='submit'
              containerClasses='bg-[#458EFF] text-white rounded-md h-[40px] w-44'
              disabled={
                !location ||
                !allKantons.find((kanton) => kanton.name === location)
              }
              onClick={handleSelectLocation}
            />
          </div>
        </CustomModal>
      )}
    </>
  )
}

const AskUserKanton = ({ customHandleSelectLocation = () => {} }) => {
  const { isLoggedIn, role } = useSelector(user)

  const { showLocationModal, setShowLocationModal } = useContext(NavbarContext)

  return !isLoggedIn || role === 'Teacher' || showLocationModal ? (
    <GetUserLocation
      showLocationModalFromNavbar={showLocationModal}
      setShowLocationModalFromNavbar={setShowLocationModal}
      customHandleSelectLocation={customHandleSelectLocation}
    />
  ) : null
}

export default AskUserKanton

export const languages = [
  { title: 'Deutsch' },
  { title: 'Englisch' },
  { title: 'Französisch' },
  { title: 'Italienisch' },
]

export const proficiency = [
  { title: 'Erstsprache' },
  { title: 'Schulkenntnisse ab dem 2. Schuljahr' },
  { title: 'Schulkenntnisse ab dem 3. Schuljahr' },
  { title: 'Schulkenntnisse ab dem 4. Schuljahr' },
  { title: 'Schulkenntnisse ab dem 5. Schuljahr' },
  { title: 'Schulkenntnisse ab dem 6. Schuljahr' },
]

import * as React from "react"

const AdminClassStatsIcon = () => (
  <svg width={92} height={92} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity={0.1} width={92} height={92} rx={24} fill="#458EFF" />
    <path
      d="M46.02 57.235c-1.91 0-3.843-.494-5.349-1.46l-13.528-8.831a8.815 8.815 0 0 1-4.022-7.416 8.815 8.815 0 0 1 4.022-7.416l13.55-8.808c3.012-1.955 7.708-1.955 10.697.022l13.46 8.831a8.838 8.838 0 0 1 4 7.394 8.838 8.838 0 0 1-4 7.393l-13.46 8.83c-1.506.99-3.438 1.461-5.37 1.461Zm0-32.021c-1.282 0-2.562.292-3.484.921L29.01 34.944c-1.573 1.034-2.495 2.697-2.495 4.584 0 1.888.9 3.55 2.495 4.584l13.527 8.831c1.865 1.214 5.146 1.214 7.011 0l13.46-8.83c1.574-1.035 2.472-2.697 2.472-4.585 0-1.887-.898-3.55-2.471-4.584l-13.46-8.831c-.945-.585-2.225-.9-3.529-.9Z"
      fill="#458EFF"
    />
    <path
      d="M45.997 70.156c-.989 0-2-.135-2.81-.404L36.02 67.37c-3.394-1.124-6.068-4.832-6.045-8.404l.022-10.54c0-.92.764-1.685 1.686-1.685.92 0 1.685.764 1.685 1.686l-.023 10.539c0 2.112 1.753 4.539 3.753 5.213l7.169 2.382c.898.292 2.561.292 3.46 0l7.168-2.382c2-.674 3.753-3.101 3.753-5.19V48.56c0-.92.764-1.685 1.685-1.685.922 0 1.686.764 1.686 1.685v10.427c0 3.573-2.652 7.258-6.045 8.404l-7.168 2.382c-.81.247-1.82.382-2.81.382ZM67.12 54.426a1.698 1.698 0 0 1-1.685-1.685V39.258c0-.921.764-1.685 1.685-1.685s1.685.764 1.685 1.685v13.483c0 .921-.764 1.685-1.685 1.685Z"
      fill="#458EFF"
    />
  </svg>
)

export default AdminClassStatsIcon

import React, { useRef } from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, user as userReducer } from 'store/reducers/user'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import userImage from 'assets/images/blank_user.png'
import CustomImageReplacer from 'components/CustomImageReplacer'
import axios from 'axios'
import CustomInput from 'components/Input'
import { useState } from 'react'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import CustomImageCropModal from 'components/CustomImageCropModal'
import { ShowToast } from 'utils/toastMessages'
import { translation } from 'utils/germanTranslation'
import { Navigate, useNavigate } from 'react-router-dom'
import { Info } from '@mui/icons-material'
import useGetUserDetail from 'hooks/useGetUserDetail'
import CustomModal from 'components/CustomModal'
import { handleDeleteStudent } from '../ClassDetails/utils'

const EmailHoverText = `
Wenn du an eine neue Schule wechselst (z.B. du zügelst weg) oder nach der Sekundarschule ins
Brückenangebot gehst, kannst du hier deine E-Mail ändern, da deine Schulmail nach der Oberstufe
nicht mehr gültig ist.
So hast du mit der neuen E-Mail weiterhin Zugriff auf dein Profil und alle Bewerbungsunterlagen.
`

const AccountSettings = () => {
  const user = useSelector(userReducer)
  console.log('user', user)

  const toastId = useRef(null)
  const previewCanvasRef = useRef(null)
  const navigate = useNavigate()

  // State
  const [showCropModal, setShowCropModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [crop, setCrop] = useState()
  const [imgSrc, setImgSrc] = useState('')
  const [loadingImage, setLoadingImage] = useState(false)
  const dispatch = useDispatch()

  const handleFile = (file) => {
    setLoadingImage(true)
    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '')
      setLoadingImage(false)
    })
    setShowCropModal(true)
    reader.readAsDataURL(file)
  }

  const initialState = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    newEmail: '',
    confirmNewEmail: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const [value, setValue] = useState(initialState)

  const handleSubmit = async () => {
    if (user.role === 'Student') {
      if (value.newEmail && value.newEmail !== value.confirmNewEmail) {
        return ShowToast({
          message: `New email and confirm new email doesn't match`,
        })
      }
    }
    if (value.newPassword && value.newPassword !== value.confirmNewPassword) {
      return ShowToast({
        message: `New password and confirm new password doesn't match`,
      })
    }
    try {
      const { data } = await axios.put(
        BACKEND_URL +
          API_URL[user.role === 'Teacher' ? 'teacher' : 'student']
            .updateSettings,
        value,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data.success) {
        ShowToast({ type: 'success', message: data.message })
      }
      dispatch(fetchUser())
    } catch (error) {
      ShowToast({ message: error })
      dispatch(fetchUser())
    }
  }

  const handleValueChange = (newValue, field) => {
    setValue({
      ...value,
      [field]: newValue,
    })
  }

  const handleUploadUserImage = async () => {
    const dataUrl = previewCanvasRef.current.toDataURL('image/jpeg')
    const blob = await fetch(dataUrl).then((r) => r.blob())
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    const formData = new FormData()
    formData.append('photo', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        id: user.id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
          })
          toastId.current = null
        }
      },
    }
    setShowCropModal(false)
    axios
      .post(BACKEND_URL + API_URL.user.upload, formData, config)
      .then((res) => {
        dispatch(fetchUser())
      })
      .catch((error) => {
        ShowToast({ error })
      })
  }
  const { data: userProfile, refetch: refetchUser } = useGetUserDetail(user.id)

  const handleDelete = () => {
    handleDeleteStudent(
      userProfile?.classId,
      user.id,
      user.id,
      { id: user.id },
      refetchUser,
      navigate
    )
  }

  if (user.role.toLowerCase() === 'admin') {
    return <Navigate to='/404' />
  }

  return (
    <div className='py-10 bg-softWhite min-h-screen flex justify-center px-6 xl:px-0'>
      <div className='max-w-[1166px] w-full text-softBlack'>
        <h1 className='font-bold text-xl sm:text-3xl pb-6'>
          Account-Einstellungen
        </h1>
        <div className='bg-white rounded-2xl p-6'>
          {user.role === 'Teacher' && (
            <div className='flex-shrink-0 w-full sm:w-auto max-w-none sm:max-w-[165px] flex items-center justify-center'>
              <div className='w-[162px] h-[162px] relative'>
                <img
                  src={
                    user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage
                  }
                  className='object-cover rounded-full border-8 border-white w-[162px] h-[162px]'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <CustomImageReplacer
                  handleChange={handleFile}
                  showCropModal={showCropModal}
                  setShowCropModal={setShowCropModal}
                />
              </div>
            </div>
          )}
          <div
            className={`flex gap-6 flex-col max-w-[547px] w-full ${
              user.role === 'Teacher' ? 'mt-8' : 'mt-0'
            }`}
          >
            {user.role === 'Teacher' && (
              <>
                <CustomInput
                  label={translation.firstName}
                  value={value.firstName}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'firstName')
                  }
                />
                <CustomInput
                  label={translation.lastName}
                  value={value.lastName}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'lastName')
                  }
                />
                <CustomInput
                  label='Email ID'
                  value={value.email}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'email')
                  }
                />
              </>
            )}
            <Typography className='font-bold text-xl sm:text-2xl'>
              Passwort ändern
            </Typography>
            <CustomInput
              label='Altes Passwort'
              value={value.oldPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'oldPassword')
              }
              type='password'
            />
            <CustomInput
              label='Neues Passwort eingeben'
              value={value.newPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'newPassword')
              }
              type='password'
            />
            <CustomInput
              label='Neues Passwort erneut eingeben'
              value={value.confirmNewPassword}
              handleChange={(newValue) =>
                handleValueChange(newValue, 'confirmNewPassword')
              }
              type='password'
            />

            {user.role === 'Student' && (
              <>
                <div className='flex items-center gap-0'>
                  <h1 className='font-bold text-xl'>Meine E-Mail ändern</h1>
                  <Tooltip enterTouchDelay={0} title={EmailHoverText}>
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
                <CustomInput
                  label={'Aktuelle E-Mail'}
                  value={value.email}
                  handleChange={(newValue) => {
                    // handleValueChange(newValue, 'email')
                  }}
                />
                <CustomInput
                  label={'Neue E-Mail eingeben'}
                  value={value.newEmail}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'newEmail')
                  }
                />
                <CustomInput
                  label={'Neue E-Mail bestätigen'}
                  value={value.confirmNewEmail}
                  handleChange={(newValue) =>
                    handleValueChange(newValue, 'confirmNewEmail')
                  }
                />
                {userProfile?.className && (
                  <div>
                    <Typography className='font-bold text-xl sm:text-2xl'>
                      Schüler*in der Klasse
                    </Typography>
                    <div className='flex items-center gap-4'>
                      <div>{userProfile?.className}</div>
                      <Button
                        title={`klasse ${userProfile?.className} verlassen`}
                        containerClasses='bg-[#E9F1FC] px-4 py-1 rounded-xl'
                        onClick={() => setShowModal(true)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-end gap-3 mt-6'>
          <Button
            title={translation.cancel}
            onClick={() => navigate(-1)}
            containerClasses='w-full sm:w-[118px] h-[60px] rounded-lg text-[#458EFF]'
            backgroundColor='#E9F1FC'
          />
          <Button
            title={translation.update}
            onClick={handleSubmit}
            titleClasses='text-white text-lg font-semibold'
            containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#458EFF]'
          />
        </div>
      </div>
      <CustomModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Willst du die Klasse wirklich verlassen?{' '}
            <span className='text-blue-600 font-bold'>
              ({userProfile?.className})
            </span>
            ?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.yes}
              onClick={() => {
                handleDelete()
              }}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={() => setShowModal(false)}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
      <CustomImageCropModal
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        crop={crop}
        setCrop={setCrop}
        handleUploadUserImage={handleUploadUserImage}
        previewCanvasRef={previewCanvasRef}
        closeCropModal={() => setShowCropModal(false)}
        loadingImage={loadingImage}
        setShowCropModal={setShowCropModal}
        showCropModal={showCropModal}
      />
    </div>
  )
}

export default AccountSettings

import { Button } from '@mui/material';
import * as React from 'react';

const NextIcon = ({ onClick, disabled = false }) => (
  <Button
    className={`${
      disabled ? 'opacity-50 cursor-auto' : 'cursor-pointer'
    } min-w-min`}
    onClick={() => !disabled && onClick()}
    disabled={disabled}
  >
    <svg width={26} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)'>
        <path
          d='M23 25.5H3A2.5 2.5 0 0 1 .5 23V3A2.5 2.5 0 0 1 3 .5h20A2.5 2.5 0 0 1 25.5 3v20a2.5 2.5 0 0 1-2.5 2.5Z'
          stroke='#F5F5F8'
        />
        <path
          d='m11.28 8.5-1.058 1.057L13.657 13l-3.435 3.442 1.057 1.058 4.5-4.5-4.5-4.5Z'
          fill='#0A233C'
          opacity={0.5}
        />
      </g>
      <rect
        x={25.5}
        y={25.5}
        width={25}
        height={25}
        rx={3.5}
        transform='rotate(-180 25.5 25.5)'
        stroke='#9CA3AF'
      />
      <defs>
        <clipPath id='a'>
          <rect
            x={26}
            y={26}
            width={26}
            height={26}
            rx={4}
            transform='rotate(-180 26 26)'
            fill='#fff'
          />
        </clipPath>
      </defs>
    </svg>
  </Button>
);

const PreviousIcon = ({ onClick, disabled = false }) => (
  <Button
    className={`${
      disabled ? 'opacity-50 cursor-auto' : 'cursor-pointer'
    } rotate-180 min-w-min`}
    onClick={() => !disabled && onClick()}
    disabled={disabled}
  >
    <svg width={26} height={26} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)'>
        <path
          d='M23 25.5H3A2.5 2.5 0 0 1 .5 23V3A2.5 2.5 0 0 1 3 .5h20A2.5 2.5 0 0 1 25.5 3v20a2.5 2.5 0 0 1-2.5 2.5Z'
          stroke='#F5F5F8'
        />
        <path
          d='m11.28 8.5-1.058 1.057L13.657 13l-3.435 3.442 1.057 1.058 4.5-4.5-4.5-4.5Z'
          fill='#0A233C'
          opacity={0.5}
        />
      </g>
      <rect
        x={25.5}
        y={25.5}
        width={25}
        height={25}
        rx={3.5}
        transform='rotate(-180 25.5 25.5)'
        stroke='#9CA3AF'
      />
      <defs>
        <clipPath id='a'>
          <rect
            x={26}
            y={26}
            width={26}
            height={26}
            rx={4}
            transform='rotate(-180 26 26)'
            fill='#fff'
          />
        </clipPath>
      </defs>
    </svg>
  </Button>
);

export { NextIcon, PreviousIcon };

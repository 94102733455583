import { CircularProgress } from '@mui/material';
import React from 'react';
import CustomModal from './CustomModal';
import CropUserImage from './Student/Dashboard/Crop';

const CustomImageCropModal = ({
  showCropModal,
  setShowCropModal,
  loadingImage,
  imgSrc,
  crop,
  setCrop,
  handleUploadUserImage,
  previewCanvasRef,
  setImgSrc,
  aspect,
}) => {
  return (
    <CustomModal
      open={showCropModal}
      handleClose={() => setShowCropModal((prev) => !prev)}
      containerClasses='w-full xs:w-auto flex items-center justify-center'
    >
      {loadingImage ? (
        <CircularProgress />
      ) : (
        <CropUserImage
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          crop={crop}
          setCrop={setCrop}
          handleUploadUserImage={handleUploadUserImage}
          previewCanvasRef={previewCanvasRef}
          closeCropModal={() => setShowCropModal(false)}
          aspect={aspect}
        />
      )}
    </CustomModal>
  );
};

export default CustomImageCropModal;

import React, { useState } from 'react'
import RichTextEditor from 'react-rte'

import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const ChangeEmailTextScreen = ({ value, setValue }) => {
  const [editorValue, setEditorValue] = useState([
    RichTextEditor.createValueFromString(value.emailText[0], 'html'),
  ])

  const handleChange = (newValue, editorValueIndex, valueIndex) => {
    setEditorValue([
      ...editorValue.slice(0, editorValueIndex),
      newValue,
      ...editorValue.slice(editorValueIndex + 1),
    ])
    setValue({
      ...value,
      emailText: [
        ...value.emailText.slice(0, valueIndex),
        newValue.toString('html'),
        ...value.emailText.slice(valueIndex + 1),
      ],
    })
  }

  const toolbarConfig = {
    display: [],
    INLINE_STYLE_BUTTONS: [],
    BLOCK_TYPE_DROPDOWN: [],
    BLOCK_TYPE_BUTTONS: [],
  }

  return (
    <div className='bg-softWhite p-4 rounded-md'>
      <RichTextEditor
        value={editorValue[0]}
        onChange={(value) => handleChange(value, 0, 0)}
        toolbarConfig={toolbarConfig}
        className='h-[12rem] overflow-scroll overflow-x-hidden font-[Mulish]'
      />
      <div dangerouslySetInnerHTML={{ __html: value.emailText[1] || '' }}></div>
      <div dangerouslySetInnerHTML={{ __html: value.emailText[2] || '' }}></div>
      <div dangerouslySetInnerHTML={{ __html: value.emailText[3] || '' }}></div>

      <br />

      <h1 className='font-bold text-[13px]'>
        Wichtig: Der Zugriff auf das Profil erfolgt immer über diesen Link (nur
        diesen Link) – bitte löschen Sie diese E-Mail nicht, wenn Sie noch
        einmal darauf zugreifen möchten.
      </h1>

      <div className='flex items-center gap-3'>
        <Button
          title={translation.back}
          onClick={() =>
            setValue({
              ...value,
              showChangeEmailTextModal: false,
            })
          }
          containerClasses='bg-blue-200 px-4 py-2 rounded-lg mt-3'
        />
        <Button
          title='Speichern'
          onClick={() =>
            setValue({
              ...value,
              showChangeEmailTextModal: false,
            })
          }
          containerClasses='bg-blue-200 px-4 py-2 rounded-lg mt-3'
        />
      </div>
    </div>
  )
}

export default ChangeEmailTextScreen

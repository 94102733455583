import SmallEditIcon from 'assets/icons/smallEdit'
import React from 'react'
import { FileUploader } from 'react-drag-drop-files'

const CustomImageReplacer = ({
  handleChange,
  containerClasses,
  types = ['jpeg', 'png', 'jpg'],
}) => {
  return (
    <div
      className={`absolute top-[10px] right-[20px] border-2 border-[#E8E8E8] rounded-full ${containerClasses}`}
    >
      <FileUploader handleChange={handleChange} types={types}>
        <SmallEditIcon />
      </FileUploader>
    </div>
  )
}

export default CustomImageReplacer

import { FileUploader } from 'react-drag-drop-files'
import DocumentIcon from 'assets/icons/document'
import CancelIcon from '@mui/icons-material/Cancel'
import { InputLabel } from '@mui/material'
import { Download } from '@mui/icons-material'

function UploadFiles({
  file,
  onDeleteFile,
  label,
  handleFileUpload,
  types,
  onDownloadFile = null,
  containerClasses,
}) {
  const handleFile = (e) => {
    handleFileUpload(e)
  }
  return (
    <div className={`${containerClasses}`}>
      <InputLabel shrink htmlFor='bootstrap-input'>
        {label}
      </InputLabel>
      {!file ? (
        <FileUploader
          multiple={false}
          handleChange={handleFile}
          name='file'
          types={types}
          hoverTitle='Drop your PDF here'
        >
          <div className='bg-[#F0F8FF] py-2 px-2 rounded-lg m-auto text-sm flex items-center gap-2 justify-between border border-dashed border-[#209ADA] h-11'>
            <div className='flex items-center gap-2'>
              <div>
                <DocumentIcon />
              </div>
              <div className='text-xs'>
                Ziehen Sie Ihre Datei per Drag & Drop hierher oder{' '}
                <span className='underline cursor-pointer'>durchsuchen</span>
              </div>
            </div>
          </div>
        </FileUploader>
      ) : (
        <div className='bg-[#F0F8FF] py-2 px-4 rounded-lg m-auto text-sm flex items-center gap-2 justify-between border border-white select-none'>
          <div className='flex items-center gap-2'>
            <div>
              <DocumentIcon />
            </div>
            <div className='break-all'>{file?.title}</div>
          </div>
          <div className='flex items-center justify-center gap-1'>
            <CancelIcon
              className='text-[#B0BEC5] cursor-pointer'
              onClick={(...props) => onDeleteFile(...props)}
            />
            {onDownloadFile && (
              <Download
                className='text-[#B0BEC5] cursor-pointer'
                onClick={(...props) => onDownloadFile(...props)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default UploadFiles

import * as React from 'react';

const AdminTeacherStatsIcon = () => (
  <svg width={92} height={92} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect opacity={0.1} width={92} height={92} rx={24} fill='#458EFF' />
    <path
      d='M46.355 45.146h-.18a1.218 1.218 0 0 0-.404 0c-6.517-.203-11.438-5.326-11.438-11.64 0-6.427 5.236-11.663 11.663-11.663 6.426 0 11.662 5.236 11.662 11.663-.022 6.314-4.966 11.438-11.236 11.64h-.067Zm-.36-19.932c-4.561 0-8.291 3.73-8.291 8.292a8.232 8.232 0 0 0 7.977 8.269c.112-.022.427-.022.72 0 4.403-.202 7.864-3.82 7.886-8.27 0-4.561-3.73-8.291-8.291-8.291ZM46.378 69.707c-4.404 0-8.831-1.124-12.18-3.37-3.123-2.068-4.83-4.9-4.83-7.978 0-3.079 1.707-5.932 4.83-8.022 6.742-4.472 17.663-4.472 24.36 0 3.1 2.067 4.83 4.898 4.83 7.977 0 3.079-1.707 5.932-4.83 8.022-3.371 2.247-7.776 3.371-12.18 3.371ZM36.064 53.168c-2.158 1.438-3.326 3.28-3.326 5.214 0 1.91 1.19 3.752 3.326 5.168 5.595 3.752 15.033 3.752 20.628 0 2.157-1.438 3.326-3.281 3.326-5.213 0-1.91-1.191-3.753-3.326-5.169-5.595-3.73-15.033-3.73-20.628 0Z'
      fill='#458EFF'
    />
  </svg>
);

export default AdminTeacherStatsIcon;

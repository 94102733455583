import React from 'react';

const AddLanguageIcon = () => (
  <svg width={89} height={89} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.019 44.5a43.482 43.482 0 1 0 86.963 0 43.482 43.482 0 0 0-86.963 0Z'
      fill='#F0CF21'
      fillOpacity={0.26}
    />
    <path
      d='M19.309 9.74H64.25v58.725H19.31V9.741Z'
      fill='#FCEC9C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.701 13.335h34.156v50.336H24.701V13.335Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M46.24 8.541c.007-.1.03-.196.03-.3a4.495 4.495 0 0 0-8.99 0c0 .104.025.2.031.3h-5.42a1.196 1.196 0 0 0-1.197 1.196v4.794a1.196 1.196 0 0 0 1.196 1.196h19.777a1.196 1.196 0 0 0 1.195-1.196V9.737a1.196 1.196 0 0 0-1.195-1.196h-5.428Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.996 80.6c0 .478 1.39.935 3.862 1.272 2.472.337 5.825.527 9.322.527 3.496 0 6.85-.19 9.322-.527 2.472-.337 3.861-.794 3.861-1.271 0-.477-1.389-.934-3.861-1.272-2.472-.337-5.826-.526-9.322-.526-3.497 0-6.85.19-9.322.526-2.473.338-3.862.795-3.862 1.272Z'
      fill='#FCEC9C'
    />
    <path
      d='M44.844 59.482a15.577 15.577 0 0 0 15.58 15.58 15.58 15.58 0 1 0-15.58-15.58v0Z'
      fill='#fff'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M68.216 56.885h-5.194v-5.193a2.597 2.597 0 1 0-5.194 0v5.193h-5.193a2.597 2.597 0 0 0 0 5.193h5.193v5.193a2.597 2.597 0 1 0 5.194 0v-5.193h5.194a2.597 2.597 0 1 0 0-5.194v0Z'
      fill='#FCEC9C'
      stroke='#020064'
      strokeWidth={0.24}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AddLanguageIcon;

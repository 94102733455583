export const ENVIRONMENT = String(
  process.env.REACT_APP_ENVIRONMENT
).toUpperCase()

export const BACKEND_URL = {
  DEVELOPMENT: 'http://localhost:8000',
  PRODUCTION: 'https://lehrstell.ch',
}[ENVIRONMENT]

export const FRONTEND_URL = {
  DEVELOPMENT: 'http://localhost:3000',
  PRODUCTION: 'https://lehrstell.ch',
}[ENVIRONMENT]

export const API_URL = {
  user: {
    all: '/api/user/all',
    upload: '/api/user/upload/avatar',
    deleteAvatar: '/api/user/remove/avatar',
    uploadVideo: '/api/user/upload/video',
    findById: '/api/user/find/',
    searchByEmail: '/api/user/search',
    verifyTeacher: '/api/teacher/verify',
    validateResetPasswordToken: '/api/user/reset-password/validate',
  },
  teacher: {
    updateSettings: '/api/teacher/update/settings',
  },
  student: {
    references: '/api/student/references',
    personal: '/api/student/personal',
    skill: '/api/student/skill/',
    shareProfile: '/api/student/share_profile/',
    generateMotivationLetter: '/api/student/generate/motivation-letter',
    updateSettings: '/api/student/update/settings',
  },
  documents: {
    all: '/api/user/documents/all',
    submitDocument: '/api/user/documents/add',
    add: '/api/user/upload/document',
    delete: '/api/user/remove/document',
    downloadMergedDocument: '/api/user/documents/merge/',
  },
  languages: {
    all: '/api/user/languages/all',
    add: '/api/user/languages/add',
  },
  academic: {
    all: '/api/user/academic/all',
    add: '/api/user/academic/add',
  },
  hobbies: {
    all: '/api/user/hobbies/all',
    add: '/api/user/hobbies/add',
  },
  trial: {
    all: '/api/user/trial/all',
    add: '/api/user/trial/add',
  },
  diary: {
    all: '/api/user/diary/all',
    add: '/api/user/diary/add',
    findById: '/api/user/diary/find/',
    deleteById: '/api/user/diary/delete/',
    updateById: '/api/user/diary/update/',
  },
  motivationLetters: {
    all: '/api/student/motivation-letters/all',
    add: '/api/student/motivation-letters/add',
    findById: '/api/student/motivation-letters/find/',
    deleteById: '/api/student/motivation-letters/delete/',
    updateById: '/api/student/motivation-letters/update/',
    upload: '/api/student/motivation-letters/upload',
  },
  personalData: {
    all: '/api/student/personal',
    add: '/api/student/personal/add',
  },
  classes: {
    all: '/api/teacher/class/all',
    findById: '/api/teacher/class/find/',
    add: '/api/teacher/class/add',
    deleteById: '/api/teacher/class/delete/',
    checkTeacherAvailability: '/api/teacher/class/available/teacher',
    checkStudentAvailability: '/api/teacher/class/available/student',
    getAllStudentsOfClassById: '/api/teacher/class/students/',
    getAllTeachersOfClassById: '/api/teacher/class/teachers/',
    addStudentsToClass: '/api/teacher/class/add/students',
    deleteStudentsFromClass: '/api/teacher/class/delete/student',
    updateClassName: '/api/teacher/class/update/name',
    shareCLass: '/api/teacher/class/share/',
    addSecondAdmin: '/api/teacher/class/add/secondAdmin',
    updateSchoolDetails: '/api/teacher/class/update/schoolDetails',
    sendClassInviteToTeacher: '/api/teacher/class/invite/teacher',
    sendClassInviteToStudent: '/api/teacher/class/invite/student',
    addMemberToClass: '/api/teacher/class/add/member',
  },
  count: {
    teachers: '/api/teacher/count',
    students: '/api/user/count',
    classes: '/api/teacher/count/class',
    emails: '/api/email/count',
    downloads: '/api/downloads/count',
    banners: '/api/web-banner/count',
  },
  admin: {
    studentsDetail: '/api/admin/students',
    teachersDetail: '/api/admin/teachers',
    deleteUser: '/api/admin/user/',
    getAllClasses: '/api/admin/classes',
  },
  job: {
    adminDashboardJobs: '/api/job/admin/dashboard',
    findById: '/api/job',
    deleteById: '/api/job/',
    updateById: '/api/job/',
    uploadImage: '/api/job/upload/image',
    allJobs: '/api/job/all',
    allCategoryJobs: '/api/job/all/',
    searchJob: '/api/job/search/',
    uploadVideo: '/api/job/upload/video/',
    deleteVideo: '/api/job/delete/video/',
  },
  shareProfile: {
    registerHR: '/api/share-profile/hr/register',
    watchProfile: '/api/share-profile/hr/watch',
    getNewAccessCode: '/api/share-profile/hr/get-new-access-code',
  },
  banner: {
    getBannerById: '/api/web-banner/',
    allBanners: '/api/web-banner',
    allCompanies: '/api/web-banner/companies',
    addBanner: '/api/web-banner',
    updateBanner: '/api/web-banner',
    deleteBanner: '/api/web-banner/',
    uploadBannerImage: '/api/web-banner/upload/image',
    updateClicks: (bannerId) => `/api/web-banner/${bannerId}/clicks`,
  },
  setNewPassword: '/api/user/reset-password/new-password',
  resetPassword: '/api/user/reset-password',
  login: '/api/user/login',
  register: '/api/user/register',
  verifyAccess: '/api/user/profile/access',
  deleteUser: '/api/student/delete/',
  isTeacherAdminOfTheStudent: '/api/teacher/class/verify/admin/',
  inviteStudentToClass: '/api/teacher/class/invite/student',
}

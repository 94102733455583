import axios from 'axios'
import {
  bannerTypeMapping,
  multiBannerMapping,
} from 'components/Admin/Banner/constants'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { user as userState } from 'store/reducers/user'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useFetchBannerById = (id) => {
  const [banner, setBanner] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const user = useSelector(userState)

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json', // Corrected content type
            Authorization: `Bearer ${user.token}`,
          },
        }

        const response = await axios.get(
          `${BACKEND_URL}${API_URL.banner.getBannerById}${id}`,
          config
        )

        const bannerDetails = { ...response.data }
        bannerDetails.jobs = bannerDetails.jobs.map((job) => job.title)
        bannerDetails.jobTypes = bannerDetails.jobTypes.map((jobType) => jobType.title)
        bannerDetails.kantons = bannerDetails.kantons.map(
          (kanton) => kanton.name
        )
        bannerDetails.multiBanner = Object.keys(multiBannerMapping).find(
          (key) => multiBannerMapping[key] === bannerDetails.multiBanner
        )
        bannerDetails.type = Object.keys(bannerTypeMapping).find(
          (key) => bannerTypeMapping[key] === bannerDetails.type
        )

        setBanner(bannerDetails)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    if (user.token) {
      fetchBanner()
    }
  }, [user.token, id])

  return { banner, loading, error }
}

export default useFetchBannerById

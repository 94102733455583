import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'utils/routes'

const styles = {
  link: 'text-blue-500 hover:underline underline-offset-2 cursor-pointer basis-[45%] xs:basis-auto',
}
const Footer = () => {
  return (
    <div className='flex flex-col sm:flex-row justify-between gap-4 bg-white w-full py-6 items-center px-4'>
      <h1>Copyright &copy; 2023 Lehrstell.ch</h1>
      <h1 className='flex gap-4 flex-wrap w-full xs:w-auto'>
        <Link to={ROUTES().about} className={styles.link}>
          Über uns
        </Link>
        <Link to={ROUTES().impressum} className={styles.link}>
          Impressum
        </Link>
        <Link to={ROUTES().termsOfUse} className={styles.link}>
          rechtliche Dokumente
        </Link>
        <Link to={ROUTES().anleitungen} className={styles.link}>
          Anleitungen & Anleitungsvideos
        </Link>
      </h1>
    </div>
  )
}

export default Footer

import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Button as MUIButton,
  Skeleton,
  Tooltip,
} from '@mui/material'
import axios from 'axios'
import _ from 'lodash'

import userImage from 'assets/images/blank_user.png'
import PlayIcon from 'assets/icons/play'
import { API_URL, BACKEND_URL, FRONTEND_URL } from 'utils/apiConstants'
import Documents from './Documents'
import CustomVideoPlayerModal from 'components/CustomVideoPlayerModal'
import ShareProfileIcon from 'assets/icons/shareProfile'
import { ShowToast } from 'utils/toastMessages'
import ShareProfileModal from './ShareProfileModal'
import DownloadDocuments from './DownloadDocuments'
import { FileUploader } from 'react-drag-drop-files'
import SmallEditIcon from 'assets/icons/smallEdit'
import { translation } from 'utils/germanTranslation'
import Button from 'components/Button'
import ClassInvitationModal from './ClassInvitationCode'
import VideoAvailableIcon from 'assets/icons/videoAvailable'
import TutorialVideo from 'assets/videos/1_Schülerprofil.mp4'

const VIDEO_NOT_AVAILABLE_TOOLTIP = 'Video nicht verfügbar'
const UPLOAD_VIDEO_IN_PROFILE_TOOLTIP =
  'Klicke rechts neben „Infos über mich“ auf den Stift und lade dort dein Video über dich hoch!'

const StudentIntro = ({
  userId,
  user,
  handleFile,
  loading,
  setToggleProfile,
  canEdit,
  loggedInUser,
  languages,
  hobbies,
  academicHistory,
  trial,
  personalData,
  isUserFromShareProfile,
  refetchUser,
  isClassTeacher = false,
}) => {
  const [invitationCode, setInvitationCode] = useState('')
  const [showInvitationCodeModal, setShowInvitationCodeModal] = useState(false)
  const userName = `${user?.firstName || 'FirstName'} ${
    user?.lastName || 'LastName'
  }`
  const profileUrl = `${FRONTEND_URL}/watch-profile/${loggedInUser?.id}`
  const InitialShareProfileModalConfig = {
    show: false,
    toShareEmail: '',
    loading: false,
    showChangeEmailTextModal: false,
    emailText: [
      `<h1><span style="font-size:18px"><strong>Guten Tag,</strong></span></h1>
      <p><span style="font-size:16px">${userName} möchte sein Lehrstell.ch-Bewerbungsprofil mit Ihnen teilen.</span></p>`,
      `
      <p><span style="font-size:14px"><strong>Zugang zum Profil:</strong></span></p>
      <p style="padding: 0">
        <p><span style="font-size:14px">1. Klicken Sie auf den untenstehenden Link</span></p>
        <p><span style="font-size:14px">2. Registrieren Sie sich/Zugangscode anfordern</span></p>
        <p><span style="font-size:14px">3. Erneuter Profilzugriff (nach Registrierung): Falls Sie Ihren Browserverlauf (Cache/Cookies) nicht gel&ouml;scht haben, erfolgt der Zugriff immer automatisch via untenstehendem Link.</span></p>
      </p>`,
      `<div style="margin:8px 0"><span style="font-size:14px;">Falls dies nicht funktioniert: Link anklicken und unter Bereits registriert?: &laquo;neuen Zugangscode anfordern&raquo;</span></div>
      <p><span style="font-size:14px">Link zum Profil von&nbsp;<strong>${userName}</strong>:&nbsp;</span></p>`,
      `<p><a href="${profileUrl}" style="font-size:14px;" target="_blank">${profileUrl}</a></p>`,
      `<p><span style="font-size:14px">Falls dies nicht funktioniert: Link anklicken und unter Bereits registriert?: &laquo;neuen Zugangscode anfordern&raquo;</span></p>
      <p>&nbsp;</p>
      <p><span style="font-size:14px"><strong>Interessante Bewerbung?</strong></span></p>
      <p><span style="font-size:14px">Diese E-Mail inkl. Link an Mitarbeiter*In weiterleiten, um Profil-Zugriff zu erm&ouml;glichen (diese*r muss ebenfalls die Identit&auml;t verifzieren und erh&auml;lt dann Zugang) </span></p>
      <p><span style="font-size:14px">Wenn Sie von verschiedenen Sch&uuml;lern*innen Bewerbungen erhalten, muss die Registrierung aus sicherheitsgr&uuml;nden jeweils nochmals komplett erfolgen, damit jede*r Sch&uuml;ler*in erf&auml;hrt, wer auf das Profil zugreift.</span></p>`,
    ],
    isCVAttached: false,
    isMotivationLetterAttached: '',
    documentsAttached: [],
    uploadedDocument: null,
  }

  const STUDENT_VIDEO = 'student_video'
  const INFO_VIDEO = 'info_video'

  const [showVideoModal, setShowVideoModal] = useState({
    type: null,
    show: false,
  })
  const [shareProfileModalConfig, setShareProfileModalConfig] = useState(
    InitialShareProfileModalConfig
  )
  const [documents, setDocuments] = useState([])

  const sendProfileAccess = async (e) => {
    e.preventDefault()
    setShareProfileModalConfig({ ...shareProfileModalConfig, loading: true })
    try {
      const { data } = await axios.post(
        BACKEND_URL + API_URL.student.shareProfile + user?.id,
        {
          toShareEmail: shareProfileModalConfig.toShareEmail,
          emailText: shareProfileModalConfig.emailText,
          isCVAttached: shareProfileModalConfig.isCVAttached,
          isMotivationLetterAttached:
            shareProfileModalConfig.isMotivationLetterAttached,
          documentsAttached: shareProfileModalConfig.documentsAttached,
          uploadedDocument: shareProfileModalConfig.uploadedDocument,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      if (data?.success) {
        ShowToast({ type: 'success', message: data?.message })
        setShareProfileModalConfig(InitialShareProfileModalConfig)
      }
    } catch (error) {
      ShowToast({ message: error })
      setShareProfileModalConfig(InitialShareProfileModalConfig)
    }
  }

  const isVideoAvailable = _.isEmpty(user?.aboutMeVideo)
  return (
    <Box className='flex flex-col sm:flex-row md:flex-col lg:flex-row items-center gap-4 text-softBlack'>
      <div className='relative flex-shrink-0'>
        {loading ? (
          <Skeleton
            variant='circular'
            className='w-[162px] h-[162px] sm:w-[120px] sm:h-[120px] 2xl:w-[140px] 2xl:h-[140px] -z-[11] object-cover rounded-full border-8 border-white'
          />
        ) : (
          <>
            {canEdit ? (
              <FileUploader
                handleChange={handleFile}
                types={['jpeg', 'png', 'jpg']}
              >
                <img
                  src={
                    user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage
                  }
                  className='w-[162px] h-[162px] sm:w-[120px] sm:h-[120px] 2xl:w-[140px] 2xl:h-[140px] -z-[11] object-cover rounded-full border-8 border-white'
                  onContextMenu={(e) => e.preventDefault()}
                  alt='user-profile-pic'
                />

                <div className='absolute top-[10px] right-[20px] border-2 border-[#E8E8E8] rounded-full'>
                  <SmallEditIcon />
                </div>
              </FileUploader>
            ) : (
              <img
                src={user?.avatar ? `${BACKEND_URL}/${user.avatar}` : userImage}
                className='w-[162px] h-[162px] sm:w-[120px] sm:h-[120px] 2xl:w-[140px] 2xl:h-[140px] -z-[11] object-cover rounded-full border-8 border-white'
                alt='user-profile-pic'
                onContextMenu={(e) => e.preventDefault()}
              />
            )}
          </>
        )}
      </div>
      <Box className='flex flex-col gap-6 sm:gap-2 2xl:gap-3 w-full'>
        <div className='flex flex-col lg:flex-row items-center sm:items-start lg:items-center gap-2 lg:gap-2'>
          <p className='text-3xl sm:text-lg 2xl:text-2xl font-extrabold lg:text-left'>
            {user?.firstName} {user?.lastName}
          </p>
          {!isUserFromShareProfile &&
            (user['className'] ? (
              <h1 className='bg-[#458EFF] font-medium text-white w-max px-3 py-1 rounded-full'>
                {user['className']}
              </h1>
            ) : (
              <Button
                title='Klassencode eingeben'
                className='bg-gray-300 px-2 py-1 rounded-full text-xs'
                onClick={() => setShowInvitationCodeModal(true)}
              />
            ))}
          <div className='flex items-center gap-3'>
            <Tooltip
              enterTouchDelay={0}
              title={
                !canEdit
                  ? 'Es wurde noch kein Video hochgeladen!'
                  : isVideoAvailable
                  ? userId === loggedInUser?.id
                    ? UPLOAD_VIDEO_IN_PROFILE_TOOLTIP
                    : VIDEO_NOT_AVAILABLE_TOOLTIP
                  : ''
              }
              arrow
            >
              <span>
                <MUIButton
                  onClick={() =>
                    setShowVideoModal({ type: STUDENT_VIDEO, show: true })
                  }
                  disabled={isVideoAvailable}
                  startIcon={<PlayIcon disabled={isVideoAvailable} />}
                >
                  <p
                    className={`${
                      isVideoAvailable
                        ? 'text-black opacity-60'
                        : 'text-[#FF0100]'
                    } text-base sm:text-xs 2xl:text-base normal-case`}
                  >
                    Mein Video
                  </p>
                </MUIButton>
              </span>
            </Tooltip>
            <button
              className='block md:hidden bg-sky-300 rounded-xl px-3 font-medium'
              onClick={() => setToggleProfile(true)}
            >
              {translation.personalInformation}
            </button>
          </div>
          <DownloadDocuments user={user} />
          {!isUserFromShareProfile && (
            <div className='m-auto sm:m-0 lg:ml-auto'>
              <IconButton
                onClick={() =>
                  setShowVideoModal({ type: INFO_VIDEO, show: true })
                }
              >
                <VideoAvailableIcon width={55} />
              </IconButton>
            </div>
          )}
        </div>
        <div className='flex justify-between items-center flex-wrap gap-3'>
          <Documents
            userId={userId}
            canEdit={canEdit}
            setDocuments={setDocuments}
            languages={languages}
            hobbies={hobbies}
            academicHistory={academicHistory}
            trial={trial}
            personalData={personalData}
            profilePicture={user?.avatar || ''}
            isUserFromShareProfile={isUserFromShareProfile}
          />
          {canEdit && (
            <Tooltip
              title={
                isClassTeacher &&
                'Nur der/die Schüler*in kann sein Profil teilen'
              }
            >
              <span>
                <button
                  onClick={() =>
                    setShareProfileModalConfig({
                      ...shareProfileModalConfig,
                      show: true,
                    })
                  }
                  disabled={isClassTeacher}
                  className={`${
                    isClassTeacher
                      ? 'bg-blue-300'
                      : 'bg-[#458EFF] cursor-pointer'
                  } py-3 px-4 sm:py-2 text-white rounded-2xl flex gap-2 items-center justify-center `}
                >
                  <ShareProfileIcon />
                  <h3 className='text-base sm:text-xs '>Profil teilen</h3>
                </button>
              </span>
            </Tooltip>
          )}
        </div>
      </Box>
      <ShareProfileModal
        documents={documents}
        value={shareProfileModalConfig}
        setValue={setShareProfileModalConfig}
        initialValue={InitialShareProfileModalConfig}
        onSubmit={sendProfileAccess}
        userId={userId}
        user={loggedInUser}
      />
      <CustomVideoPlayerModal
        open={showVideoModal.show}
        handleClose={() => setShowVideoModal({ type: null, show: false })}
        videoURL={
          showVideoModal.type === INFO_VIDEO
            ? TutorialVideo
            : BACKEND_URL + '/' + user?.aboutMeVideo?.path
        }
      />
      <ClassInvitationModal
        show={showInvitationCodeModal}
        handleClose={() => setShowInvitationCodeModal(false)}
        value={invitationCode}
        handleChange={setInvitationCode}
        refetchUser={refetchUser}
      />
    </Box>
  )
}

export default StudentIntro
